import React, { FC, useCallback, useMemo } from 'react'

import { Typography, Paper, Grid, Button } from '@mui/material'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import { RowBasedTableColumn, getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import { filter, find, get, noop, remove, replace, startCase, sumBy, upperFirst } from 'lodash'
import { formatCurrencyFloat, formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import { IncomeAdjustmentKeys } from 'shared/constants/report/sales/salesAdjustment'
import {
  ADDITIONAL_COMP_INFO_ROW_IDS,
  ADJUSTMENT_GROUPS,
  COMP_INFO_ROW_IDS_TO_PROPERTY,
  COMP_INFO_ROW_LABEL_TO_IDS,
  COMP_INFO_ROW_PROPERTY_TO_IDS,
  DEFAULT_ADJUSTMENT_LABELS,
  DEFAULT_COMP_INFO_ROW_IDS,
  OTHER_ADJUSTMENT_ROW_KEYS,
  getDefaultAdjustments,
  DEED_SALE_PRICE_PROPERTY,
} from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { fromPercents } from 'client-shared/utils/numberOperations'
import {
  calculatePricePerSF,
  calculatePricePerUnit,
  getCustomRowNames,
} from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getParkingRatio } from 'shared/utils/report/salesApproach'

import { Checkbox } from 'client-shared/components'

import useBoolean from 'client-shared/hooks/useBoolean'

import { getTrendAdjustedPricePerBasis } from '../calculations'

import { getCompInformationRows } from './constants'
import { AdjustmentName, GroupName } from './types'
import ColumnHeader from './Components/ColumnHeader'
import AddComparableInformationDialog from './dialogs/AddComparableInformationDialog'

type SalesAdjustmentGridV2Props = {
  form: any
  showEditModal: (comp: any) => {}
}

export const SalesAdjustmentGridV2: FC<SalesAdjustmentGridV2Props> = ({ form, showEditModal }) => {
  const [isAddComparableInfoModalOpen, openComparableInfoModal, closeComparableInfoModal] = useBoolean(false)

  const formValues = form.getState().values
  const {
    unitOfComparison,
    type,
    incomeAdjustmentLevel,
    compAdjustments = [],
    subjectPropertyInformation = {},
    showInExport = {},
    selectedComps = [],
    otherAdjustmentDiscussions,
  } = formValues
  const handleUpdateAdjustmentRow = useCallback(
    (row: any) => {
      const { id } = row
      const { change } = form
      const rowIsCustom = id.includes('custom')

      compAdjustments.forEach((compAdjustment: any, index: number) => {
        const { compId, adjustments } = compAdjustment
        let adjustmentToUpdate = find(adjustments, (adjustment: any) => adjustment.name === id)
        if (rowIsCustom) {
          const customIds = id.split('_')
          const groupName = customIds[0]
          const customName = customIds[2]
          const customAdjustmentsForGroup = filter(
            adjustments,
            adjustment => adjustment.groupName === groupName && adjustment.custom
          )
          adjustmentToUpdate = customAdjustmentsForGroup.find(adjustment => adjustment.name === customName)
          adjustmentToUpdate.name = row.label

          const newAdjustmentDiscussion = { name: row.label, discussion: {} }
          otherAdjustmentDiscussions.push(newAdjustmentDiscussion)
          change('otherAdjustmentDiscussions', [...otherAdjustmentDiscussions])
        }
        const rawValue = row[compId]
        const numericValue = fromPercents(replace(rawValue, '%', ''))
        adjustmentToUpdate.value = numericValue
      })
      change('compAdjustments', [...compAdjustments])
    },
    [compAdjustments, form, otherAdjustmentDiscussions]
  )

  const handleUpdateCompInformationRow = useCallback(
    (row: any) => {
      const { id, showInExport } = row
      const { change } = form
      showInExport[id] = showInExport

      change('showInExport', { ...showInExport })
    },
    [form]
  )

  const getCompInformationRowStyle = useCallback(
    (params: any) => {
      const formValues = form.getState().values
      if (formValues.showInExport[params.data.id]) {
        return { fontWeight: 'bold' }
      } else {
        return { fontWeight: 'normal' }
      }
    },
    [form]
  )

  const shouldDisableAddCompInfoRow = useCallback(() => {
    const formValues = form.getState().values
    const { showInExport } = formValues

    const allExistingKeys = Object.keys(showInExport)
    const allExistingIds = Object.entries(COMP_INFO_ROW_PROPERTY_TO_IDS)
      .filter(([key]) => allExistingKeys.includes(key))
      .map(([_, id]) => id)

    const existingAdditionalIds = Object.keys(ADDITIONAL_COMP_INFO_ROW_IDS).filter(id => !allExistingIds.includes(id))

    return !existingAdditionalIds.length
  }, [form])

  const deleteAdditionalCompInfoRow = useCallback(
    (row: string) => {
      const id = COMP_INFO_ROW_PROPERTY_TO_IDS[row]

      // @ts-ignore
      if (!id || !ADDITIONAL_COMP_INFO_ROW_IDS[id]) {
        return
      }
      const formValues = form.getState().values
      const { showInExport } = formValues

      const updatedShowInExport = { ...showInExport }

      delete updatedShowInExport[row]

      form.change('showInExport', { ...updatedShowInExport })
    },
    [form]
  )

  const addCompInfoRow = useCallback(
    ({ compInfoName }) => {
      const formValues = form.getState().values
      const { showInExport } = formValues

      const compInfoId = Object.entries(COMP_INFO_ROW_LABEL_TO_IDS).find(
        ([label]) => label.toLowerCase() === compInfoName.toLowerCase()
      )?.[1]

      if (compInfoId) {
        const compInfoKey = COMP_INFO_ROW_IDS_TO_PROPERTY[compInfoId]

        if (compInfoKey) {
          form.change('showInExport', { ...showInExport, [compInfoKey]: true })
        }
      }

      closeComparableInfoModal()
    },
    [closeComparableInfoModal, form]
  )

  const getColumns = useCallback(() => {
    const columns: RowBasedTableColumn[] = [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        minWidth: 425,
        maxWidth: 425,
        permanent: true,
        align: 'left',
        editable: params => {
          if (params.data.custom) {
            return true
          }
          return false
        },
      },
    ]
    selectedComps.forEach((comp: any) => {
      columns.push({
        name: comp._id,
        label: comp.address?.streetAddress,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          if (params.data.totalRow || params.data.incomeLevel) {
            return false
          }
          return true
        },
      })
    })
    return columns
  }, [selectedComps])

  const getCompInformationColumns = useCallback(() => {
    const columns: RowBasedTableColumn[] = [
      {
        name: 'showInExport',
        label: 'Export',
        type: ColumnDataTypeEnum.boolean,
        minWidth: 85,
        maxWidth: 85,
        permanent: true,
        align: 'center',
        editable: false,
        cellRendererFramework({ value, data: { id } }) {
          return <Checkbox name={`showInExport[${id}]`} />
        },
      },
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        minWidth: 180,
        maxWidth: 180,
        permanent: true,
        align: 'left',
        editable: false,
      },
      {
        name: 'subject',
        label: 'Subject',
        type: ColumnDataTypeEnum.text,
        minWidth: 160,
        maxWidth: 160,
        permanent: true,
        align: 'right',
        editable: false,
      },
    ]
    selectedComps.forEach((comp: any) => {
      columns.push({
        name: comp._id,
        // @ts-ignore
        label: <ColumnHeader comp={comp} showEditModal={showEditModal} />,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      })
    })
    return columns
  }, [selectedComps, showEditModal])

  const getAdjustmentLabel = useCallback(
    (adjustment: AdjustmentName) => {
      if (adjustment === OTHER_ADJUSTMENT_ROW_KEYS.stabilization) {
        return incomeAdjustmentLevel === IncomeAdjustmentKeys.income
          ? 'Income Level Adjustment'
          : 'Rent Stabilization Adjustment'
      }
      return DEFAULT_ADJUSTMENT_LABELS[adjustment] || adjustment
    },
    [incomeAdjustmentLevel]
  )

  const getRows = useCallback(
    (groupName: GroupName) => {
      const { propertyType } = subjectPropertyInformation
      const defaultRows = getDefaultAdjustments(type, propertyType)[groupName] || []
      if (!compAdjustments.length) {
        return []
      }
      const adjustmentNames = filter(
        compAdjustments[0].adjustments,
        adjustment =>
          adjustment.groupName === groupName && !adjustment.isCustom && defaultRows.includes(adjustment.name)
      )?.map(adjustment => adjustment.name)

      const adjustmentRows: any = []
      adjustmentNames.forEach(adjustment => {
        if (
          adjustment === OTHER_ADJUSTMENT_ROW_KEYS.stabilization &&
          incomeAdjustmentLevel === IncomeAdjustmentKeys.none
        ) {
          return
        }
        const showAction = adjustment === 'commercial' || adjustment === 'averageUnitSize'
        const isIncomeLevelAdjustment =
          incomeAdjustmentLevel === IncomeAdjustmentKeys.income &&
          adjustment === OTHER_ADJUSTMENT_ROW_KEYS.stabilization
        const adjustmentRow: any = {
          readOnly: false,
          suppressMovable: true,
          type: ColumnDataTypeEnum.text,
          id: adjustment,
          custom: false,
          label: getAdjustmentLabel(adjustment),
          rowDef: { hideAction: !showAction },
          incomeLevel: isIncomeLevelAdjustment,
        }
        compAdjustments.forEach((compAdjustment: any) => {
          const { adjustments, compId } = compAdjustment
          const groupAdjustments = filter(adjustments, adj => adj.groupName === groupName)
          const adjustmentValue =
            find(groupAdjustments, groupAdjustment => groupAdjustment.name === adjustment)?.value || 0
          adjustmentRow[compId] = formatPercentageString(adjustmentValue)
        })
        adjustmentRows.push(adjustmentRow)
      })

      const customAdjustments = filter(
        compAdjustments[0].adjustments,
        adjustment => adjustment.groupName === groupName && adjustment.custom
      )?.map(adjustment => adjustment.name)
      customAdjustments.forEach((customAdjustment, index) => {
        const adjustmentRow: any = {
          readOnly: false,
          suppressMovable: true,
          permanent: false,
          type: ColumnDataTypeEnum.text,
          id: `${groupName}_custom_${customAdjustment}`,
          custom: true,
          label: customAdjustment,
          rowDef: { hideAction: false },
        }
        compAdjustments.forEach((compAdjustment: any) => {
          const { adjustments, compId } = compAdjustment
          const groupAdjustments = filter(adjustments, adj => adj.groupName === groupName)
          const adjustmentValue =
            find(groupAdjustments, groupAdjustment => groupAdjustment.name === customAdjustment)?.value || 0
          adjustmentRow[compId] = formatPercentageString(adjustmentValue)
        })
        adjustmentRows.push(adjustmentRow)
      })
      return adjustmentRows
    },
    [compAdjustments, getAdjustmentLabel, subjectPropertyInformation, type, incomeAdjustmentLevel]
  )

  const getNewAdjustmentName = (rowNames: string[]) => {
    let defaultName = `Other Adjustment`
    const rowsSet = new Set(rowNames)
    let index = 1
    while (rowsSet.has(defaultName)) {
      defaultName = `Other Adjustment ${index}`
      index += 1
    }
    return defaultName
  }

  const addCustomAdjustment = () => {
    const { batch, change } = form

    const rowNamesInGroup = getCustomRowNames(compAdjustments, ADJUSTMENT_GROUPS.OTHER)
    const addedRowName = getNewAdjustmentName(rowNamesInGroup)

    batch(() => {
      for (const [, compAdjustment] of compAdjustments.entries()) {
        compAdjustment.adjustments.push({
          name: addedRowName,
          value: 0,
          groupName: ADJUSTMENT_GROUPS.OTHER,
          custom: true,
        })
      }
      change('compAdjustments', [...compAdjustments])
      const newAdjustmentDiscussion = { name: addedRowName, discussion: {} }
      otherAdjustmentDiscussions.push(newAdjustmentDiscussion)
      change('otherAdjustmentDiscussions', [...otherAdjustmentDiscussions])
    })
  }

  const handleDeleteRow = (rowId: any) => {
    const { change } = form
    let adjustmentNameToRemove = rowId
    const isCustomRow = rowId.includes('custom')
    if (isCustomRow) {
      adjustmentNameToRemove = rowId.split('_')[2]
    }
    compAdjustments.forEach((compAdjustment: any, index: number) => {
      remove(compAdjustment.adjustments, (adjustment: any) => adjustment.name === adjustmentNameToRemove)
    })
    change('compAdjustments', [...compAdjustments])
  }

  const totalRows = useMemo(() => {
    const totalsByCompId: any[] = []
    const totalRowsByType: any = {}

    compAdjustments.forEach((compAdjustment: any) => {
      const { compId, adjustments } = compAdjustment
      const selectedComp = find(selectedComps, comp => comp._id === compId)
      const trendedPricePerBasis = getTrendAdjustedPricePerBasis(selectedComp, adjustments, unitOfComparison)

      const marketAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'market')
      const marketTotal = sumBy(marketAdjustments, adjustment => adjustment.value)

      const locationAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'location')
      const locationTotal = sumBy(locationAdjustments, adjustment => adjustment.value)

      const utilityAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'utility')
      const utilityTotal = sumBy(utilityAdjustments, adjustment => adjustment.value)

      const otherAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'other')
      const otherTotal = sumBy(otherAdjustments, adjustment => adjustment.value)

      const netTotal = utilityTotal + locationTotal + otherTotal
      const adjustedPricePerBasis = trendedPricePerBasis * (1 + netTotal)
      totalsByCompId.push({
        compId,
        market: marketTotal,
        utility: utilityTotal,
        location: locationTotal,
        other: otherTotal,
        trendedPricePerBasis,
        total: netTotal,
        adjustedPricePerBasis,
      })
    })
    const groupNames = [
      'market',
      'trendedPricePerBasis',
      'location',
      'utility',
      'other',
      'total',
      'adjustedPricePerBasis',
    ]
    groupNames.forEach((group: string) => {
      let label = group
      let formatter = formatPercentageString
      switch (group) {
        case 'trendedPricePerBasis':
        case 'adjustedPricePerBasis':
          label = startCase(group)
          formatter = formatCurrencyFloat
          break
        case 'total':
          label = upperFirst(group)
          formatter = formatPercentageString
          break
        default:
          label = `${upperFirst(group)} Total`
          formatter = formatPercentageString
      }
      const totalRow: any = {
        readOnly: true,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.text,
        id: `${group}Total`,
        label,
        // TODO: make total row sticky
        rowDef: { hideAction: true, summary: true },
        totalRow: true,
      }

      totalsByCompId.forEach(total => {
        const { compId } = total
        totalRow[compId] = formatter(total[group])
      })

      totalRowsByType[group] = totalRow
    })
    return totalRowsByType
  }, [compAdjustments, selectedComps, unitOfComparison])

  const compInformationRows = useMemo(() => {
    const rows = getCompInformationRows(subjectPropertyInformation, showInExport, unitOfComparison)

    rows.map((row: any) => {
      const { formatter } = row
      selectedComps.forEach((comp: any) => {
        const { id } = comp
        let value = get(comp, row.id)
        switch (row.id) {
          case COMP_INFO_ROW_IDS_TO_PROPERTY.adjustedSalePrice:
            value = value || get(comp, DEED_SALE_PRICE_PROPERTY)
            break

          case DEFAULT_COMP_INFO_ROW_IDS.parkingRatio:
            value = getParkingRatio(comp.propertyInformation, unitOfComparison)
            break

          case ADDITIONAL_COMP_INFO_ROW_IDS.pricePerUnit:
            value = calculatePricePerUnit(
              comp.saleInformation.adjustedSalePrice,
              comp.saleInformation.salePrice,
              comp.propertyInformation.residentialUnits,
              comp.propertyInformation.commercialUnits
            )
            break

          case ADDITIONAL_COMP_INFO_ROW_IDS.pricePerSF:
            value = calculatePricePerSF(
              comp.saleInformation.adjustedSalePrice,
              comp.saleInformation.salePrice,
              comp.propertyInformation.grossBuildingArea
            )
            break

          default:
            break
        }

        row[id] = formatter ? formatter(value) : value
      })

      return row
    })
    return rows
  }, [selectedComps, subjectPropertyInformation, showInExport, unitOfComparison])

  const columns: RowBasedTableColumn[] = useMemo(() => getColumns(), [getColumns])
  const compInformationColumns: RowBasedTableColumn[] = useMemo(
    () => getCompInformationColumns(),
    [getCompInformationColumns]
  )
  const marketRows = useMemo(() => getRows('market'), [getRows])
  const locationAdjustmentRows = useMemo(() => getRows('location'), [getRows])
  const utilityRows = useMemo(() => getRows('utility'), [getRows])
  const otherRows = useMemo(() => getRows('other'), [getRows])

  const marketAdjustmentRows = [...marketRows, totalRows.market, totalRows.trendedPricePerBasis]
  const tableRows = useMemo(() => {
    const allAdjustments = [
      ...locationAdjustmentRows,
      ...utilityRows,
      ...otherRows,
      totalRows.total,
      totalRows.adjustedPricePerBasis,
    ]
    return allAdjustments
  }, [locationAdjustmentRows, utilityRows, otherRows, totalRows.adjustedPricePerBasis, totalRows.total])

  return (
    <>
      <Paper>
        <Grid container spacing={2}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Sales Adjustment Grid</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sx={{ paddingBottom: '12px' }}>
              <Typography variant="subtitle2">Market Adjustments</Typography>
            </Grid>
            <RowBasedTable
              id="market-adjustments-table"
              columns={columns}
              rows={marketAdjustmentRows}
              onRowUpdate={handleUpdateAdjustmentRow}
              onManyRowsUpdate={noop}
              getCustomColumnConfig={getCustomColumnConfig}
              hideIndexColumn
              onColumnDragEnd={noop}
              onRowsDragEnd={noop}
              onColumnDelete={noop}
              onColumnUpdate={noop}
              onRowDelete={noop}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item sx={{ paddingBottom: '12px', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2">Comp Information</Typography>
              <Button
                variant="text"
                data-qa="comp-information-add-row-btn"
                onClick={openComparableInfoModal}
                disabled={shouldDisableAddCompInfoRow()}
              >
                ADD ROW
              </Button>
            </Grid>
            <RowBasedTable
              id="comp-information-table"
              columns={compInformationColumns}
              rows={compInformationRows}
              getRowStyle={getCompInformationRowStyle}
              onRowUpdate={handleUpdateCompInformationRow}
              onManyRowsUpdate={noop}
              getCustomColumnConfig={getCustomColumnConfig}
              hideIndexColumn
              onColumnDragEnd={noop}
              onRowsDragEnd={noop}
              onColumnDelete={noop}
              onColumnUpdate={noop}
              onRowDelete={deleteAdditionalCompInfoRow}
              disableVirtualization
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="subtitle2">Adjustments</Typography>
              </Grid>
              <Grid item>
                <Button variant="text" onClick={addCustomAdjustment}>
                  Add Adjustment
                </Button>
              </Grid>
            </Grid>
            <RowBasedTable
              id="physical-adjustments-table"
              columns={columns}
              rows={tableRows}
              onRowUpdate={handleUpdateAdjustmentRow}
              onManyRowsUpdate={noop}
              getCustomColumnConfig={getCustomColumnConfig}
              hideIndexColumn
              onColumnDragEnd={noop}
              onRowsDragEnd={noop}
              onColumnDelete={noop}
              onColumnUpdate={noop}
              onRowDelete={handleDeleteRow}
              disableVirtualization
            />
          </Grid>
        </Grid>
      </Paper>
      {isAddComparableInfoModalOpen && (
        <AddComparableInformationDialog
          onSave={addCompInfoRow}
          onCancel={closeComparableInfoModal}
          showInExport={showInExport}
        />
      )}
    </>
  )
}
