import { camelCase, values } from 'lodash'

import { BASIS_TYPES, BASIS_LABELS } from '../../../../../../../shared/constants/report/incomeApproach/taxes'

export const TAX_PER_SQFT = [0.5, 1, 2, 5]

export const TAX_PROPS = {
  taxClassOne: true,
  taxClassTwo: true,
  taxClassTwoAB: true,
  taxClassFour: true,
  taxClassOther: false,
}

export const TAX_PROPS_LABELS = {
  taxClassOne: '1',
  taxClassTwo: '2',
  taxClassTwoAB: '2A/2B',
  taxClassFour: '4',
  taxClassOther: 'Other',
}

export const COLUMNS = [
  { columnName: 'STADDR', width: 250 },
  { columnName: 'YEARBUILT', width: 100 },
  { columnName: 'TAXYEAR', width: 100 },
  { columnName: 'BLDGAREA', width: 150 },
  { columnName: 'TAXCLASS', width: 100 },
  { columnName: 'BUILDINGCLASS', width: 150 },
  { columnName: 'distance', width: 100 },
  { columnName: 'taxesPerBasis', width: 100 },
]

export const TAX_COMPS_TABLE_COLUMNS = basis => [
  { name: 'STADDR', title: 'Address' },
  { name: 'YEARBUILT', title: 'Year Built' },
  { name: 'TAXYEAR', title: 'Tax Year' },
  basis === 'PSF' ? { name: 'BLDGAREA', title: 'Square Feet' } : { name: 'unitsRes', title: 'Units' },
  { name: 'TAXCLASS', title: 'Tax Class' },
  { name: 'BUILDINGCLASS', title: 'Building Class' },
  { name: 'distance', title: 'Distance' },
  { name: 'taxesPerBasis', title: basis === 'PSF' ? 'Taxes/SF' : 'Taxes/Unit' },
]

export const getSelectedTaxCompColumns = (basis, classes) => [
  { name: 'address', title: 'Address', className: classes.addressHeaderCell },
  { name: 'yearBuilt', title: 'Year Built', className: classes.numberHeaderCell },
  { name: 'isYearBuiltEstimate', title: 'Est.', className: classes.yearBuiltEstHeaderCell },
  { name: 'taxYear', title: 'Tax Year', className: classes.numberHeaderCell },
  {
    name: 'basis',
    title: basis === BASIS_TYPES.PER_UNIT ? BASIS_LABELS.NUMBER_OF_UNITS : BASIS_LABELS.SQUARE_FEET,
    className: classes.numberHeaderCell,
  },
  {
    name: 'taxesPerBasis',
    title: basis === BASIS_TYPES.PER_UNIT ? BASIS_LABELS.TAXES_PER_UNIT : BASIS_LABELS.TAXES_PSF,
    className: classes.numberHeaderCell,
  },
  { name: 'sourceOfInformation', title: 'Source of Information', className: classes.leadHeaderCell },
  { name: 'actions', title: 'Actions', className: classes.headerCellActions },
]

export const NYC_BUILDING_CLASSES = [
  'A0',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'B1',
  'B2',
  'B3',
  'B9',
  'C0',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  'C7',
  'C8',
  'C9',
  'CM',
  'D0',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'D6',
  'D7',
  'D8',
  'D9',
  'E1',
  'E2',
  'E3',
  'E4',
  'E7',
  'E9',
  'F1',
  'F2',
  'F4',
  'F5',
  'F8',
  'F9',
  'G0',
  'G1',
  'G2',
  'G3',
  'G4',
  'G5',
  'G6',
  'G7',
  'G8',
  'G9',
  'GU',
  'GW',
  'HB',
  'HH',
  'HR',
  'HS',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'H7',
  'H8',
  'H9',
  'I1',
  'I2',
  'I3',
  'I4',
  'I5',
  'I6',
  'I7',
  'I9',
  'J1',
  'J2',
  'J3',
  'J4',
  'J5',
  'J6',
  'J7',
  'J8',
  'J9',
  'K1',
  'K2',
  'K3',
  'K4',
  'K5',
  'K6',
  'K7',
  'K8',
  'K9',
  'L1',
  'L2',
  'L3',
  'L8',
  'L9',
  'M1',
  'M2',
  'M3',
  'M4',
  'M9',
  'N1',
  'N2',
  'N3',
  'N4',
  'N9',
  'O1',
  'O2',
  'O3',
  'O4',
  'O5',
  'O6',
  'O7',
  'O8',
  'O9',
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
  'P9',
  'Q1',
  'Q2',
  'Q3',
  'Q4',
  'Q5',
  'Q6',
  'Q7',
  'Q8',
  'Q9',
  'RA',
  'RB',
  'RG',
  'RH',
  'RK',
  'RP',
  'RR',
  'RS',
  'RT',
  'RW',
  'R0',
  'R1',
  'R2',
  'R3',
  'R4',
  'R5',
  'R6',
  'R7',
  'R8',
  'R9',
  'S0',
  'S1',
  'S2',
  'S3',
  'S4',
  'S5',
  'S9',
  'T1',
  'T2',
  'T9',
  'U0',
  'U1',
  'U2',
  'U3',
  'U4',
  'U5',
  'U6',
  'U7',
  'U8',
  'U9',
  'V0',
  'V1',
  'V2',
  'V3',
  'V4',
  'V5',
  'V6',
  'V7',
  'V8',
  'V9',
  'W1',
  'W2',
  'W3',
  'W4',
  'W5',
  'W6',
  'W7',
  'W8',
  'W9',
  'Y1',
  'Y2',
  'Y3',
  'Y4',
  'Y5',
  'Y6',
  'Y7',
  'Y8',
  'Y9',
  'Z0',
  'Z1',
  'Z2',
  'Z3',
  'Z4',
  'Z5',
  'Z7',
  'Z8',
  'Z9',
]

export const NYC_BUILDING_CLASSES_LIST = NYC_BUILDING_CLASSES.map(buildingClass => ({
  value: buildingClass,
  label: buildingClass,
}))

export const TaxTabs = {
  CURRENT: 'Current',
  PROJECTED: 'Projected',
  COMPARABLES: 'Comparables',
  SUMMARY: 'Summary',
}

export const TAX_TABS = values(TaxTabs).map(label => ({ label, value: label, key: camelCase(label) }))
