import { get, capitalize } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Warning from '@mui/icons-material/Warning'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Grid, Typography, Box, Paper } from '@mui/material'

import {
  COMMERCIAL_PROPERTY_SUBTYPES,
  CompPropertyTypes,
  propertyTypeOptions,
  PropertyTypes,
} from 'shared/constants/property/propertyTypes'
import { SECTIONS } from 'shared/helpers/propertyType'
import { SF_BASIS_OPTIONS, DEFAULT_SF_ANALYSIS_BASIS } from 'shared/constants/report'
import { ANCHOR_PROPERTY_DESCRIPTION } from 'shared/constants/report/dataCollections'

import { required } from 'client-shared/utils/validation'
import { NativeDropdownWithOther } from 'client-shared/components/DropDown/NativeDropDownField'
import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

import { includeLocalLaw97 } from 'shared/utils/report/propertyInformation'

import {
  ElevatorTypes,
  MAX_UNITS_NUMBER,
  LOSS_DATA_TOOLTIP,
  SFAnalysisHelperText,
  GENERAL_CONDITIONS_OPTIONS,
} from '../../../constants'
import { DropDown, Text, Number, Tooltip, RadioButtonList, CheckboxWithLabel } from '../../../../shared/components'

import UnitCountSection from './UnitCountSection'
import SquareFootageTooltipText from './SquareFootageTooltipText'

const AS_COMPLETE_CURRENT_BUILDING_DESCRIPTION_TITLE = 'As Complete'
const AS_IS_CURRENT_BUILDING_DESCRIPTION_TITLE = 'As Is'

const commercialPropertySubTypeOptions = COMMERCIAL_PROPERTY_SUBTYPES.map(value => {
  return {
    value,
    label: CompPropertyTypes[value],
  }
})

const elevatorOptions = Object.keys(ElevatorTypes).map(key => {
  return {
    label: capitalize(ElevatorTypes[key]),
    value: ElevatorTypes[key],
  }
})

const SF_BASIS_RADIO_BUTTON_ITEMS = Object.entries(SF_BASIS_OPTIONS).map(([acronym, fullString]) => ({
  value: acronym,
  label: `${fullString} (${acronym})`,
}))

const generalConditionsOptions = arrayToKeyValuePairs(GENERAL_CONDITIONS_OPTIONS)

export const PropertyDescription = ({ valueAsComplete, classes, form, locationIdentifier }) => {
  const { basisForSFAnalysis, propertyType, grossBuildingArea } = form.values
  const currentPropertyType = get(form.values, 'current.propertyType')
  const defaultBasisSelected = basisForSFAnalysis === DEFAULT_SF_ANALYSIS_BASIS

  return (
    <Paper id={ANCHOR_PROPERTY_DESCRIPTION} elevation={0} className={classes.noBackground}>
      <Typography variant="h6" className={classes.cardSpacing}>
        Property Description
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Text name="buildingName" label="Building Name" margin="normal" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <Text name="buildingClass" label="Building Class" margin="normal" fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <br />
          <Typography variant="subtitle1">
            {valueAsComplete
              ? AS_COMPLETE_CURRENT_BUILDING_DESCRIPTION_TITLE
              : AS_IS_CURRENT_BUILDING_DESCRIPTION_TITLE}
            {` Building Description`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid item xs={12} className={classes.propertyTypeDropDown}>
            <DropDown label="Property Type" name="propertyType" items={propertyTypeOptions} disabled={true} />
          </Grid>

          <UnitCountSection
            section={SECTIONS.RESIDENTIAL_UNIT_COUNT}
            fieldName="residentialUnitCount"
            fieldLabel="Residential Units"
            tooltipText="Go To Residential Rent Roll"
            link="./residential-rent-roll"
          />
          <UnitCountSection
            section={SECTIONS.COMMERCIAL_UNIT_COUNT}
            fieldName="commercialUnitCount"
            fieldLabel="Commercial Units"
            tooltipText="Go To Commercial Units"
            link="./commercial-units"
          />
        </Grid>
        <Grid item xs={6}>
          {propertyType === PropertyTypes.COMMERCIAL && (
            <Grid item xs={12} className={classes.propertyTypeDropDown}>
              <DropDown name="propertySubtype" label="Property Subtype" items={commercialPropertySubTypeOptions} />
            </Grid>
          )}
          <Number name="floors" label="Floors" decimalScale={2} helperText=" " />
          <DropDown label="Building Type" name="buildingType" items={elevatorOptions} />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '0px', paddingTop: '0px' }}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
            spacing={1}
            className={classes.subtitle}
            sx={{ marginTop: '0px', paddingTop: '0px' }}
          >
            <Grid item>
              <Typography noWrap variant="subtitle2" sx={{ marginTop: '0px', paddingTop: '0px' }}>
                Basis for Square Foot Analysis
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip classes={{ root: classes.tooltip }} text={SquareFootageTooltipText}>
                <span>
                  <InfoOutlined />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <RadioButtonList name="basisForSFAnalysis" horizontal items={SF_BASIS_RADIO_BUTTON_ITEMS} />

          {!defaultBasisSelected && (
            <Grid xs={6}>
              <Box pr="8px" mb="8px">
                <Number
                  adornment={
                    <Tooltip text={LOSS_DATA_TOOLTIP} placement="top">
                      <Warning className={classes.warningIcon} />
                    </Tooltip>
                  }
                  helperText={SFAnalysisHelperText}
                  name="basisForSFAnalysisValue"
                  label={SF_BASIS_OPTIONS[basisForSFAnalysis]}
                  decimalScale={2}
                  margin="normal"
                  thousandSeparator
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={6}>
            <Box pr="8px">
              <Number
                adornment={
                  defaultBasisSelected ? (
                    <Tooltip text={LOSS_DATA_TOOLTIP} placement="top">
                      <Warning className={classes.warningIcon} />
                    </Tooltip>
                  ) : undefined
                }
                helperText={defaultBasisSelected ? SFAnalysisHelperText : ' '}
                name="grossBuildingArea"
                label="Gross Building Area"
                decimalScale={2}
                thousandSeparator
              />
            </Box>
          </Grid>

          {includeLocalLaw97(locationIdentifier, grossBuildingArea) && (
            <Grid item xs={6}>
              <CheckboxWithLabel
                name="hasLocalLaw97Penalties"
                label="Subject has penalties on accelerator in violation of Local Law 97"
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {valueAsComplete && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <br />
              Current Building Description
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12} className={classes.currentPropertyTypeDropDown}>
              <DropDown label="Property Type" name="current.propertyType" items={propertyTypeOptions} />
            </Grid>

            <Grid item xs={12}>
              <Number
                name="current.residentialUnitCount"
                label="Residential Units"
                margin="normal"
                allowNegative={false}
                validate={required}
                max={MAX_UNITS_NUMBER}
                helperText=" "
              />
            </Grid>

            <Grid item xs={12}>
              <Number
                name="current.commercialUnitCount"
                label="Commercial Units"
                margin="normal"
                allowNegative={false}
                validate={required}
                max={MAX_UNITS_NUMBER}
                helperText=" "
              />
            </Grid>

            <Grid item xs={12}>
              <Typography noWrap variant="subtitle2">
                Square Footage
              </Typography>
            </Grid>

            {!defaultBasisSelected && (
              <Grid item xs={12}>
                <Number
                  name="current.basisForSFAnalysisValue"
                  label={SF_BASIS_OPTIONS[basisForSFAnalysis]}
                  decimalScale={2}
                  margin="normal"
                  thousandSeparator
                  helperText=" "
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Number
                name="current.grossBuildingArea"
                label="Gross Building Area"
                decimalScale={2}
                margin="normal"
                thousandSeparator
                helperText=" "
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {currentPropertyType === PropertyTypes.COMMERCIAL && (
              <Grid item xs={12} className={classes.currentPropertyTypeDropDown}>
                <DropDown
                  name="current.propertySubtype"
                  label="Property Subtype"
                  items={commercialPropertySubTypeOptions}
                />
              </Grid>
            )}
            <Number name="current.floors" label="Floors" margin="normal" decimalScale={2} helperText=" " />
            <DropDown label="Building Type" name="current.buildingType" items={elevatorOptions} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <br />
            Property Condition
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={6}>
            <NativeDropdownWithOther
              displayEmpty
              items={generalConditionsOptions}
              classes={{ root: classes.optionsList }}
              name="generalCondition"
              label="As Is General Property Condition"
              placeholder="Select As Is General Property Condition"
            />
          </Grid>
        </Grid>

        {valueAsComplete && (
          <Grid item xs={12}>
            <Grid item xs={6}>
              <NativeDropdownWithOther
                displayEmpty
                items={generalConditionsOptions}
                classes={{ root: classes.optionsList }}
                name="generalAsStabilizedCondition"
                label="As Stabilized Assumed General Property Condition"
                placeholder="Select As Stabilized Assumed General Property Condition"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

PropertyDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  valueAsComplete: PropTypes.bool.isRequired,
  locationIdentifier: PropTypes.string.isRequired,
}
