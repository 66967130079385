import React, { createRef } from 'react'
import { get, pick } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { FeatureToggle, getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'

import { SALES_ADJUSTMENT_GRID_KEY } from 'shared/constants/report/keysAndDataPaths'

import AutomationCTA from 'client-shared/components/AutomationCTA'
import { Callout } from 'client-shared/components'

import { ENABLE_SALES_ADJUSTMENT_GRID_V2, USE_COMMERCIAL_SUBTYPE } from 'shared/constants/featureFlags'
import { SALES_ADJUSTMENT_AUTOMATION_TYPES } from 'shared/constants/salesApproach'

import { precisionRound } from 'shared/utils/numberOperations'

import { mapSalesAdjustmentAmenities } from 'shared/helpers/amenities'

import wrapForm from '../../wrapForm'

import { withMicrofrontend } from '../../../../shared/hooks/useMicrofrontend'
import { errorNotification } from '../../../../shared/redux/actions/notifications'

import { CompPlexEvents } from '../../../../shared/constants/compPlex'
import { updateAppraisal } from '../../../../shared/utils/compPlex.gql'

import { saveReport } from '../../../redux/actions/report'

import { getAuthorizationHeader } from '../../../../core/api'

import {
  transformSalesCompRawInput,
  transformSalesCompToRawOutput,
} from '../SalesCompsSearch/CreateSalesComp/drm/helpers/transformers'
import { DATA_PATH as LEGACY_BASE_DATA_PATH } from '..'

import { mapSubjectProperty } from '../helpers'

import { areSameComps } from '../tools'

import SalesAdjustmentDiscussions from './SalesAdjustmentDiscussions'
import SalesComparableSetup from './SalesComparableSetup'
import { SalesAdjustmentGridV2 } from './SalesAdjustmentGridV2'
import { adjustmentDefinitions, groupAdjustmentWarningsByAddress } from './adjustments'
import AdjustmentAutomationWarning from './AdjustmentAutomationWarning'
import SalesAdjustmentGrid from './SalesAdjustmentGrid'
import { salesAdjustmentDecorators } from './decorators'

const EDIT_COMP_SOURCE_SALES_ADJUSTMENT = 'sales-adjustment'

class SalesAdjustmentGridContainer extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    formPath: PropTypes.array.isRequired,
    reportId: PropTypes.string.isRequired,
    valueConclusionType: PropTypes.string.isRequired,
    dateOfValue: PropTypes.string.isRequired,
    dateOfStabilization: PropTypes.string.isRequired,
    authenticatedUser: PropTypes.object.isRequired,
    saveReport: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
    compPlexLoaded: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.compPlexRef = createRef()
  }

  state = {
    initialLocalSalesComp: null,
    snapshotRequested: false,
  }

  componentDidMount() {
    const { compPlexLoaded, errorNotification } = this.props
    if (!compPlexLoaded) {
      errorNotification('Failed to load sales comps service. Some features will not be available.')
    }

    if (this.compPlexRef.current) {
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_UPDATED, this.handleEditModalUpdated)
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_SAVED, this.handleEditModalSaved)
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)
      this.compPlexRef.current.addEventListener(CompPlexEvents.ERROR, this.handleError)
    }
  }

  componentWillUnmount() {
    if (this.compPlexRef.current) {
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_UPDATED, this.handleEditModalUpdated)
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_SAVED, this.handleEditModalSaved)
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)
      this.compPlexRef.current.removeEventListener(CompPlexEvents.ERROR, this.handleError)
    }
  }

  showEditModal = initialLocalSalesComp => {
    this.setState({ initialLocalSalesComp })
  }

  hideEditModal = () => {
    this.setState({ initialLocalSalesComp: null })
  }

  getEditCompProps() {
    const { initialLocalSalesComp } = this.state
    if (!initialLocalSalesComp) {
      return null
    }
    const { salesTransactionId, salesTransactionVersion } = initialLocalSalesComp
    if (salesTransactionId && salesTransactionVersion) {
      return {
        source: EDIT_COMP_SOURCE_SALES_ADJUSTMENT,
        salesTransactionId,
        salesTransactionVersion,
        requireLatest: true,
      }
    } else {
      return {
        source: EDIT_COMP_SOURCE_SALES_ADJUSTMENT,
        salesComp: transformSalesCompRawInput(initialLocalSalesComp),
        requireLatest: true,
      }
    }
  }

  handleEditModalClosed = event => {
    const { source } = event.detail
    if (source !== EDIT_COMP_SOURCE_SALES_ADJUSTMENT) {
      return
    }
    this.hideEditModal()
  }

  handleEditModalUpdated = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_SALES_ADJUSTMENT) {
      return
    }
    this.saveEditedComp(salesComp, this.state.initialLocalSalesComp)
  }

  handleEditModalSaved = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_SALES_ADJUSTMENT) {
      return
    }
    this.saveEditedComp(salesComp, this.state.initialLocalSalesComp)
  }

  handleEditModalCompleted = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_SALES_ADJUSTMENT) {
      return
    }
    this.saveEditedComp(salesComp, this.state.initialLocalSalesComp)
    this.hideEditModal()
  }

  handleError = event => {
    const { error } = event.detail
    this.props.errorNotification(error.message || error)
  }

  saveEditedComp = (updatedSalesComp, initialLocalSalesComp) => {
    const { form, formPath } = this.props
    const formDataPath = formPath.join('.')
    if (!initialLocalSalesComp) {
      return
    }
    const updatedLocalSalesComp = transformSalesCompToRawOutput(updatedSalesComp, initialLocalSalesComp)

    const selectedComps = get(form, 'values.selectedComps', [])
    const compToEditIndex = selectedComps.findIndex(selectedComp => areSameComps(updatedLocalSalesComp, selectedComp))
    form.change(`selectedComps.${compToEditIndex}`, updatedLocalSalesComp)

    this.props.saveReport(formDataPath)
  }

  getAutomationErrorMessage = () => {
    return "We're sorry, the automation did not run successfully. Please fill out this page as usual."
  }

  get groupedAdjustmentWarnings() {
    const { compWarnings } = this.state
    return groupAdjustmentWarningsByAddress(compWarnings)
  }

  automateAdjustments = async () => {
    const { form, formPath } = this.props
    const formDataPath = formPath.join('.')
    const { selectedComps, compAdjustments, subjectProperty } = form.values

    const changes = await Promise.all(
      adjustmentDefinitions.map(async adjustmentDefinition => {
        const adjustments = await adjustmentDefinition.calculateAdjustments(selectedComps, subjectProperty)
        const adjustmentIndex = compAdjustments[0]?.adjustments.findIndex(
          adjustment => adjustment.name === adjustmentDefinition.adjustmentType
        )

        return selectedComps.map((comp, index) => {
          const adjustment = adjustments[index]
          const compAdjustmentIndex = compAdjustments.findIndex(compAdjustment => compAdjustment.compId === comp._id)
          if (!compAdjustmentIndex || !adjustmentIndex) {
            return () => {}
          }
          return () =>
            form.change(
              `compAdjustments[${compAdjustmentIndex}].adjustments[${adjustmentIndex}].value`,
              precisionRound(adjustment, 3)
            )
        })
      })
    )

    form.batch(() => {
      changes.flat().forEach(change => change())
      form.change('automationType', SALES_ADJUSTMENT_AUTOMATION_TYPES.STATIC)
    })

    global.automationSaved = 'auto-adjust-sales-comps'
    this.props.saveReport(formDataPath)
  }

  render() {
    const { form, valueConclusionType, dateOfValue, dateOfStabilization, authenticatedUser, reportId } = this.props
    const editCompProps = this.getEditCompProps()
    const formValues = get(this.props, 'form.values') || {}
    const selectedComps = get(formValues, 'selectedComps', [])
    const selectedCompIds = selectedComps.map(salesComp => {
      return {
        salesTransactionId: salesComp.salesTransactionId,
      }
    })
    const reportNumber = get(formValues, 'reportNumber')
    const reportData = { jobNumber: reportNumber, reportId }

    return (
      <div>
        <comp-plex
          ref={this.compPlexRef}
          edit-comp-props={JSON.stringify(editCompProps)}
          auth-user={JSON.stringify(authenticatedUser)}
          webapp-auth-header={getAuthorizationHeader().Authorization}
          webapp-api-url={global.env.apiUrl}
          selected-comps={JSON.stringify(selectedCompIds)}
          meta-data={JSON.stringify(reportData)}
        />
        <SalesComparableSetup
          formValues={formValues}
          change={form.change}
          batch={form.batch}
          valueConclusionType={valueConclusionType}
          dateOfValue={dateOfValue}
          dateOfStabilization={dateOfStabilization}
        />
        {this.groupedAdjustmentWarnings.systemWarnings.length > 0 && (
          <Callout
            variant="error"
            content="We're sorry, some parts of the automation did not run as expected. Please fill out the page as usual."
          />
        )}
        <AutomationCTA
          CTAMessage="Automate your adjustments"
          successMessage="adjustments automated"
          disableCTA={selectedComps.length === 0}
          errorMessage={this.getAutomationErrorMessage()}
          warningMessage={
            <AdjustmentAutomationWarning
              selectedComps={selectedComps}
              reportId={reportId}
              showEditModal={this.showEditModal}
            />
          }
          warningTitle="Some adjustments cannot not be automated"
          onAutomationRun={this.automateAdjustments}
          showWarningsSeparateFromMainAlert={true}
        />
        <FeatureToggle featureFlag={ENABLE_SALES_ADJUSTMENT_GRID_V2}>
          <SalesAdjustmentGridV2 form={form} showEditModal={this.showEditModal} />
        </FeatureToggle>
        <FeatureToggle featureFlag={ENABLE_SALES_ADJUSTMENT_GRID_V2} checkForValue={false}>
          <SalesAdjustmentGrid
            formValues={formValues}
            change={form.change}
            batch={form.batch}
            showEditModal={this.showEditModal}
            registerField={form.registerField}
          />
        </FeatureToggle>
        <SalesAdjustmentDiscussions formValues={formValues} change={form.change} batch={form.batch} />
      </div>
    )
  }
}

const formOptions = {
  heading: 'Adjust Comps',
  keepDirtyOnReinitialize: true,
  mutators: {
    ...arrayMutators,
  },
  decorators: [...salesAdjustmentDecorators],
  onPreSave: async (formValues, formApi, reportId, authenticatedUser, reportData) => {
    const jobNumber = reportData.report.reportInformation.reportNumber
    const usedSalesTransactionIds = formValues.selectedComps
      .filter(comp => comp.salesTransactionId)
      .map(comp => comp.salesTransactionId)

    await updateAppraisal({
      reportNumber: reportId,
      jobNumber,
      salesTransactionIds: usedSalesTransactionIds,
    })

    return formValues
  },
}

const mapStateToProps = (state, formPath, salesApproachType) => {
  const corner = get(state, 'report.reportData.propertyInformation.propertyMaps.corner')
  const units = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll.units')
  const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
  const valueConclusionType = get(state, 'report.reportSettings.valueConclusionType')

  const condition = get(state, 'report.reportData.propertyInformation.generalPropertyDescription.generalCondition')
  const asStabilizedCondition = get(
    state,
    'report.reportData.propertyInformation.generalPropertyDescription.generalAsStabilizedCondition'
  )
  const propertyType = get(state, 'report.reportData.propertyType')
  const propertySubtype = get(state, 'report.reportData.propertyInformation.propertySummary.propertySubtype')
  const { city, yearBuilt, isElevator, residentialUnitCount } = get(
    state,
    'report.reportData.propertyInformation.propertySummary'
  )
  const neighborhood = get(state, 'report.reportData.propertyInformation.propertyMarket.marketNeighborhood')
  const propertyState = get(state, 'report.reportData.propertyInformation.propertySummary.state')
  const typicalPropertyConditions = get(state, 'report.reportData.propertyInformation.typicalResidentialConditions')
  const propertyAmenities = get(state, 'report.reportData.propertyInformation.amenities')
  const amenities = mapSalesAdjustmentAmenities({ ...propertyAmenities.building, ...propertyAmenities.unit })

  const dateOfValue = get(state, 'report.reportData.report.reportInformation.dateOfValuation')
  const dateOfStabilization = get(state, 'report.reportData.acas.salesApproach.dateOfFinalValueAsStabilized')

  const authenticatedUser = pick(get(state, 'authentication.user'), ['id', 'username', 'fullName'])

  const useCommercialSubtype = getFeatureFlagValue(USE_COMMERCIAL_SUBTYPE)

  const reportNumber = get(state, 'reportData.report.reportInformation.reportNumber')

  return {
    formPath,
    initialValues: {
      ...get(state, `report.reportData.salesApproach.${salesApproachType}.salesAdjustmentGrid`),
      basisOfComparison: 'sf',
      city,
      subjectProperty: mapSubjectProperty(state),
      utilityAdjustmentLabel: get(
        state,
        `report.reportData.salesApproach.${salesApproachType}.utilityAdjustmentLabel`,
        []
      ),
      otherAdjustmentLabel: get(state, `report.reportData.salesApproach.${salesApproachType}.otherAdjustmentLabel`, [
        'Other Adjustments',
      ]),
      state: propertyState,
      neighborhood,
      yearBuilt,
      isElevator,
      residentialUnitCount,
      corner,
      units,
      condition,
      asStabilizedCondition,
      propertyType,
      propertySubtype,
      useCommercialSubtype,
      typicalPropertyConditions,
      amenities,
      valueAsComplete,
      authenticatedUser,
      reportNumber,
    },
    valueConclusionType,
    dateOfValue,
    dateOfStabilization,
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
  saveReport: formPath => dispatch(saveReport(formPath)),
})

export const createSalesAdjustmentGridContainer = (baseDataPath, salesApproachType) => {
  const DATA_PATH = [...baseDataPath, SALES_ADJUSTMENT_GRID_KEY]
  return compose(
    wrapForm(DATA_PATH, formOptions, state => mapStateToProps(state, DATA_PATH, salesApproachType), mapDispatchToProps),
    withMicrofrontend('compplex', 'compPlexLoaded')
  )(SalesAdjustmentGridContainer)
}

export default createSalesAdjustmentGridContainer(LEGACY_BASE_DATA_PATH)
