import React from 'react'
import PropTypes from 'prop-types'
import { get, isNil } from 'lodash'

import {
  Grid,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardContent,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Add from '@mui/icons-material/Add'
import ModeEdit from '@mui/icons-material/ModeEdit'
import Delete from '@mui/icons-material/Delete'

import { DragDropContext } from 'react-beautiful-dnd'

import BoweryDate from '@bowery-valuation/bowery-date'

import { getRentPsfLabel } from 'shared/helpers/incomeApproach/commercial'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { MONTH_DAY_YEAR_DATE_FORMAT } from 'client-shared/constants'

import AlertInfo from 'client-shared/components/AlertInfo'
import { DroppableTable, DraggableTableRow } from 'client-shared/components/DragDropTable'

import { Labels, UNSORTED_GROUP_ID, NOT_AVAILABLE } from '../constants'

import GroupNameDialog from './GroupNameDialog'
import RemoveGroupDialog from './RemoveGroupDialog'

const styles = {
  dragCell: {
    width: '5%',
  },
  baseCell: {
    width: '10%',
  },
  dateCell: {
    width: '12%',
  },
}

class CommercialCompGroups extends React.PureComponent {
  static propTypes = {
    compGroups: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string.isRequired,
        units: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string.isRequired,
            isVacant: PropTypes.bool.isRequired,
            tenantName: PropTypes.string.isRequired,
            signedAt: PropTypes.string.isRequired,
            expiresAt: PropTypes.string.isRequired,
            terms: PropTypes.string.isRequired,
            rentPsf: PropTypes.number,
            monthlyRentPsf: PropTypes.number,
          })
        ).isRequired,
      })
    ).isRequired,
    thereAreNoCommercialUnits: PropTypes.bool.isRequired,
    rentBasis: PropTypes.string.isRequired,
    onAddCompGroup: PropTypes.func.isRequired,
    onChangeGroupName: PropTypes.func.isRequired,
    onRemoveCompGroup: PropTypes.func.isRequired,
    onMoveUnit: PropTypes.func.isRequired,
    onChangeBaseUnit: PropTypes.func.isRequired,
  }

  state = {
    isCompGroupAdding: false,
    compGroupToEdit: null,
    compGroupToRemove: null,
    draggingId: '',
  }

  get rentPsfLabel() {
    const { rentBasis } = this.props
    return getRentPsfLabel(rentBasis, Labels.MONTHLY_PSF_RENT, Labels.PSF_RENT)
  }

  closeDialogs = () => {
    this.setState({ isCompGroupAdding: false, compGroupToEdit: null, compGroupToRemove: null })
  }

  getAddGroupDialog = () => {
    const { isCompGroupAdding } = this.state
    const { onAddCompGroup } = this.props

    return (
      isCompGroupAdding && (
        <GroupNameDialog
          title={Labels.ADD_COMP_GROUP}
          submitButtonText={Labels.ADD_COMP_GROUP}
          value=""
          onCancel={this.closeDialogs}
          onSubmit={name => {
            this.closeDialogs()
            onAddCompGroup(name)
          }}
        />
      )
    )
  }

  getRenameGroupDialog = () => {
    const { compGroupToEdit } = this.state
    const { onChangeGroupName } = this.props

    return (
      compGroupToEdit && (
        <GroupNameDialog
          title={Labels.UPDATE_COMP_GROUP}
          submitButtonText={Labels.UPDATE_COMP_GROUP}
          value={compGroupToEdit.name}
          onCancel={this.closeDialogs}
          onSubmit={name => {
            this.closeDialogs()
            onChangeGroupName(compGroupToEdit._id, name)
          }}
        />
      )
    )
  }

  getRemoveGroupDialog = () => {
    const { compGroupToRemove } = this.state
    const { onRemoveCompGroup } = this.props

    return (
      compGroupToRemove && (
        <RemoveGroupDialog
          onCancel={this.closeDialogs}
          onSubmit={() => {
            this.closeDialogs()
            onRemoveCompGroup(compGroupToRemove._id)
          }}
        />
      )
    )
  }

  onAddCompGroupClick = () => {
    this.setState({ isCompGroupAdding: true })
  }

  onCompGroupEditClick = compGroup => {
    this.setState({ compGroupToEdit: compGroup })
  }

  onCompGroupRemoveClick = compGroup => {
    this.setState({ compGroupToRemove: compGroup })
  }

  onDragStart = event => {
    this.setState({ draggingId: event.source.droppableId })
  }

  formatValue = (unit, fieldName, unitIndex) => {
    const isVacant = unit.isVacant
    const fieldValue = get(unit, fieldName)

    switch (fieldName) {
      case 'tenantName':
        return isVacant ? get(unit, 'vacantName') : fieldValue
      case 'rentPsf':
        return isVacant ? NOT_AVAILABLE : formatCurrencyFloat(fieldValue || 0)
      case 'signedAt':
        if (isVacant) {
          return NOT_AVAILABLE
        } else {
          return !isNil(fieldValue)
            ? new BoweryDate(fieldValue).formatShortDateWithZeroes()
            : MONTH_DAY_YEAR_DATE_FORMAT
        }
      case 'expiresAt':
        if (isVacant) {
          return NOT_AVAILABLE
        } else if (isNil(fieldValue)) {
          return MONTH_DAY_YEAR_DATE_FORMAT
        } else {
          const date = new BoweryDate(fieldValue)
          return date.isValidDate() ? date.formatShortDateWithZeroes() : fieldValue
        }
      case 'terms':
        return fieldValue
      default:
        return isVacant ? NOT_AVAILABLE : fieldValue
    }
  }

  render() {
    const { compGroups, thereAreNoCommercialUnits, onMoveUnit, classes, onChangeBaseUnit, onChangeShowBaseUnitOnly } =
      this.props

    return (
      <div style={{ padding: 16 }}>
        {/* fix for horizontal scroll */}
        {this.getAddGroupDialog()}
        {this.getRenameGroupDialog()}
        {this.getRemoveGroupDialog()}

        <Grid container spacing={2} wrap="nowrap" direction="column">
          <Grid item>
            <Button
              onClick={this.onAddCompGroupClick}
              color="primary"
              variant="outlined"
              startIcon={<Add />}
              data-qa="add-comp-group-btn"
            >
              {Labels.ADD_COMP_GROUP}
            </Button>
          </Grid>
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={onMoveUnit}>
            {compGroups.map((compGroup, groupIndex) => (
              <Grid item key={compGroup._id} data-qa={`${compGroup.name}-comp-group`}>
                <Card>
                  <CardHeader
                    title={compGroup.name}
                    data-qa="header"
                    action={
                      compGroup._id !== UNSORTED_GROUP_ID && (
                        <React.Fragment>
                          <IconButton onClick={() => this.onCompGroupEditClick(compGroup)}>
                            <ModeEdit />
                          </IconButton>
                          <IconButton onClick={() => this.onCompGroupRemoveClick(compGroup)}>
                            <Delete />
                          </IconButton>
                        </React.Fragment>
                      )
                    }
                  />
                  <CardContent>
                    {compGroup._id !== UNSORTED_GROUP_ID && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={compGroup.showBaseUnitOnly}
                            onClick={event => onChangeShowBaseUnitOnly(compGroup._id, event.target.checked)}
                            size="small"
                          />
                        }
                        label="Show only Base Unit in adjustment grid"
                      />
                    )}
                    <DroppableTable
                      id={compGroup._id}
                      isDropDisabled={this.state.draggingId === compGroup._id}
                      emptyTableMessage={Labels.DROP_ANY_RENT_ROLL_UNIT_HERE}
                      isEmpty={!compGroup.units.length}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.dragCell}>{Labels.MOVE}</TableCell>
                          <TableCell>{Labels.TENANT_NAME}</TableCell>
                          <TableCell className={classes.dateCell}>{Labels.DATE_SIGNED}</TableCell>
                          <TableCell className={classes.dateCell}>{Labels.LEASE_EXPIRATION_DATE}</TableCell>
                          <TableCell className={classes.baseCell}>{this.rentPsfLabel}</TableCell>
                          <TableCell className={classes.baseCell}>{Labels.LEASE_TERMS}</TableCell>
                          {compGroup._id !== UNSORTED_GROUP_ID && (
                            <TableCell className={classes.baseCell}>{Labels.BASE_UNIT}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody data-qa="table-body">
                        {compGroup.units.map((unit, index) => {
                          const isBaseUnit = unit.isBaseUnit
                          return (
                            <DraggableTableRow key={unit._id} id={unit._id} index={index}>
                              <TableCell data-qa="tenantName-cell">
                                {this.formatValue(unit, 'tenantName', index)}
                              </TableCell>
                              <TableCell className={classes.dateCell} data-qa="leaseStartDate-cell">
                                {this.formatValue(unit, 'signedAt')}
                              </TableCell>
                              <TableCell className={classes.dateCell} data-qa="leaseExpiryDate-cell">
                                {this.formatValue(unit, 'expiresAt')}
                              </TableCell>
                              <TableCell className={classes.baseCell} data-qa="rent-cell">
                                {this.formatValue(unit, 'rentPsf')}
                              </TableCell>
                              <TableCell className={classes.baseCell} data-qa="leaseTerms-cell">
                                {this.formatValue(unit, 'terms')}
                              </TableCell>
                              {compGroup._id !== UNSORTED_GROUP_ID && (
                                <TableCell className={classes.baseCell} data-qa="isBaseUnit-cell">
                                  <Checkbox
                                    checked={isBaseUnit}
                                    disabled={isBaseUnit}
                                    onClick={() => {
                                      onChangeBaseUnit(compGroup._id, unit._id)
                                    }}
                                  />
                                </TableCell>
                              )}
                            </DraggableTableRow>
                          )
                        })}
                      </TableBody>
                    </DroppableTable>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </DragDropContext>
          {thereAreNoCommercialUnits && (
            <Grid item>
              <AlertInfo>
                <Typography type="body1">{Labels.THERE_ARE_NO_COMMERCIAL_UNITS}</Typography>
              </AlertInfo>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(CommercialCompGroups)
