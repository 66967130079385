import { withFeatureFlag } from '@bowery-valuation/feature-flagger-client'
import { SEARCH_SHORTCUTS } from 'client-shared/components/JumpAhead/constants'
import { CLEAR_SHORTCUTS } from 'client-shared/components/Notifications/constants'
import { OPEN_CONTENT_LIBRARY_SHORTCUTS } from 'client-shared/components/NarrativeComponent/constants'
import { ENABLE_GLEAN, FEATURE_FLAG_AI_APPRAISAL_ASSISTANT } from 'shared/constants/featureFlags'
import React from 'react'

import { compose } from 'recompose'

import {
  BACK_SHORTCUTS,
  FORWARD_SHORTCUTS,
  SAVE_CONTINUE_SHORTCUTS,
  SAVE_SHORTCUTS,
  OPEN_GLEAN_SHORTCUTS,
} from 'report/layout/constants'
import {
  BOOKMARKS_SHORTCUTS,
  GERI_SHORTCUTS,
  GOOGLE_MAPS_SHORTCUTS,
  MAP_GALLERY_SHORTCUTS,
  NOTES_SHORTCUTS,
  PHOTO_GALLERY_SHORTCUTS,
} from 'report/layout/drawer/constants'

function formatShortcutString(shortcut) {
  const sequences = shortcut.split(' ')
  return sequences
    .map(sequence => {
      const parts = sequence.split('+')
      return parts.join('-')
    })
    .join(' ')
}

function formatShortcuts(shortcuts) {
  return shortcuts.map(shortcut => formatShortcutString(shortcut)).join(' or ')
}

function KeyboardShortcuts({ enableGeri, enableGlean }) {
  return (
    <ul>
      <li>
        <strong>{formatShortcuts(CLEAR_SHORTCUTS)}</strong>: clear last notification
      </li>
      <li>
        <strong>{formatShortcuts(SEARCH_SHORTCUTS)}</strong>: jump to section (you are here)
      </li>
      <li>
        <strong>{formatShortcuts(BACK_SHORTCUTS)}</strong>: previous page
      </li>
      <li>
        <strong>{formatShortcuts(FORWARD_SHORTCUTS)}</strong>: next page
      </li>
      <li>
        <strong>{formatShortcuts(SAVE_SHORTCUTS)}</strong>: save
      </li>
      <li>
        <strong>{formatShortcuts(SAVE_CONTINUE_SHORTCUTS)}</strong>: save &amp; continue
      </li>
      <li>
        <strong>{formatShortcuts(GOOGLE_MAPS_SHORTCUTS)}</strong>: google maps
      </li>
      {enableGlean && (
        <li>
          <strong>{formatShortcuts(OPEN_GLEAN_SHORTCUTS)}</strong>: glean search
        </li>
      )}
      <li>
        <strong>{formatShortcuts(MAP_GALLERY_SHORTCUTS)}</strong>: map gallery
      </li>
      <li>
        <strong>{formatShortcuts(PHOTO_GALLERY_SHORTCUTS)}</strong>: photo gallery
      </li>
      <li>
        <strong>{formatShortcuts(BOOKMARKS_SHORTCUTS)}</strong>: bookmarked pages
      </li>
      <li>
        <strong>{formatShortcuts(NOTES_SHORTCUTS)}</strong>: notes
      </li>
      {enableGeri && (
        <li>
          <strong>{formatShortcuts(GERI_SHORTCUTS)}</strong>: geri
        </li>
      )}
      <li>
        <strong>{formatShortcuts(OPEN_CONTENT_LIBRARY_SHORTCUTS)}</strong>: content library
      </li>
    </ul>
  )
}

export default compose(
  withFeatureFlag(FEATURE_FLAG_AI_APPRAISAL_ASSISTANT, 'enableGeri'),
  withFeatureFlag(ENABLE_GLEAN, 'enableGlean')
)(KeyboardShortcuts)
