import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Tooltip, Grid, Paper } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { OnChange } from 'react-final-form-listeners'

import FileSelectionField from 'client-shared/components/FileSelection'
import { Text, DatePicker, CheckboxWithLabel } from 'client-shared/components'

const MAX_ENGAGEMENT_LETTER_SIZE_MB = 20

const Engagement = ({ authenticatedUser, form }) => {
  const { change, values } = form
  const { dateOfValuation, isDifferentDateOfValuation } = values

  const onDateOfValuationChange = React.useCallback(() => {
    if (!isDifferentDateOfValuation) {
      change('inspectionDate', dateOfValuation)
    }
  }, [change, dateOfValuation, isDifferentDateOfValuation])

  const removeLetterOfEngagement = React.useCallback(() => {
    change('letterOfEngagement', null)
  }, [change])

  return (
    <Paper>
      <Typography gutterBottom variant="h6">
        Engagement
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxWithLabel
            name="isDifferentDateOfValuation"
            label="My Date of Valuation (As Is) date is different from my Inspection Date."
          />
          <OnChange name="isDifferentDateOfValuation">{onDateOfValuationChange}</OnChange>
        </Grid>
        <Grid item xs={4}>
          <DatePicker name="dueDate" label="Due Date" margin="dense" />
        </Grid>
        <Grid item xs={4}>
          <DatePicker name="dateOfValuation" label="Date of Valuation (As Is)" margin="dense" />
          <OnChange name="dateOfValuation">{onDateOfValuationChange}</OnChange>
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            name="inspectionDate"
            label="Inspection Date"
            margin="dense"
            disabled={!isDifferentDateOfValuation}
          >
            <Typography variant="caption" display="block" gutterBottom>
              If the Inspection Date and the Date of Valuation are not the same, your Assumptions will be updated.
            </Typography>
          </DatePicker>
        </Grid>
        <Grid item xs={4}>
          <Text
            fullWidth
            data-qa="job-number-text-input"
            name="reportNumber"
            label="Bowery Job #"
            placeholder="##########"
            InputProps={{
              startAdornment: (
                <Typography variant="body1" sx={{ pr: '3px' }}>
                  JOB-
                </Typography>
              ),
              endAdornment: (
                <Tooltip placement="top-end" title="Your Bowery Job # can be found on Salesforce.">
                  <InfoIcon />
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Text
            fullWidth
            data-qa="deal-identifier-text-input"
            name="dealIdentifier"
            label="Deal Identifier"
            InputProps={{
              endAdornment: (
                <Tooltip placement="top-end" title="Your client should provide you with the Fannie Deal Identifier #.">
                  <InfoIcon />
                </Tooltip>
              ),
            }}
          />
          <Typography variant="caption" display="block" gutterBottom>
            Required for Fannie Mae reports.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FileSelectionField
            name="letterOfEngagement"
            label="Letter Of Engagement"
            fileType="letterOfEngagement"
            fileTypesRestriction={['pdf']}
            uploadEndpoint="pdfs/upload"
            cancelEndpoint="pdfs/cancel"
            parentId={authenticatedUser.organizationId}
            maxUploadSize={MAX_ENGAGEMENT_LETTER_SIZE_MB}
            onDelete={removeLetterOfEngagement}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {/* eslint-disable-next-line max-len */}
              {`File upload limit is ${MAX_ENGAGEMENT_LETTER_SIZE_MB} MB. To upload larger files, please optimize the PDF first.`}
            </Typography>
          </FileSelectionField>
        </Grid>
      </Grid>
    </Paper>
  )
}

Engagement.propTypes = {
  form: PropTypes.object.isRequired,
  authenticatedUser: PropTypes.object.isRequired,
}

export default Engagement
