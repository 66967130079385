import React, { useMemo } from 'react'
import ObjectID from 'bson-objectid'
import { useForm } from 'react-final-form'
import { noop } from 'lodash'
import { Link } from 'react-router-dom'
import LinkIcon from '@mui/icons-material/Launch'
import {
  ColumnAlignmentEnum,
  ColumnDataTypeEnum,
  CustomColumnConfig,
  Row,
  RowBasedTable,
} from '@bowery-valuation/ui-components'
import { Typography, Grid, Link as MuiLink, Button } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'

import { RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { NOT_AVAILABLE } from 'report/constants'
import { toPercents } from 'shared/utils/numberOperations'
import { Theme } from 'client-shared/components/_mui5/_theme'
import nationalSurveyResponsesDiscussion from 'shared/utils/textGeneration/final/capRateDiscussion/nationalSurveyResponsesDiscussion'

import { NarrativeComponent } from '../../../../../shared/components'

const styles = (theme: Theme) => ({
  title: {
    lineHeight: 1,
    marginBottom: 20,
  },
  tableTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 1.33,
    letterSpacing: 0.4,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: 1.33,
    letterSpacing: 0.4,
    marginBottom: 8,
  },
  firstCell: {
    width: 50,
  },
  icon: {
    width: 18,
    height: 18,
    fill: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      fill: theme.palette.primary.main,
    },
    marginLeft: 8,
    marginBottom: -4,
  },
})
const capRateColumns: RowBasedTableColumn[] = [
  {
    name: 'min',
    label: 'Min',
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: ColumnAlignmentEnum.left,
    editable: false,
  },
  {
    name: 'max',
    label: 'Max',
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: false,
  },
  {
    name: 'avg',
    label: 'Average',
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: false,
  },
]

const nationalInvestorSurveyColumns: RowBasedTableColumn[] = [
  {
    name: 'survey',
    label: 'Survey',
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: ColumnAlignmentEnum.left,
    editable: true,
    minWidth: 300,
  },
  {
    name: 'quarter',
    label: 'Quarter',
    type: ColumnDataTypeEnum.numeric,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: true,
  },
  {
    name: 'year',
    label: 'Year',
    type: ColumnDataTypeEnum.numeric,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: true,
  },
  {
    name: 'min',
    label: 'Min',
    type: ColumnDataTypeEnum.percent,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: true,
  },
  {
    name: 'avg',
    label: 'Average',
    type: ColumnDataTypeEnum.percent,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: true,
  },
  {
    name: 'max',
    label: 'Max',
    type: ColumnDataTypeEnum.percent,
    permanent: true,
    align: ColumnAlignmentEnum.right,
    editable: true,
  },
]

const nationalInvestorSurveyBaseRowProps = {
  readOnly: false,
  suppressMovable: true,
  permanent: false,
  type: ColumnDataTypeEnum.text,
}

type NationalInvestorSurveysProps = {
  classes: ClassNameMap<keyof ReturnType<typeof styles>>
  minCapRate: number
  maxCapRate: number
  averageCapRate: number
}

type nationalSurveyResponseRow = Row & {
  survey: string
  quarter: number
  year: number
  min: number
  avg: number
  max: number
}

const getNationalInvestorSurveyColumnConfig = (column: RowBasedTableColumn): CustomColumnConfig | undefined => {
  if (column.name === 'quarter') {
    return {
      editable: true,
      formatValueForDisplay: (value?: number) => {
        if (value == null) {
          return ''
        }

        return `${value}Q`
      },
    }
  }
}

const mapNationalInvestorSurveyRowData = (survey: Record<string, any>) => ({
  id: survey.id,
  survey: survey.survey,
  quarter: survey.quarter,
  year: survey.year,
  min: survey.min,
  max: survey.max,
  avg: survey.avg,
})

const NationalInvestorSurveys: React.FC<NationalInvestorSurveysProps> = ({
  classes,
  minCapRate,
  maxCapRate,
  averageCapRate,
}) => {
  const form = useForm()
  const nationalInvestorSurvey = form.getState().values.nationalSurveyResponses
  const formValues = form.getState().values

  const capRateRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'minMaxAvgRow',
      min: minCapRate ? `${toPercents(minCapRate)}%` : NOT_AVAILABLE,
      max: maxCapRate ? `${toPercents(maxCapRate)}%` : NOT_AVAILABLE,
      avg: averageCapRate ? `${toPercents(averageCapRate)}%` : NOT_AVAILABLE,
      rowDef: { hideAction: true },
    },
  ]

  const addSurveyRow = () => {
    const id = new ObjectID().toHexString()

    form.change(`nationalSurveyResponses.${id}`, { id, survey: 'National Survey Response' })
  }

  const deleteSurveyRow = (rowId: string) => {
    // Extra check to ensure PWS and RERC would not be deleted
    if (['pwc', 'rerc'].includes(rowId)) {
      return
    }

    const { nationalSurveyResponses } = form.getState().values
    const updatedNationalSurveyResponses = { ...nationalSurveyResponses }
    delete updatedNationalSurveyResponses[rowId]

    form.change('nationalSurveyResponses', updatedNationalSurveyResponses)
  }

  const nationalInvestorSurveyRows = useMemo(() => {
    const { pwc, rerc, discussion, ...rest } = nationalInvestorSurvey

    const rows = [
      {
        ...nationalInvestorSurveyBaseRowProps,
        ...mapNationalInvestorSurveyRowData(pwc),
        id: 'pwc',
        survey: pwc.survey === undefined ? 'PWC' : pwc.survey,
        rowDef: { hideAction: true },
      },
      {
        ...nationalInvestorSurveyBaseRowProps,
        ...mapNationalInvestorSurveyRowData(rerc),
        id: 'rerc',
        survey: rerc.survey === undefined ? 'RERC - Situs' : rerc.survey,
        rowDef: { hideAction: true },
      },
    ]

    Object.values(rest)
      .filter((data: any) => data?.id)
      .forEach(survey => {
        rows.push({
          ...nationalInvestorSurveyBaseRowProps,
          ...mapNationalInvestorSurveyRowData(survey as Record<string, any>),
          rowDef: { hideAction: false },
        })
      })

    return rows
  }, [nationalInvestorSurvey])

  const handleSurveyRowUpdate = (row: nationalSurveyResponseRow) => {
    const { id, survey, min, avg, max, quarter, year } = row
    form.change(`nationalSurveyResponses.${id}`, { id, survey, min, avg, max, quarter, year })
  }

  const TOOLTIP_TEXT = 'The following text will appear in the Income Capitalization Approach section of your report.'

  return (
    <div data-qa="national-investor-surveys">
      <Typography className={classes.title} variant="h6">
        National Investor Surveys
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.caption} variant="caption">
            Investor Risk Perception
          </Typography>
          <Typography className={classes.caption} gutterBottom variant="caption">
            Overall capitalization rates are influenced by numerous factors, of which the most influential are:
            investors' perception of risk, the potential for net income growth, and the market for competitive assets.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ paddingBottom: '8px' }}>
            Cap Rate Comps
            <Link to="cap-rate-comps">
              <LinkIcon className={classes.icon} />
            </Link>
          </Typography>
          <RowBasedTable
            id="cap-rate-table"
            columns={capRateColumns}
            rows={capRateRows}
            onRowUpdate={noop}
            onManyRowsUpdate={noop}
            actionCellHidden
            hideIndexColumn
            onColumnDragEnd={noop}
            onRowsDragEnd={noop}
            onColumnDelete={noop}
            onColumnUpdate={noop}
            onRowDelete={noop}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle1" sx={{ paddingBottom: '8px' }}>
                National Survey Responses
              </Typography>
            </Grid>
            <Grid item>
              <MuiLink
                href="https://www.dropbox.com/home/Research/Market%20Analysis/Surveys%20%26%20Broker%20Reports/Surveys"
                type="body1"
                color="primary.dark"
                target="_blank"
                rel="noreferrer"
              >
                View Cap Rate Investor Surveys
              </MuiLink>
            </Grid>
          </Grid>

          <Grid container justifyContent="end" style={{ marginBottom: '16px' }}>
            <Button size="small" variant="contained" onClick={addSurveyRow} sx={{ boxShadow: 1 }}>
              Add row +
            </Button>
          </Grid>

          <RowBasedTable
            id="national-investor-survey-table"
            columns={nationalInvestorSurveyColumns}
            rows={nationalInvestorSurveyRows}
            onRowUpdate={handleSurveyRowUpdate}
            getCustomColumnConfig={getNationalInvestorSurveyColumnConfig}
            onManyRowsUpdate={noop}
            hideIndexColumn
            onColumnDragEnd={noop}
            onRowsDragEnd={noop}
            onColumnDelete={noop}
            onColumnUpdate={noop}
            onRowDelete={deleteSurveyRow}
          />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent
            data={nationalSurveyResponsesDiscussion.mapDataFromFormValues(formValues)}
            generatedText={nationalSurveyResponsesDiscussion.generate}
            name="nationalSurveyResponses.discussion"
            title="National Survey Responses Discussion"
            tooltipText={TOOLTIP_TEXT}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(NationalInvestorSurveys)
