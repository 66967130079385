import React from 'react'
import PropTypes from 'prop-types'
import ReactMouseTrap from 'react-mousetrap'

import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Box, Button, Divider } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose, pure } from 'recompose'
import { withFeatureFlag } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_GLEAN } from 'shared/constants/featureFlags'

import FormHeader from '../../shared/components/FormHeader'
import { FOOTER_HEIGHT, HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../shared/layout/constants'

import BookmarkIndicator from '../components/BookmarkIndicator'

import { saveReport } from '../redux/actions/report'

import {
  BACK_SHORTCUTS,
  FORWARD_SHORTCUTS,
  SAVE_CONTINUE_SHORTCUTS,
  SAVE_SHORTCUTS,
  OPEN_GLEAN_SHORTCUTS,
} from './constants'
import Drawer from './drawer'
import { sectionNameSelector } from './selectors'

const styles = theme => {
  const section = {
    zIndex: 1001,
    position: 'sticky',
    backgroundColor: '#fafafa',
  }

  const FORM_HEADER_HEIGHT = 64

  return {
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      flex: 1,
    },
    header: {
      ...section,
      top: HEADER_HEIGHT,
      height: FORM_HEADER_HEIGHT,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 0 0 16px',
      boxShadow: '0 0.5px 0.4px 0 rgba(46, 65, 84, 0.20)',
    },
    headerTitle: {
      color: '#212121',
      lineHeight: 'normal',
    },
    headerIcon: {
      '& *': {
        fill: '#212121',
      },
    },
    openedDrawer: {
      marginRight: 560,
      transition: theme.transitions.create('margin-right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    closedDrawer: {
      marginRight: SIDEBAR_WIDTH,
      transition: theme.transitions.create('margin-right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    footer: {
      ...section,
      height: FOOTER_HEIGHT,
      bottom: 0,
      display: 'flex',
      padding: '0 16px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      boxShadow: '0 -0.5px 0.4px 0 rgba(46, 65, 84, 0.20)',
    },
  }
}

const SaveAndContinue = ({ disabled, returnPath }) => {
  return (
    <Button disabled={disabled} data-qa="form-submit-btn" variant="contained" type="submit" endIcon={<ChevronRight />}>
      Save & {returnPath ? 'Return' : 'Continue'}
    </Button>
  )
}

class FormLayout extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    prevFormPath: PropTypes.string,
    nextFormPath: PropTypes.string,
    children: PropTypes.node.isRequired,
    section: PropTypes.string,
    formName: PropTypes.string,
    formPath: PropTypes.string,
    saveReport: PropTypes.func.isRequired,
    returnPath: PropTypes.string,
    areFilesUploading: PropTypes.bool,
    isDrawerOpen: PropTypes.bool.isRequired,
    enableGlean: PropTypes.bool,
  }

  static defaultProps = {
    id: '',
    prevFormPath: null,
    nextFormPath: null,
    section: '',
    formName: '',
    enableGlean: false,
  }

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const { bindShortcut, enableGlean } = this.props
    bindShortcut(BACK_SHORTCUTS, this.onBackKeyPressed)
    bindShortcut(FORWARD_SHORTCUTS, this.onForwardKeyPressed)
    bindShortcut(SAVE_SHORTCUTS, this.onSaveKeyPressed)
    bindShortcut(SAVE_CONTINUE_SHORTCUTS, this.onSaveAndContinueKeyPressed)
    if (enableGlean) {
      bindShortcut(OPEN_GLEAN_SHORTCUTS, window.GleanWebSDK?.openSidebar)
    }
  }

  componentWillUnmount() {
    const { unbindShortcut, enableGlean } = this.props
    unbindShortcut(BACK_SHORTCUTS)
    unbindShortcut(FORWARD_SHORTCUTS)
    unbindShortcut(SAVE_SHORTCUTS)
    unbindShortcut(SAVE_CONTINUE_SHORTCUTS)
    if (enableGlean) {
      unbindShortcut(OPEN_GLEAN_SHORTCUTS)
    }
  }

  onBackKeyPressed = event => {
    const { prevFormPath } = this.props
    event.preventDefault()
    const { history } = this.context.router
    history.push(prevFormPath, { goBack: true })
  }

  onForwardKeyPressed = event => {
    const { returnPath, nextFormPath } = this.props
    event.preventDefault()
    const { history } = this.context.router
    const pathname = returnPath ? returnPath : nextFormPath
    history.push(pathname)
  }

  onSaveKeyPressed = event => {
    const { saveReport, areFilesUploading, id, section } = this.props
    event.preventDefault()
    if (id !== section && !areFilesUploading) {
      saveReport()
    }
  }

  onSaveAndContinueKeyPressed = event => {
    const { returnPath, nextFormPath, areFilesUploading, id, section } = this.props
    event.preventDefault()
    if (id !== section && !areFilesUploading) {
      const { history } = this.context.router
      const pathname = returnPath ? returnPath : nextFormPath
      history.push(pathname, { skipConfirmation: true })
    }
  }

  render() {
    const {
      classes,
      children,
      saveReport,
      formName,
      id,
      prevFormPath,
      nextFormPath,
      section,
      areFilesUploading,
      returnPath,
      isDrawerOpen,
    } = this.props
    const drawerDependentClassName = classNames({
      [classes.openedDrawer]: isDrawerOpen,
      [classes.closedDrawer]: !isDrawerOpen,
    })

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <FormHeader title={formName} section={section}>
            {id !== 'subject-property' && id !== 'job-details' && <BookmarkIndicator id={id} />}
          </FormHeader>
          <Divider />
          <div className={classes.closedDrawer}>{children}</div>
        </div>
        {id !== section && (
          <div className={classNames(classes.footer, drawerDependentClassName)}>
            <Box mr="auto">
              <Link to={{ pathname: prevFormPath, goBack: true }}>
                <Button startIcon={<ChevronLeft />} variant="text" type="submit">
                  Back
                </Button>
              </Link>
            </Box>
            <Box mx={2}>
              <Button
                disabled={areFilesUploading}
                data-qa="form-save-btn"
                onClick={saveReport}
                type="submit"
                variant="outlined"
                sx={{ boxShadow: 1 }}
              >
                Save
              </Button>
            </Box>
            {areFilesUploading ? (
              <SaveAndContinue disabled={true} />
            ) : (
              <Link to={{ pathname: returnPath ? returnPath : nextFormPath, state: { skipConfirmation: true } }}>
                <SaveAndContinue disabled={false} returnPath={returnPath} />
              </Link>
            )}
          </div>
        )}
        <Drawer />
      </div>
    )
  }
}

export default compose(
  pure,
  ReactMouseTrap,
  connect(
    state => {
      const { formName, formPath, prevFormPath, id, nextFormPath, section } = get(state, 'shared.location.form', {})
      const returnPath = get(state, 'router.location.state.returnPath', undefined)
      const { isDrawerOpen } = sectionNameSelector(state)

      return {
        formName,
        formPath,
        prevFormPath,
        nextFormPath,
        section,
        id,
        returnPath,
        areFilesUploading: get(state, 'shared.areFilesUploading'),
        isDrawerOpen,
      }
    },
    { saveReport },
    (props, dispatchProps, ownProps) => ({
      ...props,
      ...ownProps,
      saveReport: () => dispatchProps.saveReport(props.formPath),
    })
  ),
  withFeatureFlag(ENABLE_GLEAN, 'enableGlean'),
  withStyles(styles)
)(FormLayout)
