import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_AUTO_SALES_VALUE_CALCULATION } from 'shared/constants/featureFlags'

import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'
import { mapValuesToDisplay } from 'shared/report-calculations/income-approach/acas/helpers'
import { UNIT_OF_COMPARISON_TYPES, SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'
import salesValueConclusionDiscussion from 'shared/utils/textGeneration/salesApproach/salesValueConclusionDiscussion'

import wrapForm from 'report/forms/wrapForm'
import { NarrativeComponent } from 'client-shared/components'
import { formatCurrencyFloat, formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import salesApproachCalculations from 'shared/report-calculations/sales-approach/sales-approach-calculations'
import { getSalesApproachFormPath } from 'shared/helpers/salesApproach/salesApproachHelpers'
import { SALE_VALUE_CONCLUSION_KEY } from 'shared/constants/report/keysAndDataPaths'
import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'

import {
  asStabilizedDiscountRateDecorator,
  asCompleteDiscountRateDecorator,
} from 'report/forms/income/CapRateConclusion/decorators'
import AutomationCTA from 'client-shared/components/AutomationCTA'

import AdjustmentsAndLossesTable, {
  adjustmentsAndLossesConstants,
  adjustmentsAndLossesDecorators,
  LandAdjustmentsTable,
} from 'client-shared/components/AdjustmentsAndLosses'

import { saveReport } from '../../../redux/actions/report'

import SalesCompsTableSummaryContainer from '../SalesCompsSummary/SalesCompsTableSummaryContainer'
import { autoCalculateSaleValueConclusion, caluclateAdjustedCompMedian } from '../calculations'
import { DATA_PATH as LEGACY_BASE_DATA_PATH } from '..'

import { acasDecorator } from './decorators'

const SALES_VALUE_CONCLUSION_TABLE_TITLE = 'Sales Value Conclusion Table'

class SaleValueConclusionContainer extends React.PureComponent {
  getChildContext() {
    const { formPath } = this.props
    return {
      formName: formPath,
    }
  }

  renderAdjustmentsAndLossesTable = () => {
    const {
      form,
      valueAsComplete,
      valueAsStabilized,
      valueConclusionType,
      netOperatingIncome,
      commercialUnits,
      residentialUnits,
      propertyType,
      residentialVCLossPercentage,
      commercialVCLossPercentageAggregate,
      salesApproachType,
      hasIncomeApproach,
    } = this.props
    const { change, batch } = form

    return salesApproachType === SALES_APPROACH_TYPES.LAND ? (
      <LandAdjustmentsTable
        change={change}
        values={form.values}
        dataSourceType={adjustmentsAndLossesConstants.ADJUSTMENT_AND_LOSSES_DATA_SOURCE.SALES_APPROACH}
        title={SALES_VALUE_CONCLUSION_TABLE_TITLE}
        hasIncomeApproach={hasIncomeApproach}
      />
    ) : (
      <AdjustmentsAndLossesTable
        change={change}
        values={form.values}
        batch={batch}
        netOperatingIncome={netOperatingIncome}
        valueAsComplete={valueAsComplete}
        valueAsStabilized={valueAsStabilized}
        residentialUnits={residentialUnits}
        commercialUnits={commercialUnits}
        propertyType={propertyType}
        residentialVCLossPercentage={residentialVCLossPercentage}
        commercialVCLossPercentageAggregate={commercialVCLossPercentageAggregate}
        valueConclusionType={valueConclusionType}
        dataSourceType={adjustmentsAndLossesConstants.ADJUSTMENT_AND_LOSSES_DATA_SOURCE.SALES_APPROACH}
        title={SALES_VALUE_CONCLUSION_TABLE_TITLE}
        hasIncomeApproach={hasIncomeApproach}
      />
    )
  }

  getDisabledMessage = () => {
    const { incomeApproachValue, salesComps, unitOfComparison } = this.props
    const missingFields = []
    if (!incomeApproachValue.unit) {
      missingFields.push('income approach value')
    }
    if (!salesComps.length) {
      missingFields.push('sales comps')
    }
    if (unitOfComparison !== UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS) {
      missingFields.push('basis of comparison to "per residential units"')
    }
    const missingFieldsText = missingFields.length ? missingFields.join(', ') : ''
    return `We're not able to run the automation. Please update ${missingFieldsText}.`
  }

  calculateValueConclusion = () => {
    const { incomeApproachValue, salesComps, netOperatingIncome, temper, unitOfComparison, form } = this.props
    const { values } = form
    const { incomeAdjustmentLevel, compAdjustments } = values

    const adjustedCompMedian = caluclateAdjustedCompMedian(
      salesComps,
      unitOfComparison,
      temper,
      netOperatingIncome,
      incomeAdjustmentLevel,
      compAdjustments
    )
    const incomeApproachValuePerUnit = incomeApproachValue.unit
    const saleValueConclusion = autoCalculateSaleValueConclusion(adjustedCompMedian, incomeApproachValuePerUnit)

    form.change('saleValueConclusion', saleValueConclusion)

    global.automationSaved = 'salesValueConclusionImport'
    this.props.saveReport(this.props.formPath)
  }

  render() {
    const {
      form,
      unitOfComparison,
      incomeApproachValue,
      temper,
      inspectionDate,
      netOperatingIncome,
      salesComps,
      salesApproachType,
      npvAdjustments,
      asCompleteNpvAdjustments,
      asStabilizedNpvAdjustments,
    } = this.props
    const { values } = form
    const { incomeAdjustmentLevel, compAdjustments } = values

    const autoSalesApproachValue = getFeatureFlagValue(ENABLE_AUTO_SALES_VALUE_CALCULATION)
    const disableAutomation =
      !incomeApproachValue.unit || !salesComps.length || unitOfComparison !== UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS
    return (
      <Grid container spacing={2}>
        {autoSalesApproachValue && (
          <Grid item xs={12}>
            <AutomationCTA
              CTAMessage="Automate the Value Conclusion."
              successMessage={DEFAULT_ALERT_MESSAGES.SUCCESS}
              errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
              onAutomationRun={this.calculateValueConclusion}
              disabledMessage={this.getDisabledMessage()}
              disableCTA={disableAutomation}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper>
            <SalesCompsTableSummaryContainer
              incomeAdjustmentLevel={incomeAdjustmentLevel}
              unitOfComparison={unitOfComparison}
              temper={temper}
              netOperatingIncome={netOperatingIncome}
              inspectionDate={inspectionDate}
              incomeApproachValue={incomeApproachValue}
              salesComps={salesComps}
              showAdjustments={false}
              showAdjustedValues={false}
              showSummary={true}
              compAdjustments={compAdjustments}
              salesApproachType={salesApproachType}
            />
          </Paper>
        </Grid>
        <Grid item lg={4} md={7} sm={10} xs={12}>
          <Paper>
            <Table
              size="small"
              sx={{
                '& .MuiTableCell-root': {
                  padding: '13px',
                },
              }}
            >
              <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
                <TableRow>
                  <TableCell>Income Approach Conclusion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ '& .MuiTableCell-root': { fontSize: 13 } }}>
                <TableRow>
                  <TableCell data-qa="income-approach-conclusion">
                    {unitOfComparison === UNIT_OF_COMPARISON_TYPES.SF
                      ? `${formatCurrencyFloat(incomeApproachValue.psf)} per square foot`
                      : `${formatCurrencyInt(incomeApproachValue.unit)} per unit`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {this.renderAdjustmentsAndLossesTable()}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <NarrativeComponent
              title="Sales Value Conclusion Discussion"
              name="valueConclusionInformation.discussion"
              generatedText={salesValueConclusionDiscussion.generate}
              data={salesValueConclusionDiscussion.mapDTO({
                ...form.values,
                unitOfComparison,
                npvAdjustments,
                asCompleteNpvAdjustments,
                asStabilizedNpvAdjustments,
                temper,
                netOperatingIncome,
                salesComps,
              })}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

SaleValueConclusionContainer.propTypes = {
  capRateRoundingFactor: PropTypes.number,
  grossBuildingArea: PropTypes.number,
  incomeApproachValue: PropTypes.object,
  inspectionDate: PropTypes.string,
  netOperatingIncome: PropTypes.object,
  npvAdjustmentTotal: PropTypes.number,
  propertyType: PropTypes.string.isRequired,
  unitOfComparison: PropTypes.string,
  salesComps: PropTypes.array,
  temper: PropTypes.number,
  totalUnits: PropTypes.number,
  valueAsComplete: PropTypes.bool.isRequired,
  valueAsStabilized: PropTypes.bool.isRequired,
  salesApproachType: PropTypes.string,
}

SaleValueConclusionContainer.childContextTypes = {
  formName: PropTypes.string.isRequired,
}

const salesApproachOperands = (state, DATA_PATH) => {
  const salesApproachPath = getSalesApproachFormPath(DATA_PATH)
  const formDataPath = DATA_PATH.join('.')
  const formData = get(state, `report.reportData.${formDataPath}`, {})
  const {
    temper,
    salesComps,
    inspectionDate,
    dateOfValuation,
    squareFootage,
    totalUnits,
    npvAdjustmentTotal,
    incomeApproachValue,
    valueConclusionInformation,
    unitOfComparison,
    netOperatingIncome,
    subjectPropertyInformation,
    compAdjustments,
    landValue,
    asIsLandValue,
    type,
  } = formData || {}

  const reportData = get(state, 'report.reportData', {})
  const salesApproach = get(reportData, salesApproachPath, {})
  const incomeApproachData = get(reportData, 'incomeApproach', {})

  const salesAdjustmentGridData = get(salesApproach, 'salesAdjustmentGrid', {})
  const capRateConclusionData = get(incomeApproachData, 'capRateConclusion', {})

  const basisOfComparisonUnitTypes = get(salesAdjustmentGridData, 'basisOfComparisonUnitTypes', {})
  const incomeAdjustmentLevel = get(salesAdjustmentGridData, 'incomeAdjustmentLevel')

  const roundingFactor =
    get(salesApproach, 'saleValueConclusion.capRateRoundingFactor') ??
    get(capRateConclusionData, 'roundingFactor', 50000)

  const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
  const valueConclusionType = get(state, 'report.reportSettings.valueConclusionType')

  const {
    conclusionValue,
    matchIncomeApproachDeductions,
    asIsValueInformation,
    asStabilizedValueInformation,
    asCompleteValueInformation,
  } = valueConclusionInformation

  const propertyInformation = subjectPropertyInformation

  const capitalizationConclusion = salesApproachCalculations.calculateSalePriceFromBasis(
    conclusionValue,
    unitOfComparison,
    propertyInformation
  )

  return {
    ...formData,
    temper,
    salesComps,
    inspectionDate,
    dateOfValuation,
    unitOfComparison,
    asCompleteMonthsOfRentLoss: get(asCompleteValueInformation, 'monthsOfRentLoss'),
    asStabilizedMonthsOfRentLoss: get(asStabilizedValueInformation, 'monthsOfRentLoss'),
    npvAdjustmentTotal,
    npvAdjustments: get(asIsValueInformation, 'npvAdjustments', []),
    landDeductions: get(asIsValueInformation, 'landDeductions', []),
    landValue,
    asIsLandValue,
    asCompleteNpvAdjustments: get(asCompleteValueInformation, 'npvAdjustments', []),
    asStabilizedNpvAdjustments: get(asStabilizedValueInformation, 'npvAdjustments', []),
    capRateRoundingFactor: roundingFactor,
    roundingFactor,
    asStabilizedResRentLossItems: get(asStabilizedValueInformation, 'residentialRentLossItems', []),
    asCompleteResRentLossItems: get(asCompleteValueInformation, 'residentialRentLossItems', []),
    asStabilizedLossItems: get(asStabilizedValueInformation, 'lossItems', []),
    asCompleteLossItems: get(asCompleteValueInformation, 'lossItems', []),
    asStabilizedCommercialRentLossItems: get(asStabilizedValueInformation, 'commercialRentLossItems', []),
    asCompleteCommercialRentLossItems: get(asCompleteValueInformation, 'commercialRentLossItems', []),
    basisOfComparisonUnitTypes,
    capitalizationConclusion,
    squareFootage,
    totalUnits,
    incomeApproachValue,
    saleValueConclusion: conclusionValue,
    valueConclusionInformation,
    valueAsComplete,
    valueAsStabilized,
    netOperatingIncome,
    incomeAdjustmentLevel,
    matchIncomeApproachDeductions,
    incomeApproachData,
    valueConclusionType,
    subjectPropertyInformation,
    compAdjustments,
    type,
  }
}

const formOptions = {
  heading: 'Value Conclusion',
  mutators: {},
  decorators: [
    acasDecorator,
    adjustmentsAndLossesDecorators.adjustmentsAndLossesCalculationDecorator,
    asStabilizedDiscountRateDecorator,
    asCompleteDiscountRateDecorator,
  ],
  keepDirtyOnReinitialize: true,
}

const mapStateToProps = (state, DATA_PATH, salesApproachType) => {
  const operands = salesApproachOperands(state, DATA_PATH)
  const formPath = DATA_PATH.join('.')
  const {
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
    npvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedNpvAdjustments,
    roundingFactor,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    capitalizationConclusion,
    squareFootage,
    totalUnits,
    valueAsComplete,
    valueAsStabilized,
    incomeApproachData,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    basisOfComparisonUnitTypes,
    asStabilizedDiscountRate,
    asCompleteDiscountRate,
  } = operands

  const acasDollarAmounts = ACASCalculations.calculateFinalValues({
    indicatedValue: capitalizationConclusion,
    npvAdjustments,
    asStabilizedNpvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    roundingFactor,
    squareFootage,
    unitCount: totalUnits,
    includeValueAsComplete: valueAsComplete,
    includeValueAsStabilized: valueAsStabilized,
    asStabilizedDiscountRate,
    asCompleteDiscountRate,
  })
  const acasDisplayDollarAmounts = mapValuesToDisplay(acasDollarAmounts)

  const acasDates = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  })

  const initialFormValues = {
    ...operands,
    ...acasDisplayDollarAmounts,
    ...acasDates,
    totalUnits,
    adjustmentTypeToAdd: adjustmentsAndLossesConstants.ADJUSTMENT_TYPES.NPV,
    residentialUnits: incomeApproachData.residentialIncome.residentialProjectedRentRoll.units || [],
    commercialUnits: incomeApproachData.commercialIncome.commercialProjectedRentRoll.units || [],
    residentialVCLossPercentage: incomeApproachData.potentialGrossIncome.residentialVCLossPercentage || 0,
    commercialVCLossPercentageAggregate:
      incomeApproachData.potentialGrossIncome.commercialVCLossPercentageAggregate || 0,
    basisOfComparisonUnitTypes,
    type: salesApproachType,
  }

  const propertyType = get(state, 'report.reportData.propertyType')

  return {
    propertyType,
    ...initialFormValues,
    initialValues: initialFormValues,
    salesApproachType,
    formPath,
    hasIncomeApproach: get(state, 'report.reportSettings.approaches.income', true),
  }
}

const mapDispatchToProps = dispatch => ({
  saveReport: formPath => dispatch(saveReport(formPath)),
})

export const createSalesValueConclusion = (baseDataPath, salesApproachType = SALES_APPROACH_TYPES.IMPROVED) => {
  const DATA_PATH = [...baseDataPath, SALE_VALUE_CONCLUSION_KEY]
  return wrapForm(
    DATA_PATH,
    formOptions,
    state => mapStateToProps(state, DATA_PATH, salesApproachType),
    mapDispatchToProps,
    false,
    baseDataPath
  )(SaleValueConclusionContainer)
}

export default createSalesValueConclusion(LEGACY_BASE_DATA_PATH)
