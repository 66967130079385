import createDecorator from 'final-form-calculate'

import * as formCalculations from './formCalculations'

export const adjustmentsAndLossesCalculationDecorator = createDecorator(
  {
    field: [
      /asStabilizedLossItems\[\d]\.months/,
      /asStabilizedResRentLossItems\[\d+]\.months/,
      'asStabilizedMonthsOfRentLoss',
      /asStabilizedCommercialRentLossItems\[\d+].months/,
    ],
    updates: formCalculations.recalculateValueForAsStabilizedLossItems,
  },
  {
    field: [
      /asCompleteLossItems\[\d]\.months/,
      /asStabilizedResRentLossItems\[\d+]\.months/,
      /asCompleteResRentLossItems\[\d+]\.months/,
      'asCompleteMonthsOfRentLoss',
      'asStabilizedMonthsOfRentLoss',
      /asStabilizedCommercialRentLossItems\[\d+].months/,
      /asCompleteCommercialRentLossItems\[\d+].months/,
    ],
    updates: formCalculations.recalculateValueForAsCompleteLossItems,
  },
  {
    field: [
      /asCompleteLossItems\[\d+]\.amount/,
      /asStabilizedLossItems\[\d+]\.amount/,
      /npvAdjustments\[\d+]\.value/,
      /landDeductions\[\d+]\.value/,
      /asCompleteNpvAdjustments\[\d+]\.value/,
      /asStabilizedNpvAdjustments\[\d+]\.value/,
      /asStabilizedResRentLossItems\[\d+].amount/,
      /asCompleteResRentLossItems\[\d+].amount/,
      'asCompleteMonthsOfRentLoss',
      'asStabilizedMonthsOfRentLoss',
      /asStabilizedCommercialRentLossItems\[\d+].amount/,
      /asCompleteCommercialRentLossItems\[\d+].amount/,
      'saleValueConclusion',
      /asCompleteDiscountPercentage/,
      /asStabilizedDiscountPercentage/,
      /asCompleteMonthsDiscounted/,
      /asStabilizedMonthsDiscounted/,
      /roundingFactor/,
    ],
    updates: formCalculations.recalculateFinalValuesAndDates,
    isEqual: (value, previousValue) => {
      return value === previousValue || (Number.isNaN(value) && Number.isNaN(previousValue))
    },
  }
)
