import React from 'react'

import { get } from 'lodash'
import { Paper, Tab } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'

import { CAP_RATE_DISCUSSION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import { normalizePropertyCondition } from '../../../../../../shared/utils/report/propertyInformation/description'

import NationalInvestorSurveys from './NationalInvestorSurveys'
import IncomeSpikes from './IncomeSpikes'
import CapRateComps from './CapRateComps'

export const DATA_PATH = CAP_RATE_DISCUSSION_PATH

const TABS = [
  {
    label: 'National Investor Surveys',
    value: 'NATIONAL_INVESTOR_SURVEYS',
  },
  {
    label: 'Cap Rate Comps',
    value: 'CAP_RATE_COMPS',
  },
  {
    label: 'Income Spikes',
    value: 'INCOME_SPIKES',
  },
]

class CapRateDiscussion extends React.Component {
  state = {
    tab: 'NATIONAL_INVESTOR_SURVEYS',
  }

  handleTabChange = (event, tab) => {
    this.setState({ tab })
  }

  get currentTab() {
    const { form } = this.props
    const { tab } = this.state
    const {
      capRateCompsMin,
      capRateCompsMax,
      capRateCompsAverage,
      comparableIncomePotential,
      comparablePropertyConditions,
      comparablePropertyLocations,
      propertyCondition,
      asStabilizedPropertyCondition,
      valueConclusionType,
      concludedCapRate,
      occupancyLevel,
      percentageOfMarketRent,
      specificPropertyTypes,
      rentControlledTenants,
      unusedAirRights,
      grossBuildingAreaPerFloor,
      actualPerTransitionalAssessmentGap,
    } = form.values

    switch (tab) {
      case 'CAP_RATE_COMPS':
        return (
          <CapRateComps
            comparableIncomePotential={comparableIncomePotential}
            comparablePropertyConditions={comparablePropertyConditions}
            comparablePropertyLocations={comparablePropertyLocations}
          />
        )
      case 'INCOME_SPIKES':
        return (
          <IncomeSpikes
            propertyCondition={normalizePropertyCondition(propertyCondition)}
            asStabilizedPropertyCondition={normalizePropertyCondition(asStabilizedPropertyCondition)}
            valueConclusionType={valueConclusionType}
            concludedCapRate={concludedCapRate}
            occupancyLevel={occupancyLevel}
            percentageOfMarketRent={percentageOfMarketRent}
            specificPropertyTypes={specificPropertyTypes}
            rentControlledTenants={rentControlledTenants}
            unusedAirRights={unusedAirRights}
            grossBuildingAreaPerFloor={grossBuildingAreaPerFloor}
            actualPerTransitionalAssessmentGap={actualPerTransitionalAssessmentGap}
            formValues={form.values}
          />
        )
      default:
        return (
          <NationalInvestorSurveys
            minCapRate={capRateCompsMin}
            maxCapRate={capRateCompsMax}
            averageCapRate={capRateCompsAverage}
          />
        )
    }
  }

  render() {
    const { tab } = this.state
    return (
      <div>
        <TabContext value={tab}>
          <TabList onChange={this.handleTabChange}>
            {TABS.map(({ label, value }, index) => (
              <Tab data-qa={`${label}-tab`} key={value} value={value} label={label} />
            ))}
          </TabList>
        </TabContext>
        <Paper sx={{ maxWidth: 900 }}>{this.currentTab}</Paper>
      </div>
    )
  }
}

export default wrapForm(
  DATA_PATH,
  {
    registeredFields: [
      'subjectIncomePotential',
      'currentMarketConditions',
      'cashFlowRiskProfile',
      'discussFeasibleConversion',
      'discussAssessmentGap',
      'discussAirRights',
      'nationalSurveyResponses',
      'typicalIncomeSpikesDiscussion',
      'additionalIncomeSpikesDiscussion',
      'capRateCompsDiscussion',
    ],
    heading: 'Cap Rate Discussion',
    onPreSave: formValues => {
      const { pwc, rerc, additionalSurveys, discussion, ...rest } = get(formValues, 'nationalSurveyResponses', {})

      const updatedAdditionalSurveys = Object.values(rest).filter(data => data?.id)
      const updatedNationalSurveyResponses = { pwc, rerc, additionalSurveys: updatedAdditionalSurveys, discussion }

      return { ...formValues, nationalSurveyResponses: updatedNationalSurveyResponses }
    },
  },
  state => {
    const formData = get(state, `report.reportData.${DATA_PATH.join('.')}`, {})
    const nationalSurveyResponses = get(formData, `nationalSurveyResponses`, {})
    const additionalSurveys = get(formData, `nationalSurveyResponses.additionalSurveys`, [])

    additionalSurveys.forEach(survey => {
      if (survey.id) {
        nationalSurveyResponses[survey.id] = survey
      }
    })

    return {
      initialValues: {
        ...formData,
        nationalSurveyResponses,
      },
    }
  }
)(CapRateDiscussion)
