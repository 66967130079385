import React from 'react'

import { Button } from '@mui/material'
import { noop } from 'lodash'

class GleanButton extends React.PureComponent {
  static defaultProps = {
    onClick: noop,
  }
  render() {
    const { onClick } = this.props
    return (
      <Button onClick={onClick} style={{ height: '12px', width: '12px' }}>
        Use AI
      </Button>
    )
  }
}

export default GleanButton
