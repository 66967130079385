export const MAP_SIZE = { height: '400px', width: '640px' }

export const HERE_HYBRID_BASEMAP_CONFIG = {
  baseMap: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?size=256&style=explore.satellite.day',
  subject: {
    maxZoom: 18,
    minZoom: 12,
    initialZoom: 17,
  },
}
