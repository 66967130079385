import React from 'react'

import { eq, get, noop } from 'lodash'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  TableRow,
  Tooltip,
} from '@mui/material'

import OpenInNew from '@mui/icons-material/OpenInNew'

import {
  TableSelection,
  TableHeaderRow,
  Table,
  PagingPanel,
  Grid as DxGrid,
} from '@devexpress/dx-react-grid-material-ui'
import {
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid'

import InputRangeField from 'client-shared/components/Range/InputRanges'
import Loading from 'client-shared/components/Loading'
import { MultipleDropDownComponent } from 'client-shared/components/DropDown/MultipleDropDownField'
import { SORT_DIRECTIONS } from 'report/constants'
import { formatInt, formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import { getPerBasisLabel, getBasisValue } from 'shared/report-calculations/income-approach/tax/helpers'

import { COLUMNS, NYC_BUILDING_CLASSES_LIST, TAX_COMPS_TABLE_COLUMNS, TAX_PROPS, TAX_PROPS_LABELS } from './constants'

class AdvancedSearchModal extends React.PureComponent {
  state = {
    yearRange: {
      min: '',
      max: '',
    },
    taxYearRange: {
      min: '',
      max: '',
    },
    sqftRange: {
      min: '',
      max: '',
    },
    taxRateRange: {
      min: '',
      max: '',
    },
    ...TAX_PROPS,
    excludeZeroValueComps: true,
    tableFilters: {
      sortField: 'address',
      sortDirection: SORT_DIRECTIONS.DESC,
      currentPage: 0,
      pageSize: 5,
    },
    selectedTaxComps: [],
    buildingClass: [],
  }

  setSelected = selectedTaxComps => {
    this.setState({
      selectedTaxComps,
    })
  }

  onCheckboxChange = name => (_event, checked) => {
    this.setState({ [name]: checked })
  }

  onChange = event => {
    const rangeParts = event.target.name.split('.')
    if (rangeParts.length > 1) {
      const rangeName = rangeParts[0]
      const propName = rangeParts[1]
      this.setState({
        [rangeName]: { ...this.state[rangeName], [propName]: event.target.value },
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      })
    }
  }

  searchForSalesComps = () => {
    const { searchTaxComps, borough, basis } = this.props

    const {
      taxClassOne,
      taxClassTwo,
      taxClassTwoAB,
      taxClassFour,
      taxClassOther,
      yearRange,
      sqftRange,
      taxRateRange,
      excludeZeroValueComps,
      buildingClass,
    } = this.state

    const maxTaxPerBasis = taxRateRange.max
    const minTaxPerBasis = taxRateRange.min

    const data = {
      borough,
      basis,

      maxSize: sqftRange.max,
      minSize: sqftRange.min,
      maxYear: yearRange.max,
      minYear: yearRange.min,

      taxClassOne,
      taxClassTwo,
      taxClassTwoAB,
      taxClassFour,
      taxClassOther,

      maxTaxPerBasis,
      minTaxPerBasis,
      excludeZeroValueComps,

      buildingClass,
    }

    searchTaxComps(data)
  }

  addToTaxComps = () => {
    const { taxCompsSearchResults, onAdd, basis } = this.props
    const { selectedTaxComps } = this.state
    const taxComps = get(taxCompsSearchResults, 'comps', [])
    const compsToAdd = []

    selectedTaxComps.forEach(index => {
      compsToAdd.push(taxComps[index])
    })

    const taxCompsToAdd = []
    for (const comp of compsToAdd) {
      const squareFeet = get(comp, 'BLDGAREA', 0)
      const residentialUnitCount = get(comp, 'unitsRes', 0)
      const basisValue = getBasisValue(basis, squareFeet, residentialUnitCount)
      taxCompsToAdd.push({
        address: get(comp, 'STADDR', null),
        city: get(comp, 'city', null),
        state: get(comp, 'state', null),
        postalCode: get(comp, 'ZIP', null),
        basis: basisValue,
        residentialUnitCount,
        squareFeet,
        taxYear: get(comp, 'TAXYEAR', 0),
        taxesPerBasis: get(comp, 'taxesPerBasis', 0),
        yearBuilt: get(comp, 'YEARBUILT', 0),
      })
    }
    onAdd(taxCompsToAdd)
    this.setState({
      selectedTaxComps: [],
    })
  }

  onSortFieldChange = ([{ columnName, direction }]) => {
    this.setState({
      tableFilters: {
        ...this.state.tableFilters,
        sortField: columnName,
        sortDirection: direction,
      },
    })
  }

  onBuildingClassChange = values => {
    this.setState({
      buildingClass: values,
    })
  }

  tableRowTemplate = props => {
    return <TableRow data-qa={`row-${props.tableRow.rowId}`}>{props.children}</TableRow>
  }

  tableCellTemplate = props => {
    const value = get(props, `row.${props.column.name}`)
    if (props.column.name === 'BLDGAREA') {
      return <Table.Cell>{formatInt(value)}</Table.Cell>
    } else {
      return <Table.Cell>{value}</Table.Cell>
    }
  }

  TableHeaderContentBase = ({ column, children, ...restProps }) => {
    if (column.name === 'BUILDINGCLASS') {
      return (
        <TableHeaderRow.Content column={column} {...restProps}>
          {children}
          <a
            href="https://www1.nyc.gov/assets/finance/jump/hlpbldgcode.html#A"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip title="Open Building Class Description in New Tab" placement="right">
              <IconButton onClick={noop}>
                <OpenInNew />
              </IconButton>
            </Tooltip>
          </a>
        </TableHeaderRow.Content>
      )
    } else {
      return (
        <TableHeaderRow.Content column={column} {...restProps}>
          {children}
        </TableHeaderRow.Content>
      )
    }
  }

  render() {
    const {
      taxLiability,
      grossBuildingArea,
      yearBuilt,
      taxCompsSearchResults,
      taxClass,
      basis,
      residentialUnitCount,
      onClose,
    } = this.props
    const { yearRange, sqftRange, taxRateRange, selectedTaxComps, tableFilters, buildingClass } = this.state
    const disableSearch =
      eq(yearRange.min, '') ||
      eq(yearRange.max, '') ||
      eq(sqftRange.min, '') ||
      eq(sqftRange.max, '') ||
      eq(taxRateRange.min, '') ||
      eq(taxRateRange.max, '')

    const taxComps = get(taxCompsSearchResults, 'comps', [])
    const isLoading = get(taxCompsSearchResults, 'isLoading', false)
    const subjectTaxesPerBasis = TaxCalculations.calculateTaxesPerBasis(
      basis,
      taxLiability,
      grossBuildingArea,
      residentialUnitCount
    )
    const basisLabel = getPerBasisLabel(basis)
    return (
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        data-qa="advanced-search-modal"
        disableBackdropClick
        maxWidth="lg"
        onClose={() => onClose()}
        open
      >
        <DialogTitle id="alert-dialog-title">TAX COMPS ADVANCED SEARCH</DialogTitle>
        {isLoading ? (
          <Loading />
        ) : (
          <Box data-qa="advanced-search-modal-content">
            <DialogContent data-qa="advanced-search-modal-content">
              <Stack spacing={2} sx={{ width: 1130 }}>
                <FormControl>
                  <FormLabel component="legend">Year Built Range</FormLabel>
                  <InputRangeField
                    betweenLabel="to"
                    handleChange={this.onChange}
                    maxLabel="Latest Year Built"
                    maxPlaceholder={yearRange.max}
                    minLabel="Earliest Year Built"
                    minPlaceholder={yearRange.min}
                    name="yearRange"
                  />
                  <FormHelperText data-qa="subject-taxes">{`Subject built in ${yearBuilt}`}</FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel component="legend">Square Feet Range</FormLabel>
                  <InputRangeField
                    betweenLabel="to"
                    handleChange={this.onChange}
                    maxLabel="Square Feet Max"
                    maxPlaceholder={sqftRange.max}
                    minLabel="Square Feet Min"
                    minPlaceholder={sqftRange.min}
                    name="sqftRange"
                    data-qa="sqft-range"
                  />
                  <FormHelperText>{`Subject square footage: ${grossBuildingArea} square feet`}</FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel component="legend">Tax Rate Range</FormLabel>
                  <InputRangeField
                    adornment="$"
                    adornmentPosition="start"
                    betweenLabel="to"
                    decimalScale={2}
                    handleChange={this.onChange}
                    maxLabel="Tax Rate Max"
                    maxPlaceholder={taxRateRange.max}
                    minLabel="Tax Rate Min"
                    minPlaceholder={taxRateRange.min}
                    name="taxRateRange"
                  />
                  <FormHelperText data-qa="subject-taxes">{`Subject taxes: ${formatCurrencyFloat(
                    subjectTaxesPerBasis
                  )} ${basisLabel}`}</FormHelperText>
                </FormControl>
                <FormControl component="fieldset" data-qa="checkbox-group">
                  <FormLabel component="legend">Tax Class</FormLabel>
                  <FormGroup row>
                    {Object.entries(TAX_PROPS).map(([prop, value], index) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={prop}
                              checked={this.state[prop]}
                              onChange={this.onCheckboxChange(prop)}
                              value={value}
                              data-qa={`checkbox-${index}`}
                            />
                          }
                          label={TAX_PROPS_LABELS[prop]}
                          key={index}
                        />
                      )
                    })}
                  </FormGroup>
                  <FormHelperText>{`Subject tax class: ${taxClass}`}</FormHelperText>
                </FormControl>
                <MultipleDropDownComponent
                  data-qa="multiple-dropdown"
                  items={NYC_BUILDING_CLASSES_LIST}
                  label="Building Class"
                  onChange={this.onBuildingClassChange}
                  placeholder="Building Class"
                  value={buildingClass}
                />
                <FormControl component="fieldset" data-qa="checkbox-group">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.excludeZeroValueComps}
                        data-qa="checkbox-excludeZeroValueComps"
                        name="excludeZeroValueComps"
                        onChange={this.onCheckboxChange('excludeZeroValueComps')}
                      />
                    }
                    label="Exclude Comps Whose Value Is $0"
                  />
                </FormControl>
                {!taxCompsSearchResults.isLoading && taxComps.length > 0 && (
                  <Stack data-qa="tax-comps-search-results" spzcing={2}>
                    {selectedTaxComps.length > 0 && (
                      <Button
                        color="primary"
                        data-qa="add-selected-btn"
                        onClick={this.addToTaxComps}
                        variant="contained"
                      >
                        {`Add Selected ${selectedTaxComps.length}`}
                      </Button>
                    )}
                    <DxGrid rows={taxComps} columns={TAX_COMPS_TABLE_COLUMNS(basis)}>
                      <SortingState />
                      <IntegratedSorting />
                      <PagingState defaultCurrentPage={tableFilters.currentPage} pageSize={tableFilters.pageSize} />
                      <IntegratedPaging />
                      <SelectionState selection={selectedTaxComps} onSelectionChange={this.setSelected} />
                      <Table
                        cellComponent={this.tableCellTemplate}
                        columnExtensions={COLUMNS}
                        rowComponent={this.tableRowTemplate}
                      />
                      <TableHeaderRow showSortingControls contentComponent={this.TableHeaderContentBase} />
                      <TableSelection selectByRowClick />
                      <PagingPanel />
                    </DxGrid>
                  </Stack>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.searchForSalesComps} disabled={disableSearch} data-qa="search-btn">
                Search
              </Button>
              <Button onClick={() => onClose()} color="primary" autoFocus data-qa="close-btn">
                Close
              </Button>
            </DialogActions>
          </Box>
        )}
      </Dialog>
    )
  }
}

export default AdvancedSearchModal
