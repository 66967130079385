import { findIndex, get, kebabCase, lowerCase, startCase } from 'lodash'
import { RentTypesUnitsOfMeasure } from 'shared/constants/report/incomeApproach'
import { STATE_ABBREVIATIONS } from 'shared/constants/states'

export const transformBaseRent = (baseRent, unitOfMeasure, unitSquareFootage) => {
  let annualRent = baseRent
  let monthlyRent = baseRent
  let annualRentPsf = baseRent
  let mappedUnitOfMeasure = lowerCase(unitOfMeasure)
  if (mappedUnitOfMeasure === 'per square foot') {
    mappedUnitOfMeasure = RentTypesUnitsOfMeasure.PER_SF_PER_YEAR
  }
  switch (mappedUnitOfMeasure) {
    case RentTypesUnitsOfMeasure.ANNUAL:
      monthlyRent = baseRent / 12
      annualRentPsf = baseRent / unitSquareFootage
      break
    case RentTypesUnitsOfMeasure.MONTHLY:
      annualRent = baseRent * 12
      annualRentPsf = annualRent / unitSquareFootage
      break
    case RentTypesUnitsOfMeasure.PER_SF_PER_MONTH:
      annualRent = baseRent * 12 * unitSquareFootage
      annualRentPsf = baseRent * 12
      monthlyRent = baseRent * unitSquareFootage
      break
    case RentTypesUnitsOfMeasure.PER_SF_PER_YEAR:
      annualRent = baseRent * unitSquareFootage * 12
      monthlyRent = annualRent / 12
      annualRentPsf = annualRent / unitSquareFootage
      break
    default:
      break
  }
  return { annualRent, monthlyRent, annualRentPsf, mappedUnitOfMeasure }
}

export const mapSourceOfInformation = sources => {
  const sourceIndex = findIndex(sources, source => source.url)
  const firstSourceWithUrl = sources[sourceIndex]
  const firstSource = sources[0]
  let sourceName = null
  let sourceUrl = null
  let sourceOfInformation = null
  if (firstSourceWithUrl) {
    sourceName = startCase(firstSourceWithUrl.type)
    sourceUrl = firstSourceWithUrl.url
    sourceOfInformation = firstSourceWithUrl.type || null
  } else if (firstSource) {
    sourceName = startCase(firstSource.type)
    sourceOfInformation = firstSource.type || null
  } else {
    sourceOfInformation = null
    sourceName = null
    sourceUrl = null
  }
  return { sourceOfInformation, sourceName, sourceUrl }
}

export const transformCompPlexCompToWebapp = commercialRentComp => {
  const {
    id,
    version,
    isLatestVersion,
    address,
    tenantName,
    unitType,
    unitSquareFootage,
    leaseInformation,
    leaseDate,
    status,
    propertyInformation,
    resourceInformation,
    verificationInformation,
  } = commercialRentComp
  const { postalCode, state, coords } = address
  const { displayStreetAddress: streetAddress, displayCity: city } = propertyInformation

  const { sources } = resourceInformation
  const { sourceOfInformation, sourceName, sourceUrl } = mapSourceOfInformation(sources)

  const {
    unitState,
    unitOfMeasure,
    location,
    leaseTerms,
    leaseStatus,
    streetType,
    ceilingHeight,
    facade,
    floor,
    frontage,
    leaseExpirationDate,
    baseRent,
  } = leaseInformation
  const { annualRent, annualRentPsf, monthlyRent, mappedUnitOfMeasure } = transformBaseRent(
    baseRent,
    unitOfMeasure,
    unitSquareFootage
  )
  const mappedFacade = facade === 'plateGlass' ? facade : 'other'
  const otherFacade = facade === 'other' ? facade : null

  const UNIT_TYPE_MAP = {
    medicalOffice: 'medical',
    communityFacility: 'community',
  }
  const mappedUse = get(UNIT_TYPE_MAP, unitType, unitType)
  return {
    leaseId: id,
    leaseVersionNumber: version,
    status,
    isLatestVersion,
    address: streetAddress,
    annualRent,
    annualRentPsf,
    baseRent,
    city,
    leaseInformation,
    propertyInformation,
    verificationInformation,
    commercialCompGroupId: 'unsorted_group',
    coords: { ...coords },
    dateSigned: leaseDate,
    sourceOfInformation,
    sourceName,
    sourceUrl,
    description: {
      use: mappedUse,
      state: unitState,
      location: kebabCase(location),
      streetType: lowerCase(streetType),
      ceilingHeight: ceilingHeight?.toString() || null,
      facade: startCase(facade),
      floor: {
        belowGrade: floor === 'belowGrade',
        groundFloor: floor === 'groundFloor',
        groundFloorBasement: floor === 'groundFloorBasement',
        other: false,
        upperFloor: floor === 'upperFloor',
      },
      frontage: startCase(frontage),
    },
    location: kebabCase(location),
    streetType: lowerCase(streetType),
    ceilingHeight: ceilingHeight?.toString() || null,
    frontage: startCase(frontage),
    facade: mappedFacade,
    otherFacade,
    fullAddressWithZip: `${streetAddress}, ${city}, ${STATE_ABBREVIATIONS[state]} ${postalCode}`,
    lease: {
      expiresAt: leaseExpirationDate?.toString() || null,
      signedAt: leaseDate,
      status: leaseStatus,
      terms: lowerCase(leaseTerms),
    },
    leaseTerms: lowerCase(leaseTerms),
    monthlyRent,
    rentType: mappedUnitOfMeasure,
    squareFeet: unitSquareFootage,
    state,
    tenantName,
    use: mappedUse,
    zip: postalCode,
    unitType,
  }
}
