import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { Stack } from '@mui/material'

import {
  generateAsCompleteDeductions,
  generateAsStabilizedDeductions,
} from 'shared/utils/textGeneration/incomeApproach/capRateConclusion'
import { toPercents } from 'client-shared/utils/numberOperations'
import { formatFloat } from 'client-shared/utils/numberFormatters'
import { GeneratedComment } from 'client-shared/components'
import purposeAndDateOfValue from 'shared/utils/textGeneration/incomeApproach/capRateConclusion/purposeAndDateOfValue'
import { CAP_RATE_CONCLUSION_PATH } from 'shared/constants/report/keysAndDataPaths'

import AdjustmentsAndLossesTable, {
  adjustmentsAndLossesConstants,
  adjustmentsAndLossesDecorators,
} from 'client-shared/components/AdjustmentsAndLosses'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'

import { TEMPLATE_TYPES } from 'shared/constants/settings'

import wrapForm from '../../wrapForm'

import { CAP_RATE_DISCUSSION_TOOLTIP, CAP_RATE_CONCLUSION, CAP_RATE_CONCLUSION_SUMMARY_TABLE_TITLE } from './constants'
import {
  concludedCapRateDecorator,
  asCompleteDiscountRateDecorator,
  asStabilizedDiscountRateDecorator,
} from './decorators'
import * as formHelpers from './formHelpers'
import CapRateConclusion from './CapRateConclusion'

export const DATA_PATH = CAP_RATE_CONCLUSION_PATH

class CapRateConclusionContainer extends React.PureComponent {
  getPurposeAndDateOfValueDiscussionData = () => {
    const {
      form,
      interestAppraisedAsComplete,
      interestAppraisedAsIsMarketValue,
      interestAppraisedAsStabilized,
      valueAsComplete,
      valueAsStabilized,
    } = this.props
    const { dateOfFinalValueAsIs, dateOfFinalValueAsStabilized, dateOfFinalValueAsComplete } = form.values
    return purposeAndDateOfValue.mapDataFromFormValues({
      dateOfFinalValueAsIs,
      dateOfFinalValueAsStabilized,
      dateOfFinalValueAsComplete,
      interestAppraisedAsIsMarketValue,
      interestAppraisedAsStabilized,
      interestAppraisedAsComplete,
      valueAsComplete,
      valueAsStabilized,
    })
  }

  generateAppraisersCapRateDiscussion = ({ concludedCapRate }) => {
    return `An overall rate of ${
      formatFloat(toPercents(concludedCapRate)) || 0
    }% is applied in the analysis and in line with investor expectations.`
  }

  generateAsStabilizedDeductions = ({ asStabilizedLossItems, asStabilizedResRentLossItems, valueAsComplete }) => {
    const { commercialUnits, residentialUnits } = this.props
    return generateAsStabilizedDeductions({
      asStabilizedLossItems,
      asStabilizedResRentLossItems,
      commercialUnits,
      valueAsComplete,
      residentialUnits,
    })
  }

  generateAsCompleteDeductions = ({ asCompleteLossItems, asCompleteResRentLossItems }) => {
    const { commercialUnits, residentialUnits } = this.props
    return generateAsCompleteDeductions({
      asCompleteLossItems,
      asCompleteResRentLossItems,
      residentialUnits,
      commercialUnits,
    })
  }

  render() {
    const {
      commercialUnits,
      commercialVCLossPercentageAggregate,
      form,
      isFreddieMac,
      netOperatingIncome,
      propertyType,
      residentialUnits,
      residentialVCLossPercentage,
      valueAsComplete,
      valueAsStabilized,
      valueConclusionType,
    } = this.props
    const { values, change, batch } = form
    const marketParticipantSurvey = get(form, 'values.personalSurveys', {})
    const bandOfInvestmentCapRate = get(form, 'values.bandOfInvestmentCapRate')
    const debtCoverageCapRate = get(form, 'values.debtCoverageCapRate')
    const pwc = get(form, 'values.pwc', {})
    const situsRERC = get(form, 'values.situsRERC', {})
    const capRateComps = get(form, 'values.capRateComps', [])

    return (
      <Stack spacing={2}>
        <CapRateConclusion
          bandOfInvestments={bandOfInvestmentCapRate}
          capRateComps={capRateComps}
          debtCoverageRatio={debtCoverageCapRate}
          isFreddieMac={isFreddieMac}
          personalSurvey={marketParticipantSurvey}
          pwc={pwc}
          situsRERC={situsRERC}
        />
        <AdjustmentsAndLossesTable
          batch={batch}
          change={change}
          commercialUnits={commercialUnits}
          commercialVCLossPercentageAggregate={commercialVCLossPercentageAggregate}
          dataSourceType={adjustmentsAndLossesConstants.ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH}
          netOperatingIncome={netOperatingIncome}
          propertyType={propertyType}
          residentialUnits={residentialUnits}
          residentialVCLossPercentage={residentialVCLossPercentage}
          title={CAP_RATE_CONCLUSION_SUMMARY_TABLE_TITLE}
          valueAsComplete={valueAsComplete}
          valueAsStabilized={valueAsStabilized}
          valueConclusionType={valueConclusionType}
          values={values}
          hasIncomeApproach
        />
        <GeneratedComment
          dataPath="discussion"
          getGeneratedText={this.generateAppraisersCapRateDiscussion}
          isDynamicContent
          label="Appraiser's Cap Rate Discussion"
          readOnly
          title="Generated Commentary"
          tooltipText={CAP_RATE_DISCUSSION_TOOLTIP}
        />
        {(valueAsStabilized || valueAsComplete) && (
          <GeneratedComment
            dataPath="asStabilizedDeductionsDiscussion"
            getGeneratedText={this.generateAsStabilizedDeductions}
            isDynamicContent
            label={`As Stabilized to As ${valueAsComplete ? 'Complete' : 'Is'} Deductions Discussion`}
            title="Generated Commentary"
            tooltipText={CAP_RATE_DISCUSSION_TOOLTIP}
          />
        )}
        {valueAsComplete && (
          <GeneratedComment
            dataPath="asCompleteDeductionsDiscussion"
            getGeneratedText={this.generateAsCompleteDeductions}
            isDynamicContent
            label="As Complete to As Is Deductions Discussion"
            title="Generated Commentary"
            tooltipText={CAP_RATE_DISCUSSION_TOOLTIP}
          />
        )}
        <NarrativeComponent
          data={this.getPurposeAndDateOfValueDiscussionData(form.values)}
          generatedText={purposeAndDateOfValue.generate}
          name="purposeAndDateOfValue"
          title="Purpose & Date of Value Discussion"
        />
      </Stack>
    )
  }
}

CapRateConclusionContainer.propTypes = {
  commercialUnits: PropTypes.array.isRequired,
  isFreddieMac: PropTypes.bool.isRequired,
  netOperatingIncome: PropTypes.object.isRequired,
  propertyType: PropTypes.string.isRequired,
  valueAsComplete: PropTypes.bool.isRequired,
  valueAsStabilized: PropTypes.bool.isRequired,
}

const formOptions = {
  decorators: [
    adjustmentsAndLossesDecorators.adjustmentsAndLossesCalculationDecorator,
    concludedCapRateDecorator,
    asStabilizedDiscountRateDecorator,
    asCompleteDiscountRateDecorator,
  ],
  heading: CAP_RATE_CONCLUSION,
  keepDirtyOnReinitialize: true,
  registeredFields: ['npvAdjustments'],
}

const mapStateToProps = state => {
  const capRateConclusionValues = formHelpers.getCapRateConclusionValues(state)

  const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')

  const interestAppraisedAsIsMarketValue = get(
    state,
    'report.reportData.report.reportInformation.interestAppraisedAsIsMarketValue'
  )
  const interestAppraisedAsStabilized = get(
    state,
    'report.reportData.report.reportInformation.interestAppraisedAsStabilized'
  )
  const interestAppraisedAsComplete = get(
    state,
    'report.reportData.report.reportInformation.interestAppraisedAsComplete'
  )
  const propertyType = get(state, 'report.reportData.propertyType')
  const isFreddieMac = get(state, 'report.reportSettings.templateType') === TEMPLATE_TYPES.FREDDIE_MAC

  const residentialUnits = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialProjectedRentRoll.units',
    []
  )
  const residentialVCLossPercentage = get(
    state,
    'report.reportData.incomeApproach.potentialGrossIncome.residentialVCLossPercentage',
    0
  )

  const commercialUnits = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialProjectedRentRoll.units',
    []
  )
  const commercialVCLossPercentageAggregate = get(
    state,
    'report.reportData.incomeApproach.potentialGrossIncome.commercialVCLossPercentageAggregate',
    0
  )

  const valueConclusionType = get(state, 'report.reportSettings.valueConclusionType')
  return {
    interestAppraisedAsComplete,
    interestAppraisedAsIsMarketValue,
    interestAppraisedAsStabilized,
    netOperatingIncome: capRateConclusionValues.netOperatingIncome,
    valueAsComplete,
    valueAsStabilized,
    ...capRateConclusionValues,
    adjustmentTypeToAdd: adjustmentsAndLossesConstants.ADJUSTMENT_TYPES.NPV,
    commercialUnits,
    commercialVCLossPercentageAggregate,
    initialValues: {
      ...capRateConclusionValues,
      adjustmentTypeToAdd: adjustmentsAndLossesConstants.ADJUSTMENT_TYPES.NPV,
      propertyType,
    },
    isFreddieMac,
    propertyType,
    residentialUnits,
    residentialVCLossPercentage,
    valueConclusionType,
  }
}

export default wrapForm(DATA_PATH, formOptions, mapStateToProps, {})(CapRateConclusionContainer)
