import React from 'react'
import PropTypes from 'prop-types'
import { find, get, isEmpty, isNil } from 'lodash'
import { ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel } from '@material-ui/core'
import { Grid, Link, Typography } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import createDecorator from 'final-form-calculate'
import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import AutomationCTA from 'client-shared/components/AutomationCTA'

import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'
import { ENABLE_RES_RENT_RECONCILIATION_AUTOMATION } from 'shared/constants/featureFlags'
import { RESIDENTIAL_RENT_RECONCILIATION_PATH } from 'shared/constants/report/keysAndDataPaths'
import { getMarketBreakdown } from 'shared/automation/residential-income'
import { TEMPLATE_TYPES } from 'shared/constants/settings'

import { roundToNearest } from 'shared/utils/numberOperations'

import wrapForm from '../../../../wrapForm'
import { CompTypes } from '../../../../../constants'
import { GeneratedComment } from '../../../../../../shared/components'
import { saveReport } from '../../../../../redux/actions/report'

import { generateIntroduction } from '../writeupHelper'
import { selectUnitStats } from '../selectors'
import { Labels, GENERATED_TEXT_TOOLTIP } from '../constants'
import { getUnitTypeTitle } from '../tools'

import ResidentialRentReconciliationItemContainer from './ResidentialRentRollReconciliationItemContainer'

const DATA_PATH = RESIDENTIAL_RENT_RECONCILIATION_PATH

const styles = theme => ({
  paper: {
    ...theme.paper,
    padding: '15px 20px',
  },
  loadingContainer: {
    position: 'relative',
    height: 200,
  },
  group: {
    width: '100%',
    marginBottom: 16,
  },
  introductionContainer: {
    width: 800,
    boxSizing: 'content-box',
  },
  automationDisabledMessage: {
    '& ul': {
      margin: 0,
    },
  },
})

class ResidentialRentReconciliation extends React.PureComponent {
  constructor(props) {
    super(props)
    const { form } = props
    const rentReconciliationGroups = get(form.values, 'rentReconciliationGroups', [])
    const panels = Array(rentReconciliationGroups.length).fill(false)
    if (panels.length > 0) {
      panels[0] = true
    }
    this.state = { panels }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    valueConclusionType: PropTypes.string.isRequired,
    unitCompGroups: PropTypes.array,
    buildingComps: PropTypes.array,
    neighborhood: PropTypes.string,
    surroundingUses: PropTypes.object,
    showPerUnitSF: PropTypes.bool,
    rentRollHasNoRents: PropTypes.bool.isRequired,
    form: PropTypes.object,
    state: PropTypes.string,
    saveReport: PropTypes.func.isRequired,
  }

  onChangeExpansionPanelState = index => (event, isExpanded) => {
    const { panels } = this.state
    const updatedPanels = Array(panels.length).fill(false)
    updatedPanels[index] = isExpanded
    this.setState({ panels: updatedPanels })
  }

  generateIntroduction = () => {
    const { state, neighborhood, surroundingUses } = this.props
    return generateIntroduction({ state, neighborhood, surroundingUses })
  }

  automateRentReconciliation = () => {
    const { form, unitCompGroups } = this.props
    const { rentReconciliationGroups, unitRentPSFTimePeriod } = form.values
    const formDataPath = DATA_PATH.join('.')
    const marketConclusionsPerGroup = rentReconciliationGroups.map((rentReconciliationGroup, index) => {
      const { unitGroupKey } = rentReconciliationGroup
      const compUnitGroup = find(unitCompGroups, group => group.unitGroupKey === unitGroupKey)
      const unitStats = selectUnitStats({
        rentReconciliationGroup,
        showDevelopersForecast: false,
        unitRentPSFTimePeriod,
      })
      const comps = get(compUnitGroup, 'units', [])

      const marketConclusion = roundToNearest(unitStats.max, 50)

      const marketBreakdown = getMarketBreakdown(comps, marketConclusion)
      return () => {
        form.change(`rentReconciliationGroups[${index}].marketConclusion`, marketConclusion)
        form.change(`rentReconciliationGroups[${index}].marketBreakdown`, marketBreakdown)
        form.change(`rentReconciliationGroups[${index}].automationMetadata`, {
          sourceName: 'In-Place Rent Roll',
          sourceLinks: ['residential-rent-roll'],
          updatedAt: new Date(),
        })
      }
    })

    form.batch(() => {
      marketConclusionsPerGroup.forEach(change => change())
    })

    global.automationSaved = 'residentialRentReconciliationImport'
    this.props.saveReport(formDataPath)
  }

  render() {
    const {
      classes,
      unitCompGroups,
      buildingComps,
      neighborhood,
      showPerUnitSF,
      valueConclusionType,
      rentRollHasNoRents,
      form,
      templateType,
    } = this.props

    const { compType, rentReconciliationGroups } = form.values
    const useBuildingComps = compType === CompTypes.BUILDING
    const isFreddieMacReport = templateType === TEMPLATE_TYPES.FREDDIE_MAC

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FeatureToggle featureFlag={ENABLE_RES_RENT_RECONCILIATION_AUTOMATION}>
            <AutomationCTA
              CTAMessage="Automate your market conclusions"
              successMessage={DEFAULT_ALERT_MESSAGES.SUCCESS}
              disableCTA={unitCompGroups.length === 0 || rentRollHasNoRents}
              disabledMessage={
                <div className={classes.automationDisabledMessage}>
                  Residential Vacancy and Collection Loss cannot be automated:
                  <ul>
                    <li>
                      In-Place rent Roll table is missing -{' '}
                      <Link href="./residential-rent-roll">Edit In-Place Rent Roll</Link>
                    </li>
                  </ul>
                </div>
              }
              errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
              onAutomationRun={this.automateRentReconciliation}
            />
          </FeatureToggle>
        </Grid>
        {isFreddieMacReport && (
          <Grid item>
            <GeneratedComment
              isDynamicContent
              label="Rent Reconciliation Introduction"
              title="Generated commentary"
              tooltipText={GENERATED_TEXT_TOOLTIP}
              dataPath="introduction"
              getGeneratedText={this.generateIntroduction}
            />
          </Grid>
        )}
        <Grid item container>
          {isEmpty(rentReconciliationGroups) ? (
            <Grid container spacing={1}>
              <Typography className="subtitle1">You need to add items before you can complete this step.</Typography>
            </Grid>
          ) : (
            rentReconciliationGroups.map((rentReconciliationGroup, index) => {
              const { unitGroupKey } = rentReconciliationGroup
              // TODO: Get the unit como group and building comps here

              return (
                <ExpansionPanel
                  expanded={this.state.panels[index]}
                  className={classes.group}
                  key={unitGroupKey}
                  onChange={this.onChangeExpansionPanelState(index)}
                  CollapseProps={{ unmountOnExit: true }}
                >
                  <ExpansionPanelSummary
                    data-qa={`${unitGroupKey}-bedroom-rent-reconciliation`}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography variant="h6">{getUnitTypeTitle(rentReconciliationGroup)} Reconciliation</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      key={rentReconciliationGroup}
                      data-qa={`${unitGroupKey}-bedroom-rent-reconciliation-container`}
                      item
                      xs={12}
                    >
                      <ResidentialRentReconciliationItemContainer
                        formValues={form.values}
                        fieldNamePrefix={`rentReconciliationGroups[${index}]`}
                        useBuildingComps={useBuildingComps}
                        unitCompGroups={unitCompGroups}
                        buildingComps={buildingComps}
                        neighborhood={neighborhood}
                        showPerUnitSF={showPerUnitSF}
                        valueConclusionType={valueConclusionType}
                      />
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })
          )}
        </Grid>
      </Grid>
    )
  }
}

const cleanDetails = (value, sourceField, oldValue, previousValues) => {
  const isInitialLoad = isEmpty(previousValues)
  if (isInitialLoad) {
    return {}
  }

  const details = sourceField.replace('general', 'details')
  return {
    [details]: [],
  }
}

const decorator = createDecorator(
  {
    field: /rentReconciliationGroups\[\d]\.location.general/,
    updates: cleanDetails,
  },
  {
    field: /rentReconciliationGroups\[\d]\.finishes.general/,
    updates: cleanDetails,
  },
  {
    field: /rentReconciliationGroups\[\d]\.amenities.general/,
    updates: cleanDetails,
  }
)

const mapDispatchToProps = dispatch => ({
  saveReport: formPath => dispatch(saveReport(formPath)),
})

export default wrapForm(
  DATA_PATH,
  { styles, heading: Labels.RESIDENTIAL_RENT_RECONCILIATION, decorators: [decorator] },
  state => {
    const valueConclusionType = get(state, 'report.reportSettings.valueConclusionType')
    const templateType = get(state, 'report.reportSettings.templateType')

    const { unitCompGroups, selectedBuildingComps } = get(
      state,
      'report.reportData.incomeApproach.residentialIncome.residentialRentComps'
    )

    const rentRoll = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll') || {}
    const showPerUnitSF = rentRoll.perUnitSF

    const neighborhood = get(state, 'report.reportData.propertyInformation.propertySummary.neighborhood')
    const surroundingUses = get(state, 'report.reportData.propertyInformation.siteDescription.surroundingUses', {})
    const rentRollHasNoRents = get(rentRoll, 'units', []).every(unit => unit.rent === 0 || isNil(unit.rent))

    return {
      valueConclusionType,
      unitCompGroups,
      buildingComps: selectedBuildingComps,
      neighborhood,
      surroundingUses,
      showPerUnitSF,
      rentRollHasNoRents,
      state: get(state, 'report.reportData.state'),
      templateType,
    }
  },
  mapDispatchToProps
)(ResidentialRentReconciliation)
