import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { connect } from 'react-redux'
import { useForm, useField, useFormState } from 'react-final-form'
import { PropertyTypes } from 'shared/constants'

import { DEFAULT_APPROACH_SETTINGS } from 'shared/constants/report'

import { selectSyncState } from './redux/selectors'
import { pullReportSettings, clearReportSettings } from './redux/actions'
import ReportSettingsView from './ReportSettingsView'

const ReportSettings = ({
  invalid,
  openPreviousStep,
  previousStep,
  submit,
  fetchedSettings,
  clearReportSettings,
  pullReportSettings,
  syncState,
}) => {
  const pullExternalDataField = useField('settings.pullExternalData')
  const pullExternalData = pullExternalDataField.input.value
  const [shouldRenderSFJobInput, setShouldRenderSFJobInput] = useState(pullExternalData)

  const formApi = useForm()
  const { values } = useFormState()
  const isBlocks = get(values, 'settings.isBlocks')

  const approaches = get(values, 'settings.approaches', DEFAULT_APPROACH_SETTINGS)
  const [prevApproaches, setPrevApproaches] = useState(approaches)

  const resetReportSettings = useCallback(() => {
    clearReportSettings()

    formApi.batch(() => {
      formApi.change('settings.valueConclusionType', '')
      formApi.change('settings.incomeType', '')
      formApi.change('settings.templateType', '')
    })
  }, [clearReportSettings, formApi])

  const fetchReportSettings = useCallback(
    jobNumber => {
      resetReportSettings()

      pullReportSettings({ reportNumber: jobNumber })
    },
    [pullReportSettings, resetReportSettings]
  )

  const onPullExternalDataChange = pullExternalData => {
    formApi.resetFieldState('reportNumber')
    resetReportSettings()

    if (pullExternalData) {
      setShouldRenderSFJobInput(true)
    } else {
      setShouldRenderSFJobInput(false)
    }
  }

  const handleSubmit = () => {
    submit()
  }

  useEffect(() => {
    if (syncState.completed) {
      const { valueConclusionType, propertyType, templateType } = fetchedSettings

      formApi.batch(() => {
        formApi.change('settings.valueConclusionType', valueConclusionType)
        if (propertyType === PropertyTypes.COMMERCIAL) {
          formApi.change('settings.incomeType', null)
        } else {
          formApi.change('settings.incomeType', propertyType)
        }
        formApi.change('settings.templateType', templateType)
      })
    }
  }, [fetchedSettings, formApi, syncState.completed])

  useEffect(() => {
    if (approaches.cost && !prevApproaches?.land) {
      formApi.change('settings.approaches.land', true)
      setPrevApproaches({ ...prevApproaches, cost: true, land: true })
    } else {
      setPrevApproaches({ ...prevApproaches, cost: approaches.cost })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formApi, approaches.cost])

  useEffect(() => {
    if (!approaches.land && prevApproaches?.cost) {
      formApi.change('settings.approaches.cost', false)
      setPrevApproaches({ ...prevApproaches, cost: false, land: false })
    } else {
      setPrevApproaches({ ...prevApproaches, land: approaches.land })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formApi, approaches.land])

  useEffect(() => {
    return () => {
      clearReportSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReportSettingsView
      {...{
        fetchReportSettings,
        handleSubmit,
        invalid,
        isBlocks,
        onPullExternalDataChange,
        openPreviousStep,
        previousStep,
        pullExternalData,
        resetReportSettings,
        shouldRenderSFJobInput,
        syncState,
      }}
    />
  )
}

ReportSettings.propTypes = {
  openPreviousStep: PropTypes.func.isRequired,
  previousStep: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  fetchedSettings: PropTypes.bool,
  syncState: PropTypes.shape({
    syncCompleted: PropTypes.bool.isRequired,
    syncFailed: PropTypes.bool.isRequired,
    syncInProgress: PropTypes.bool.isRequired,
  }).isRequired,
}

ReportSettings.defaultProps = {}

export default connect(
  state => ({
    syncState: selectSyncState(state),
    fetchedSettings: get(state, 'reportSettings.fetchedSettings', null),
  }),
  {
    pullReportSettings,
    clearReportSettings,
  }
)(ReportSettings)
