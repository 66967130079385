import React from 'react'
import { get, startCase } from 'lodash'

import { Box, Grid, IconButton, Paper, Table, TableBody, TableHead, Typography } from '@mui/material'
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined'

import { COMMERCIAL_STABILIZED_LEASE_STRUCTURE_PATH } from 'shared/constants/report/keysAndDataPaths'
import { DropDown, Text } from 'client-shared/components'

import { TableHeadRow, TableBodyRowNew } from '../../../../../../shared/components/TableHelpers'

import NarrativeComponent from '../../../../../../shared/components/NarrativeComponent'

import leaseStructureDiscussion from '../../../../../../../../shared/utils/textGeneration/incomeApproach/commercial/leaseStructureDiscussion'
import { LEASE_TERMS_LIST, OWNER_TYPES_LIST } from '../../../../../constants/commercial'
import wrapForm from '../../../../wrapForm'

const DATA_PATH = COMMERCIAL_STABILIZED_LEASE_STRUCTURE_PATH

const styles = theme => ({
  cell: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: 0,
  },
  readOnlyCell: {
    color: 'grey',
  },
  paper: theme.paper,
})

class CommercialStabilizedLeaseStructure extends React.PureComponent {
  columns = [
    {
      name: 'unitNumber',
      title: 'Unit',
    },
    {
      name: 'tenantName',
      title: 'Tenant',
    },
    {
      name: 'leaseStatus',
      title: 'Lease Status',
    },
    {
      name: 'rentIncrease',
      title: 'Rent Steps',
    },
    {
      name: 'leaseTerms',
      title: 'Lease Terms',
    },
    {
      name: 'taxes',
      title: 'Taxes',
    },
    {
      name: 'electric',
      title: 'Electric',
    },
    {
      name: 'insurance',
      title: 'Insurance',
    },
    {
      name: 'maintenance',
      title: 'Maintenance',
    },
    {
      name: 'renewalOptions',
      title: 'Renewal Options',
    },
  ]

  fillAll = (field, value) => {
    const { form } = this.props
    const units = get(form, 'values.units', [])

    const updatedUnits = units.map(unit => ({ ...unit, [field]: value }))

    form.change('units', updatedUnits)
  }

  getCellContent = data => {
    const { unit, unitName, index, column } = data

    const isVacant = unit.isVacant
    const fieldPath = `${unitName}.${column.name}`
    const fieldValue = get(unit, column.name)

    switch (column.name) {
      case 'unitNumber':
        return (
          <Typography variant="body1" sx={{ fontSize: '10px' }}>
            {index + 1}
          </Typography>
        )
      case 'tenantName':
        return (
          <Typography variant="body1" sx={{ fontSize: '12px' }}>
            {isVacant ? `Commercial Unit ${index + 1}` : fieldValue}
          </Typography>
        )
      case 'rentIncrease':
      case 'renewalOptions':
        return <Text name={fieldPath} />
      case 'leaseTerms':
        return (
          <Grid container wrap="nowrap" alignItems="center">
            <DropDown name={fieldPath} items={LEASE_TERMS_LIST} getValue={startCase} size="small" shrink={true} />
            {index === 0 && (
              <IconButton size="small" onClick={() => this.fillAll(column.name, fieldValue)}>
                <SystemUpdateAltOutlinedIcon fontSize="4px" />
              </IconButton>
            )}
          </Grid>
        )
      case 'taxes':
      case 'electric':
      case 'insurance':
      case 'maintenance':
        return (
          <Grid container wrap="nowrap" alignItems="center">
            <DropDown name={fieldPath} items={OWNER_TYPES_LIST} getValue={startCase} size="small" shrink={true} />
            {index === 0 && (
              <IconButton size="small" onClick={() => this.fillAll(column.name, fieldValue)}>
                <SystemUpdateAltOutlinedIcon fontSize="4px" />
              </IconButton>
            )}
          </Grid>
        )
      default:
        return (
          <Typography variant="body1" sx={{ fontSize: '12px' }}>
            {fieldValue || '-'}
          </Typography>
        )
    }
  }

  render() {
    const { classes, form } = this.props
    const units = get(form, 'values.units', [])

    return (
      <Grid container wrap="nowrap" direction="column">
        <Grid item>
          <Paper className={classes.paper}>
            {units.length > 0 ? (
              <>
                <Box pb={2}>
                  <NarrativeComponent
                    title="Lease Structure Discussion"
                    generatedText={leaseStructureDiscussion.generate}
                    data={leaseStructureDiscussion.mapDTO(form.values)}
                    name="leaseStructureDiscussion"
                  />
                </Box>
                <Table>
                  <TableHead>
                    <TableHeadRow columns={this.columns} cellClass={classes.cell} />
                  </TableHead>
                  <TableBody>
                    {units.map((unit, index) => (
                      <TableBodyRowNew
                        key={unit._id}
                        columns={this.columns}
                        getCellContent={this.getCellContent}
                        unit={unit}
                        index={index}
                        unitName={`units[${index}]`}
                        cellClass={classes.cell}
                      />
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <Typography variant="subtitle1">There are no commercial units</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default wrapForm(DATA_PATH, { styles })(CommercialStabilizedLeaseStructure)
