import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

import { Grid } from '@material-ui/core'
import { Typography } from '@mui/material'

import { PROPERTY_MAPS_PATH } from 'shared/constants/report/keysAndDataPaths'
import { TEMPLATE_TYPES } from 'shared/constants/settings'

import { FLOOD_MAP_LOCATION_LABELS } from '../../../../../shared/constants/report/property'

import CartoDBConfig from '../../../shared/utils/cartodbConfig'
import wrapForm from '../wrapForm'
import { SubjectMapWizard, ZoningMapWizard, FloodMapWizard, TaxMapWizard } from '../../../shared/components/MapWizard'
import { CheckboxWithLabel } from '../../../shared/components/Checkbox'
import { Text, Number, RadioButtonList } from '../../../shared/components'
import MultipleDropDown from '../../../shared/components/DropDown/MultipleDropDownField'
import { BOROUGH_MAP_STYLE_LOOKUP, BOROUGHS } from '../../../shared/constants/borough'
import { arrayToKeyValuePairs, keyValuePairsToCheckboxValues } from '../../../shared/utils/ui/checkboxHelper'

import { GRID_CONTAINER_GUTTER, STREET_ACCESS, CORNERS } from '../../constants'

import { getAddress } from '../../../shared/utils/geography'

import { MID_BLOCK, THROUGH_LOT, CORNER } from '../../../../../shared/constants/report'

import { floodDataShape } from './propTypes'

export const DATA_PATH = PROPERTY_MAPS_PATH

const heading = 'Maps'

const corners = arrayToKeyValuePairs(CORNERS)
const floodMapLocationOptions = keyValuePairsToCheckboxValues(FLOOD_MAP_LOCATION_LABELS, 'value')

const streetAccessItems = STREET_ACCESS.map(item => ({
  value: item,
  label: item,
}))

const styles = theme => ({
  checkbox: {
    height: 'inherit',
  },
  taxMapContainer: {
    marginBottom: 65,
  },
  footerContainer: {
    padding: 12,
  },
})

class PropertyMaps extends React.PureComponent {
  static propTypes = {
    address: PropTypes.PropTypes.shape({
      addressOne: PropTypes.string.isRequired,
      addressTwo: PropTypes.string.isRequired,
    }).isRequired,
    borough: PropTypes.oneOf(BOROUGHS).isRequired,
    coords: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    block: PropTypes.number.isRequired,
    lot: PropTypes.string.isRequired,
    isFreddieMacReport: PropTypes.bool.isRequired,
    pulledFloodData: floodDataShape,
  }

  componentDidUpdate(prevProps) {
    const { form, pulledFloodData } = this.props

    if (
      pulledFloodData.floodMap &&
      !form.values.floodMap?.isOverridden &&
      form.values.floodMap !== prevProps.pulledFloodData.floodMap
    ) {
      form.change('floodMap', pulledFloodData.floodMap)
    }
  }
  render() {
    const { address, borough, coords, block, lot, isFreddieMacReport, classes, form } = this.props
    const isMidBlock = get(form, 'values.corner') === MID_BLOCK
    const isThroughLot = get(form, 'values.corner') === THROUGH_LOT
    const isCorner = get(form, 'values.corner') === CORNER

    const propertyFrontageHidden = isCorner || isThroughLot

    const zoningOptions = {
      maxZoom: CartoDBConfig.zoning.maxZoom,
      initialZoom: CartoDBConfig.zoning.initialZoom,
      initialCoordinates: { lat: coords.latitude, lng: coords.longitude },
      baseMap: CartoDBConfig.baseMap,
      mapLayers: CartoDBConfig.zoning.mapLayers,
      legendItems: CartoDBConfig.zoning.legendItems,
      customLayerName: BOROUGH_MAP_STYLE_LOOKUP[borough],
      block,
      lot,
    }

    const floodOptions = {
      maxZoom: CartoDBConfig.zoning.maxZoom,
      initialZoom: CartoDBConfig.flood.initialZoom,
      initialCoordinates: { lat: coords.latitude, lng: coords.longitude },
      baseMap: CartoDBConfig.baseMap,
      mapLayers: CartoDBConfig.flood.mapLayers,
      legendItems: CartoDBConfig.flood.legendItems,
      marker: {
        coords: [coords.latitude, coords.longitude],
        labelContent: {
          ...address,
        },
      },
    }

    const taxOptions = {
      maxZoom: CartoDBConfig.tax.maxZoom,
      initialZoom: CartoDBConfig.tax.initialZoom,
      initialCoordinates: { lat: coords.latitude, lng: coords.longitude },
      baseMap: CartoDBConfig.baseMap,
      mapLayers: CartoDBConfig.tax.mapLayers,
      customLayerName: BOROUGH_MAP_STYLE_LOOKUP[borough],
      block,
      lot,
    }

    const subjectOptions = {
      zoomDelta: 0.3,
      zoomSnap: 0.1,
      wheelPxPerZoomLevel: 20,
      maxZoom: CartoDBConfig.subject.maxZoom,
      initialCoordinates: { lat: coords.latitude, lng: coords.longitude },
      initialZoom: CartoDBConfig.subject.initialZoom,
      baseMap: CartoDBConfig.baseMap,
      marker: {
        coords: [coords.latitude, coords.longitude],
        labelContent: {
          ...address,
        },
      },
    }

    return (
      <div>
        <Grid container justify="flex-start" spacing={GRID_CONTAINER_GUTTER}>
          <Grid item xs={12} sm={6} data-qa="zoning-map-wizard">
            <ZoningMapWizard
              label="Zoning Map"
              name="zoningMapImage"
              mapOptions={{
                isZoomOn: true,
                ...zoningOptions,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} data-qa="flood-map-wizard">
            <FloodMapWizard
              label="Flood Map"
              name="floodMap"
              mapOptions={{
                isZoomOn: true,
                ...floodOptions,
              }}
            >
              <Grid container spacing={16} className={classes.footerContainer}>
                <Grid item xs={6}>
                  <RadioButtonList
                    label="Location in report."
                    name="floodMapReportLocation"
                    items={floodMapLocationOptions}
                    disabled={isFreddieMacReport}
                    horizontal
                  />
                </Grid>
                <Grid item xs={6}>
                  <Text name="femaFloodZone" label="FEMA Flood Zone" fullWidth />
                  <Typography variant="caption" display="block" gutterBottom>
                    Required for Fannie Mae reports.
                  </Typography>
                </Grid>
              </Grid>
            </FloodMapWizard>
          </Grid>
          <Grid item xs={12} sm={6} data-qa="tax-map-wizard" className={classes.taxMapContainer}>
            <TaxMapWizard
              label="Tax Map"
              name="taxMapImage"
              mapOptions={{
                isZoomOn: true,
                ...taxOptions,
              }}
            >
              <div className={classes.footerContainer}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    {!propertyFrontageHidden && (
                      <Number
                        name="propertyFrontage"
                        label="Property Frontage (ft)"
                        margin="normal"
                        fullWidth
                        decimalScale={2}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxWithLabel name="isRectangular" label="The subject is on a rectangular lot." />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioButtonList label="Corner" name="corner" items={corners} horizontal />
                  </Grid>
                  {!isMidBlock && (
                    <Grid item xs={12}>
                      <Text name="streetOneName" label="Street 1" fullWidth />
                      <Number name="streetOneFrontage" label="Street 1 Frontage (ft)" fullWidth decimalScale={2} />
                      <Text name="streetTwoName" label="Street 2" fullWidth />
                      <Number name="streetTwoFrontage" label="Street 2 Frontage (ft)" fullWidth decimalScale={2} />
                      <Text name="streetThreeName" label="Street 3" fullWidth />
                      <Number name="streetThreeFrontage" label="Street 3 Frontage (ft)" fullWidth decimalScale={2} />
                      <MultipleDropDown
                        name="streetAccess"
                        label="Which streets do you access the building from?"
                        placeholder="Select Streets"
                        fullWidth
                        items={streetAccessItems}
                        data-qa="streetAccess-filter"
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </TaxMapWizard>
          </Grid>

          <Grid item xs={12} sm={6} data-qa="subject-map-wizard">
            <SubjectMapWizard
              label="Subject Map"
              name="subjectMapImage"
              mapOptions={{
                isZoomOn: true,
                ...subjectOptions,
              }}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default wrapForm(DATA_PATH, { styles, heading }, state => {
  const templateType = get(state, 'report.reportSettings.templateType')
  const reportData = get(state, 'report.reportData')
  const pulledFloodData = get(state, 'shared.pulledFloodData')

  const isFreddieMacReport = templateType === TEMPLATE_TYPES.FREDDIE_MAC
  const streetAddress = get(reportData, 'propertyInformation.propertySummary.streetAddress')
  const city = get(reportData, 'propertyInformation.propertySummary.city')
  const propertyState = get(reportData, 'state')
  const borough = get(reportData, 'borough')
  const block = get(reportData, 'block')
  const lot = get(reportData, 'lot')
  const zip = get(reportData, 'zip')

  const address = getAddress(streetAddress, city, propertyState, zip)
  return {
    coords: get(reportData, 'propertyInformation.coords'),
    address,
    borough,
    block,
    lot,
    isFreddieMacReport,
    pulledFloodData,
  }
})(PropertyMaps)
