import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'
import BoweryDate from '@bowery-valuation/bowery-date'
import { isNumber, startCase } from 'lodash'

import { formatCurrencyFloat, formatInt, formatPercentageString } from 'shared/utils/formatters/numberFormatters'

import { DEFAULT_PHYSICAL_ADJUSTMENT_LABELS, MARKET_ADJUSTMENT_LABELS } from '../CommercialRentReconciliationConstants'

import {
  DEFAULT_COMP_INFO_ROW_IDS,
  COMP_INFO_ROW_IDS_TO_PROPERTY,
  COMP_INFO_ROW_IDS_TO_LABEL,
  MARKET_ROWS,
  REMOVABLE_PHYSICAL_ROWS,
} from '../CommercialRentReconciliationItemV2Constants'

const getCompInfoRowFormatter = (rowId: string): { formatter?: (value: string) => string } => {
  let formatter = null

  switch (rowId) {
    case DEFAULT_COMP_INFO_ROW_IDS.dateSigned:
      formatter = (date: string) => new BoweryDate(date).formatShortDate()
      break

    case DEFAULT_COMP_INFO_ROW_IDS.psfRent:
      formatter = formatCurrencyFloat
      break

    case DEFAULT_COMP_INFO_ROW_IDS.leaseTerms:
    case DEFAULT_COMP_INFO_ROW_IDS.tenantName:
    case DEFAULT_COMP_INFO_ROW_IDS.frontage:
    case DEFAULT_COMP_INFO_ROW_IDS.location:
      formatter = startCase
      break

    case DEFAULT_COMP_INFO_ROW_IDS.squareFeet:
      formatter = formatInt
      break

    case DEFAULT_COMP_INFO_ROW_IDS.ceilingHeight:
      formatter = (value: any) => {
        return isNumber(value) ? formatInt(value) : startCase(value)
      }
      break

    default:
      break
  }

  return formatter ? { formatter } : {}
}

export const getCompInformationRows = (rentPsfLabel: string) => {
  const DEFAULT_COMP_INFO_ROW_PROPERTIES = {
    readOnly: false,
    suppressMovable: true,
    permanent: true,
    type: ColumnDataTypeEnum.text,
    rowDef: { hideAction: true },
  }

  const defaultRows = Object.keys(DEFAULT_COMP_INFO_ROW_IDS).map(rowId => ({
    ...DEFAULT_COMP_INFO_ROW_PROPERTIES,
    rowId,
    id: COMP_INFO_ROW_IDS_TO_PROPERTY[rowId],
    label: rowId === DEFAULT_COMP_INFO_ROW_IDS.psfRent ? rentPsfLabel : COMP_INFO_ROW_IDS_TO_LABEL[rowId],
    ...getCompInfoRowFormatter(rowId),
  }))

  return defaultRows
}

export const getNewAdjustmentName = (customAdjustments: { [id in string]: string }) => {
  let defaultName = `Other Adjustment`
  const rowsSet = new Set(Object.values(customAdjustments))
  let index = 1
  while (rowsSet.has(defaultName)) {
    defaultName = `Other Adjustment ${index}`
    index += 1
  }
  return defaultName
}

const getAdjustmentLabel = (adjustment: string) => {
  return MARKET_ADJUSTMENT_LABELS[adjustment] || DEFAULT_PHYSICAL_ADJUSTMENT_LABELS[adjustment] || adjustment
}

export const getMarketRows = (rentComps: any, leaseTermsCalcType: string, disableInputs: boolean) => {
  const adjustmentsNames: { name: string; label?: string; isCustom: boolean }[] = MARKET_ROWS.map(row => ({
    name: row,
    isCustom: false,
  }))

  const adjustmentRows: any = []
  adjustmentsNames.forEach(({ name, label, isCustom }) => {
    const showAction = isCustom || REMOVABLE_PHYSICAL_ROWS.includes(name)
    const adjustmentRow: any = {
      readOnly: disableInputs,
      suppressMovable: true,
      type: ColumnDataTypeEnum.text,
      id: name,
      custom: isCustom,
      label: isCustom ? label : getAdjustmentLabel(name),
      rowDef: { hideAction: !showAction },
    }
    rentComps.forEach(
      ({ id, adjustments, customAdjustments }: { id: string; adjustments: any; customAdjustments: any }) => {
        const adjustmentValue = adjustments[name] || customAdjustments[name] || 0
        if (name === 'leaseTerms') {
          adjustmentRow[id] =
            leaseTermsCalcType === '$/SF' ? `${adjustmentValue}$/SF` : formatPercentageString(adjustmentValue)
        } else {
          adjustmentRow[id] = formatPercentageString(adjustmentValue)
        }
      }
    )
    adjustmentRows.push(adjustmentRow)
  })
  return adjustmentRows
}

export const getPhysicalRows = (
  physicalAdjustments: string[],
  customAdjustments: { [key: string]: string },
  rentComps: any,
  leaseTermsCalcType: string,
  disableInputs: boolean
) => {
  const adjustmentsNames: { name: string; label?: string; isCustom: boolean }[] = [
    ...physicalAdjustments.map(id => ({ name: id, label: DEFAULT_PHYSICAL_ADJUSTMENT_LABELS[id], isCustom: false })),
    ...Object.entries(customAdjustments).map(([id, label]) => ({ name: id, label, isCustom: true })),
  ]

  const adjustmentRows: any = []
  adjustmentsNames.forEach(({ name, label, isCustom }) => {
    const showAction = isCustom || REMOVABLE_PHYSICAL_ROWS.includes(name)
    const adjustmentRow: any = {
      readOnly: disableInputs,
      suppressMovable: true,
      type: ColumnDataTypeEnum.text,
      id: name,
      custom: isCustom,
      label: isCustom ? label : getAdjustmentLabel(name),
      rowDef: { hideAction: !showAction },
    }
    rentComps.forEach(
      ({ id, adjustments, customAdjustments }: { id: string; adjustments: any; customAdjustments: any }) => {
        const adjustmentValue = adjustments[name] || customAdjustments[name] || 0
        if (name === 'leaseTerms') {
          adjustmentRow[id] =
            leaseTermsCalcType === '$/SF' ? `${adjustmentValue}$/SF` : formatPercentageString(adjustmentValue)
        } else {
          adjustmentRow[id] = formatPercentageString(adjustmentValue)
        }
      }
    )
    adjustmentRows.push(adjustmentRow)
  })
  return adjustmentRows
}
