import React, { useMemo } from 'react'
import { Paper, Autocomplete, TextField, InputAdornment } from '@mui/material'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import SearchIcon from '@mui/icons-material/Search'
import { sortBy, uniqBy, isEmpty } from 'lodash'
import { matchSorter } from 'match-sorter'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

const CustomPaper = props => {
  return <Paper {...props} sx={{ padding: 0 }} />
}

const ClientSearch = ({ index, disabled, onClientChange, clientSuggestions, client, name, onFocus }) => {
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ['fullNameWithClient'],
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    })

  const clientNumber = index ? index + 1 : ''

  const options = useMemo(() => {
    return sortBy(
      uniqBy(
        clientSuggestions
          .map(client => {
            const trimmedFullName = client.fullName.trim()
            const trimmedClient = client.client?.trim()
            return {
              ...client,
              fullName: trimmedFullName,
              fullNameWithClient: `${trimmedFullName}${trimmedClient ? ` - ${trimmedClient}` : ''}`,
            }
          })
          .filter(client => !isEmpty(client.fullName) && /[a-zA-Z]/.test(client.fullName)),
        'fullNameWithClient'
      ),
      ['fullNameWithClient']
    )
  }, [clientSuggestions])

  const renderOption = (props, option, { inputValue }) => {
    const matches = match(option.fullNameWithClient, inputValue)
    const parts = parse(option.fullNameWithClient, matches)

    return (
      <li {...props}>
        <div>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    )
  }

  return (
    <Autocomplete
      id={name}
      onFocus={onFocus}
      fullWidth
      PaperComponent={CustomPaper}
      popupIcon={null}
      blurOnSelect={true}
      clearIcon={null}
      disabled={disabled}
      onChange={onClientChange}
      value={client}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={option => option.fullNameWithClient || ''}
      renderInput={params => (
        <TextField
          {...params}
          label={`Client ${clientNumber}`}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={!options.length ? 'Loading clients...' : 'Search Clients'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={renderOption}
    />
  )
}

ClientSearch.propTypes = {
  clientSuggestions: PropTypes.array.isRequired,
  onClientChange: PropTypes.func.isRequired,
  client: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  onFocus: PropTypes.func.isRequired,
}
ClientSearch.defaultProps = {
  client: {},
}

const ClientsSearchField = ({ name, validate, ...props }) => (
  // It's important to have key={name} here because of issue - https://github.com/final-form/react-final-form-arrays/issues/125
  // Poor final form.
  <Field key={name} name={name} validate={validate}>
    {({ input }) => {
      return (
        <ClientSearch
          onClientChange={(event, option) => input.onChange(option)}
          onFocus={input.onFocus}
          client={input.value}
          name={name}
          {...props}
        />
      )
    }}
  </Field>
)

export default ClientsSearchField
