import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Card, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'
import { Paper, Stack } from '@mui/material'
import { grey } from '@material-ui/core/colors'

import { get, lowerCase, isEqual } from 'lodash'

import { RadioButtonList, GeneratedComment, CheckboxGroup } from 'client-shared/components'
import { longDateFormat } from 'client-shared/utils/dateHelper'
import { notEmpty } from 'client-shared/utils/validation'
import { RECONCILIATION_APPROACH } from 'client-shared/constants'
import { fromCurrency } from 'shared/utils/numberOperations'
import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'

import CostApproachCalculations from 'shared/report-calculations/cost-approach/cost-approach-calculations'
import { formatCurrencyInt } from 'shared/utils/formatters/numberFormatters'
import { FINAL_VALUES_AND_RECONCILIATION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import { NOT_AVAILABLE } from '../../../constants'

import {
  FINAL_VALUES_AND_RECONCILIATION_HEADING,
  FINAL_VALUE_AND_RECONCILIATION_TOOLTIP_TEXT,
  APPROACH_LABELS,
  SALES_APPROACH_METHODOLOGY_TITLE,
  INCOME_APPROACH_METHODOLOGY_TITLE,
  COST_APPROACH_METHODOLOGY_TITLE,
  PRIMARY_APPROACH,
  ADDITIONAL_APPROACH,
  APPROACH_NOT_USED,
} from './constants'

export const DATA_PATH = FINAL_VALUES_AND_RECONCILIATION_PATH
const { COST, INCOME, SALES, LAND, OTHER } = RECONCILIATION_APPROACH

const styles = theme => ({
  tableRow: { border: `1.5px solid ${grey[600]}` },
  headerCell: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  explanatoryText: {
    color: grey[600],
  },
})

const FinalValuesAndReconciliation = ({
  form: {
    values: {
      approachSelected,
      additionalApproaches = {},
      methodologiesUsed = {},
      costApproach,
      totalLeaseUpCosts,
      isCostApproachEnabled,
      asIsDateOfValue,
      landValue,
      valueConclusionType,
      otherApproachValue,
    },
    change,
  },
  acas,
  valueAsComplete,
  valueAsStabilized,
  classes,
  interestAppraisedAsIsMarketValue,
  interestAppraisedAsComplete,
  interestAppraisedAsStabilized,
  costApproachMethodology,
  incomeCapitalizationApproachMethodology,
  salesComparisonApproachMethodology,
}) => {
  const [additionalApproachOptions, setAdditionalApproachOptions] = useState([
    {
      name: lowerCase(COST),
      label: COST,
    },
    {
      name: lowerCase(INCOME),
      label: INCOME,
    },
    {
      name: lowerCase(SALES),
      label: SALES,
    },
    {
      name: lowerCase(LAND),
      label: LAND,
    },
  ])
  const replacementCostNew = get(costApproach, 'reconciliation.reconciledValue', 0)
  const { conclusion, subjectBudget } = costApproach || {}

  useEffect(() => {
    const newAdditionalApproachOptions = additionalApproachOptions

    for (const option of newAdditionalApproachOptions) {
      const disableSales = [LAND, SALES].includes(approachSelected) && [LAND, SALES].includes(option.label)
      option.disabled = option.label === approachSelected || disableSales
    }

    setAdditionalApproachOptions(newAdditionalApproachOptions)

    additionalApproaches[lowerCase(approachSelected)] = false
  }, [approachSelected, additionalApproachOptions, additionalApproaches])

  useEffect(() => {
    const updatedMethodologiesUsed = {
      cost: approachSelected === RECONCILIATION_APPROACH.COST || !!additionalApproaches.cost,
      income: approachSelected === RECONCILIATION_APPROACH.INCOME || !!additionalApproaches.income,
      sales: approachSelected === RECONCILIATION_APPROACH.SALES || !!additionalApproaches.sales,
      land: approachSelected === RECONCILIATION_APPROACH.LAND || !!additionalApproaches.land,
    }
    if (!isEqual(methodologiesUsed, updatedMethodologiesUsed)) {
      change('methodologiesUsed', updatedMethodologiesUsed)
    }
  }, [approachSelected, additionalApproaches, change, methodologiesUsed])

  const getGeneratedText = approach => {
    if (approachSelected && approachSelected === approach) {
      return PRIMARY_APPROACH[approach]
    }

    if (additionalApproaches && additionalApproaches[lowerCase(approach)]) {
      if (approach === RECONCILIATION_APPROACH.LAND) {
        return ADDITIONAL_APPROACH[RECONCILIATION_APPROACH.SALES]
      }
      return ADDITIONAL_APPROACH[approach]
    }

    if (approach === RECONCILIATION_APPROACH.COST && !isCostApproachEnabled) {
      return 'The difficulty in credibly isolating the influence of physical depreciation and obsolescence affects the reliability of the Cost Approach. Investors typically give nominal weight to this analysis once a property is operating on a stabilized basis as its cost bears little relationship to the value; therefore, we exclude this approach from our analysis.'
    }
    if (approachSelected === RECONCILIATION_APPROACH.LAND && approach === RECONCILIATION_APPROACH.SALES) {
      return PRIMARY_APPROACH[approach]
    }

    return APPROACH_NOT_USED[approach]
  }

  const getDescriptionOfMethodologies = finalValuesAndReconciliation => {
    const {
      costApproachParagraph,
      incomeApproachParagraph,
      salesApproachParagraph,
      descriptionOfMethodologiesParagraph,
    } = finalValuesAndReconciliation

    return (
      costApproachParagraph.join('\n\n') +
      '\n\n' +
      incomeApproachParagraph.join('\n\n') +
      '\n\n' +
      salesApproachParagraph.join('\n\n') +
      '\n\n' +
      descriptionOfMethodologiesParagraph.join('\n\n')
    )
  }

  const getMethodologiesNotUsed = () => {
    const approaches = [COST, INCOME, SALES]
    let methodologiesNotUsed = ''

    if (approachSelected === LAND) {
      const idx = approaches.indexOf(SALES)
      if (idx > -1) {
        approaches.splice(idx, 1)
      }
    }
    const idx = approaches.indexOf(approachSelected)
    if (idx > -1) {
      approaches.splice(idx, 1)
    }

    for (const approach in additionalApproaches) {
      if (!additionalApproaches[approach]) {
        continue
      }
      if (approach === LAND.toLowerCase()) {
        const idx = approaches.indexOf(SALES)
        if (idx > -1) {
          approaches.splice(idx, 1)
        }
      }
      const capitalApproach = approach[0].toUpperCase() + approach.slice(1)
      const idx = approaches.indexOf(capitalApproach)
      if (idx > -1) {
        approaches.splice(idx, 1)
      }
    }

    for (const unusedApproach of approaches) {
      methodologiesNotUsed = methodologiesNotUsed + APPROACH_NOT_USED[unusedApproach] + '\n\n'
    }

    return methodologiesNotUsed
  }

  const getCostEstimatedValueRows = () => {
    const costApproachValues = CostApproachCalculations.calculateCostApproachFinalValues({
      conclusion,
      subjectBudget,
      totalLeaseUpCosts,
      replacementCostNew,
      landValue,
    })
    return (
      <React.Fragment>
        {(valueAsComplete || valueAsStabilized) && (
          <TableRow className={classes.tableRow} data-qa="costApproachAsStabilized-row">
            <TableCell data-qa="costApproachAsStabilized-approach">Cost Approach</TableCell>
            <TableCell data-qa="costApproachAsStabilized-value">Prospective Market Value As Stabilized</TableCell>
            <TableCell data-qa="costApproachAsStabilized-date">
              {isCostApproachEnabled && costApproachValues.asStabilizedDateOfValue
                ? longDateFormat(costApproachValues.asStabilizedDateOfValue)
                : NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="costApproachAsStabilized-conclusion">
              {isCostApproachEnabled
                ? formatCurrencyInt(costApproachValues.asStabilizedMarketValueRounded)
                : NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        {valueAsComplete && (
          <TableRow className={classes.tableRow} data-qa="costApproachAsComplete-row">
            <TableCell data-qa="costApproachAsComplete-approach">Cost Approach</TableCell>
            <TableCell data-qa="costApproachAsComplete-value">Prospective Market Value As Complete</TableCell>
            <TableCell data-qa="costApproachAsComplete-date">
              {isCostApproachEnabled && costApproachValues.asCompleteDateOfValue
                ? longDateFormat(costApproachValues.asCompleteDateOfValue)
                : NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="costApproachAsComplete-conclusion">
              {isCostApproachEnabled
                ? formatCurrencyInt(costApproachValues.asCompleteMarketValueRounded)
                : NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={classes.tableRow} data-qa="costApproachAsIs-row">
          <TableCell data-qa="costApproachAsIs-approach">Cost Approach</TableCell>
          <TableCell data-qa="costApproachAsIs-value">As Is Market Value</TableCell>
          <TableCell data-qa="costApproachAsIs-date">
            {isCostApproachEnabled && asIsDateOfValue ? longDateFormat(asIsDateOfValue) : NOT_AVAILABLE}
          </TableCell>
          <TableCell data-qa="costApproachAsIs-conclusion">
            {isCostApproachEnabled ? formatCurrencyInt(costApproachValues.asIsMarketValueRounded) : NOT_AVAILABLE}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  const getIncomeEstimatedValueRows = () => {
    return (
      <React.Fragment>
        {(valueAsComplete || valueAsStabilized) && (
          <TableRow className={classes.tableRow} data-qa="incomeApproachAsStabilized-row">
            <TableCell data-qa="incomeApproachAsStabilized-approach">Income Capitalization Approach</TableCell>
            <TableCell data-qa="incomeApproachAsStabilized-value">Prospective Market Value As Stabilized</TableCell>
            <TableCell data-qa="incomeApproachAsStabilized-date">
              {longDateFormat(get(acas, 'incomeApproach.dateOfFinalValueAsStabilized')) || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="incomeApproachAsStabilized-conclusion">
              {get(acas, 'incomeApproach.finalValueAsStabilizedRounded') || NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        {valueAsComplete && (
          <TableRow className={classes.tableRow} data-qa="incomeApproachAsComplete-row">
            <TableCell data-qa="incomeApproachAsComplete-approach">Income Capitalization Approach</TableCell>
            <TableCell data-qa="incomeApproachAsComplete-value">Prospective Market Value As Complete</TableCell>
            <TableCell data-qa="incomeApproachAsComplete-date">
              {longDateFormat(get(acas, 'incomeApproach.dateOfFinalValueAsComplete')) || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="incomeApproachAsComplete-conclusion">
              {get(acas, 'incomeApproach.finalValueAsCompleteRounded') || NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={classes.tableRow} data-qa="incomeApproachAsIs-row">
          <TableCell data-qa="incomeApproachAsIs-approach">Income Capitalization Approach</TableCell>
          <TableCell data-qa="incomeApproachAsIs-value">As Is Market Value</TableCell>
          <TableCell data-qa="incomeApproachAsIs-date">
            {longDateFormat(get(acas, 'incomeApproach.dateOfFinalValueAsIs')) || NOT_AVAILABLE}
          </TableCell>
          <TableCell data-qa="incomeApproachAsIs-conclusion">
            {get(acas, 'incomeApproach.finalValueAsIsRounded') || NOT_AVAILABLE}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  const getSalesEstimatedValueRows = () => {
    if (approachSelected === LAND) {
      return getLandEstimatedValueRows()
    }
    return (
      <React.Fragment>
        {(valueAsComplete || valueAsStabilized) && (
          <TableRow className={classes.tableRow} data-qa="salesApproachAsStabilized-row">
            <TableCell data-qa="salesApproachAsStabilized-approach">Sales Comparison Approach</TableCell>
            <TableCell data-qa="salesApproachAsStabilized-value">Prospective Market Value As Stablilized</TableCell>
            <TableCell data-qa="salesApproachAsStabilized-date">
              {longDateFormat(get(acas, 'salesApproach.dateOfFinalValueAsStabilized')) || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="salesApproachAsStabilized-conclusion">
              {get(acas, 'salesApproach.finalValueAsStabilizedRounded') || NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        {valueAsComplete && (
          <TableRow className={classes.tableRow} data-qa="salesApproachAsComplete-row">
            <TableCell data-qa="salesApproachAsComplete-approach">Sales Comparison Approach</TableCell>
            <TableCell data-qa="salesApproachAsComplete-value">Prospective Market Value As Complete</TableCell>
            <TableCell data-qa="salesApproachAsComplete-date">
              {longDateFormat(get(acas, 'salesApproach.dateOfFinalValueAsComplete')) || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="salesApproachAsComplete-conclusion">
              {get(acas, 'salesApproach.finalValueAsCompleteRounded') || NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={classes.tableRow} data-qa="salesApproachAsIs-row">
          <TableCell data-qa="salesApproachAsIs-approach">Sales Comparison Approach</TableCell>
          <TableCell data-qa="salesApproachAsIs-value">As Is Market Value</TableCell>
          <TableCell data-qa="salesApproachAsIs-date">
            {longDateFormat(get(acas, 'salesApproach.dateOfFinalValueAsIs')) || NOT_AVAILABLE}
          </TableCell>
          <TableCell data-qa="salesApproachAsIs-conclusion">
            {get(acas, 'salesApproach.finalValueAsIsRounded') || NOT_AVAILABLE}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  const getLandEstimatedValueRows = () => (
    <>
      <TableRow className={classes.tableRow} data-qa="landValueAsVacant-row">
        <TableCell data-qa="landValuationAsVacant-approach">Sales Comparison Appraoch</TableCell>
        <TableCell data-qa="landValuationAsVacant-value">As Vacant Market Value</TableCell>
        <TableCell data-qa="landValuationAsVacant-date">
          {longDateFormat(get(acas, 'salesApproach.dateOfFinalValueAsIs')) || NOT_AVAILABLE}
        </TableCell>
        <TableCell data-qa="landValuationAsVacant-conclusion">
          {get(acas, 'landValuation.finalValueAsVacantRounded') || NOT_AVAILABLE}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRow} data-qa="landValueAsIs-row">
        <TableCell data-qa="landValuationAsIs-approach">Sales Comparison Approach</TableCell>
        <TableCell data-qa="landValuationAsIs-value">As Is Market Value</TableCell>
        <TableCell data-qa="landValuationAsIs-date">
          {longDateFormat(get(acas, 'salesApproach.dateOfFinalValueAsIs')) || NOT_AVAILABLE}
        </TableCell>
        <TableCell data-qa="landValuationAsIs-conclusion">
          {get(acas, 'landValuation.finalValueAsIsRounded') || NOT_AVAILABLE}
        </TableCell>
      </TableRow>
    </>
  )

  const getFinalValueOpinionTable = () => {
    return (
      <Table className={classes.table} data-qa="final-value-opinion-table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.headerCell}>Value</TableCell>
            <TableCell className={classes.headerCell}>Date</TableCell>
            <TableCell className={classes.headerCell}>Interest Appraised</TableCell>
            <TableCell className={classes.headerCell}>Conclusion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{getFinalValueOpinionRows()}</TableBody>
      </Table>
    )
  }

  const getMethodologiesUsedRow = approach => {
    const approachKey = lowerCase(approach)
    return (
      <TableRow>
        <TableCell>{approach}</TableCell>
        <TableCell>{methodologiesUsed[approachKey] ? 'Applicable' : 'Not Applicable'}</TableCell>
        <TableCell>{methodologiesUsed[approachKey] ? 'Yes' : 'No'}</TableCell>
      </TableRow>
    )
  }

  const getFinalValueOpinionCostApproachRows = () => {
    const costApproachValues = CostApproachCalculations.calculateCostApproachFinalValues({
      conclusion,
      subjectBudget,
      totalLeaseUpCosts,
      replacementCostNew,
      landValue,
    })
    return (
      <React.Fragment>
        {(valueAsComplete || valueAsStabilized) && (
          <TableRow className={classes.tableRow} data-qa="asStablilized-row">
            <TableCell data-qa="asStablilized-value">Prospective Market Value As Stabilized</TableCell>
            <TableCell data-qa="asStablilized-date">
              {isCostApproachEnabled && costApproachValues.asStabilizedDateOfValue
                ? longDateFormat(costApproachValues.asStabilizedDateOfValue)
                : NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="asStablilized-interestAppraised">
              {interestAppraisedAsStabilized || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="asStablilized-conclusion">
              {isCostApproachEnabled
                ? formatCurrencyInt(costApproachValues.asStabilizedMarketValueRounded)
                : NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        {valueAsComplete && (
          <TableRow className={classes.tableRow} data-qa="asComplete-row">
            <TableCell data-qa="asComplete-value">Prospective Market Value As Complete</TableCell>
            <TableCell data-qa="asComplete-date">
              {isCostApproachEnabled && costApproachValues.asCompleteDateOfValue
                ? longDateFormat(costApproachValues.asCompleteDateOfValue)
                : NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="asComplete-interestAppraised">{interestAppraisedAsComplete || NOT_AVAILABLE}</TableCell>
            <TableCell data-qa="asComplete-conclusion">
              {isCostApproachEnabled
                ? formatCurrencyInt(costApproachValues.asCompleteMarketValueRounded)
                : NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={classes.tableRow} data-qa="asIs-row">
          <TableCell data-qa="asIs-value">As Is Market Value</TableCell>
          <TableCell data-qa="asIs-date">
            {isCostApproachEnabled && asIsDateOfValue ? longDateFormat(asIsDateOfValue) : NOT_AVAILABLE}
          </TableCell>
          <TableCell data-qa="asIs-interestAppraised">{interestAppraisedAsIsMarketValue || NOT_AVAILABLE}</TableCell>
          <TableCell data-qa="asIs-conclusion">
            {isCostApproachEnabled ? formatCurrencyInt(costApproachValues.asIsMarketValueRounded) : NOT_AVAILABLE}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  const getFinalValueOpinionLandValuationRows = () => (
    <>
      <TableRow className={classes.tableRow} data-qa="asIs-row">
        <TableCell data-qa="asIs-value">As Vacant Market Value</TableCell>
        <TableCell data-qa="asIs-date">{longDateFormat(asIsDateOfValue) || NOT_AVAILABLE}</TableCell>
        <TableCell data-qa="asIs-interestAppraised">{interestAppraisedAsIsMarketValue || NOT_AVAILABLE}</TableCell>
        <TableCell data-qa="asIs-conclusion">
          {get(acas, 'landValuation.finalValueAsVacantRounded') || NOT_AVAILABLE}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRow} data-qa="asIs-row">
        <TableCell data-qa="asIs-value">As Is Market Value</TableCell>
        <TableCell data-qa="asIs-date">{longDateFormat(asIsDateOfValue) || NOT_AVAILABLE}</TableCell>
        <TableCell data-qa="asIs-interestAppraised">{interestAppraisedAsIsMarketValue || NOT_AVAILABLE}</TableCell>
        <TableCell data-qa="asIs-conclusion">
          {get(acas, 'landValuation.finalValueAsIsRounded') || NOT_AVAILABLE}
        </TableCell>
      </TableRow>
    </>
  )

  const getFinalValueOpinionOtherValueRows = () => (
    <>
      <TableRow className={classes.tableRow} data-qa="asIs-row">
        <TableCell data-qa="asIs-value">As Is Market Value</TableCell>
        <TableCell data-qa="asIs-date">{longDateFormat(asIsDateOfValue) || NOT_AVAILABLE}</TableCell>
        <TableCell data-qa="asIs-interestAppraised">{interestAppraisedAsIsMarketValue || NOT_AVAILABLE}</TableCell>
        <TableCell data-qa="asIs-conclusion">{formatCurrencyInt(otherApproachValue) || NOT_AVAILABLE}</TableCell>
      </TableRow>
    </>
  )

  const getFinalValueOpinionRows = () => {
    let data = {}

    if (approachSelected === RECONCILIATION_APPROACH.COST) {
      return getFinalValueOpinionCostApproachRows()
    }

    if (approachSelected === RECONCILIATION_APPROACH.SALES) {
      data = acas.salesApproach
    }
    if (approachSelected === RECONCILIATION_APPROACH.INCOME) {
      data = acas.incomeApproach
    }
    if (approachSelected === RECONCILIATION_APPROACH.LAND) {
      return getFinalValueOpinionLandValuationRows()
    }
    if (approachSelected === RECONCILIATION_APPROACH.OTHER) {
      return getFinalValueOpinionOtherValueRows()
    }
    return (
      <React.Fragment>
        {(valueAsComplete || valueAsStabilized) && (
          <TableRow className={classes.tableRow} data-qa="asStablilized-row">
            <TableCell data-qa="asStablilized-value">Prospective Market Value As Stablilized</TableCell>
            <TableCell data-qa="asStablilized-date">
              {longDateFormat(get(data, 'dateOfFinalValueAsStabilized')) || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="asStablilized-interestAppraised">
              {interestAppraisedAsStabilized || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="asStablilized-conclusion">
              {get(data, 'finalValueAsStabilizedRounded') || NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        {valueAsComplete && (
          <TableRow className={classes.tableRow} data-qa="asComplete-row">
            <TableCell data-qa="asComplete-value">Prospective Market Value As Complete</TableCell>
            <TableCell data-qa="asComplete-date">
              {longDateFormat(get(data, 'dateOfFinalValueAsComplete')) || NOT_AVAILABLE}
            </TableCell>
            <TableCell data-qa="asComplete-interestAppraised">{interestAppraisedAsComplete || NOT_AVAILABLE}</TableCell>
            <TableCell data-qa="asComplete-conclusion">
              {get(data, 'finalValueAsCompleteRounded') || NOT_AVAILABLE}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={classes.tableRow} data-qa="asIs-row">
          <TableCell data-qa="asIs-value">As Is Market Value</TableCell>
          <TableCell data-qa="asIs-date">
            {longDateFormat(get(data, 'dateOfFinalValueAsIs')) || NOT_AVAILABLE}
          </TableCell>
          <TableCell data-qa="asIs-interestAppraised">{interestAppraisedAsIsMarketValue || NOT_AVAILABLE}</TableCell>
          <TableCell data-qa="asIs-conclusion">{get(data, 'finalValueAsIsRounded') || NOT_AVAILABLE}</TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  const APPROACH_LABELS_TO_USE =
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS
      ? APPROACH_LABELS
      : APPROACH_LABELS.filter(approach => approach.label !== OTHER)

  return (
    <Stack spacing={1} sx={{ maxWidth: 900 }}>
      <Paper>
        <Typography variant="h6" gutterBottom>
          Summary Of Salient Facts
        </Typography>
        <Typography variant="subtitle1" gutterBottom></Typography>
        <CheckboxGroup
          groupName="unitOfComparison"
          header="Financial Indicators Table - Unit Of Comparison"
          required
          validate={notEmpty}
          items={[
            {
              name: 'psf',
              label: 'Per Square Foot',
            },
            {
              name: 'perUnit',
              label: 'Per Unit',
            },
          ]}
        />
      </Paper>
      <Paper>
        <Typography variant="h6" gutterBottom>
          Reconciliation
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Estimated Values
        </Typography>
        <Card elevation={0}>
          <Table className={classes.table} data-qa="estimated-values-table">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.headerCell}>Approach</TableCell>
                <TableCell className={classes.headerCell}>Value</TableCell>
                <TableCell className={classes.headerCell}>Date</TableCell>
                <TableCell className={classes.headerCell}>Conclusion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getCostEstimatedValueRows()}
              {getIncomeEstimatedValueRows()}
              {getSalesEstimatedValueRows()}
            </TableBody>
          </Table>
        </Card>
        <Typography variant="subtitle1" gutterBottom>
          Final Value Opinion
        </Typography>
        <RadioButtonList
          label="Which primary approach are you reconciling your final value with?"
          name="approachSelected"
          horizontal
          otherName="otherApproachValue"
          otherInputProps={{
            label: 'Other Value',
            required: true,
            format: value => formatCurrencyInt(value) || '',
            parse: value => fromCurrency(value) || 0,
          }}
          items={APPROACH_LABELS_TO_USE}
        />
        <Typography variant="body2" gutterBottom>
          Are you reconciling any additional approaches?
        </Typography>
        <CheckboxGroup groupName="additionalApproaches" horizontal items={additionalApproachOptions} />
        <Card elevation={0} className="tableContainer">
          {!!approachSelected && getFinalValueOpinionTable()}
        </Card>
      </Paper>
      <Paper>
        <Typography variant="h6" gutterBottom>
          Reconciliation & Final Value Methodology
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.explanatoryText}>
          {COST_APPROACH_METHODOLOGY_TITLE}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.explanatoryText}>
          {costApproachMethodology}
        </Typography>
        <GeneratedComment
          title="Generated Commentary"
          label="Cost Approach Discussion"
          tooltipText={FINAL_VALUE_AND_RECONCILIATION_TOOLTIP_TEXT}
          dataPath="costApproachDiscussion"
          getGeneratedText={() => getGeneratedText(COST)}
          isDynamicContent
        />
        <Typography variant="subtitle1" gutterBottom className={classes.explanatoryText}>
          {INCOME_APPROACH_METHODOLOGY_TITLE}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.explanatoryText}>
          {incomeCapitalizationApproachMethodology}
        </Typography>
        <GeneratedComment
          title="Generated Commentary"
          label="Income Capitalization Approach Discussion"
          tooltipText={FINAL_VALUE_AND_RECONCILIATION_TOOLTIP_TEXT}
          dataPath="incomeApproachDiscussion"
          getGeneratedText={() => getGeneratedText(INCOME)}
          isDynamicContent
        />
        <Typography variant="subtitle1" gutterBottom className={classes.explanatoryText}>
          {SALES_APPROACH_METHODOLOGY_TITLE}
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.explanatoryText}>
          {salesComparisonApproachMethodology}
        </Typography>
        <GeneratedComment
          title="Generated Commentary"
          label="Sales Comparison Approach Discussion"
          tooltipText={FINAL_VALUE_AND_RECONCILIATION_TOOLTIP_TEXT}
          dataPath="salesApproachDiscussion"
          getGeneratedText={() => getGeneratedText(SALES)}
          isDynamicContent
        />
      </Paper>
      <Paper>
        <Typography variant="h6" gutterBottom>
          Appraisal Valuation Process
        </Typography>
        <GeneratedComment
          title="Generated Commentary"
          label="Description of Methodologies"
          tooltipText={FINAL_VALUE_AND_RECONCILIATION_TOOLTIP_TEXT}
          dataPath="descriptionOfMethodologiesDiscussion"
          getGeneratedText={getDescriptionOfMethodologies}
          isDynamicContent
        />
        <br />
        <Typography variant="subtitle1">Methodologies Used</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Approach</TableCell>
              <TableCell>Applicability to Subject</TableCell>
              <TableCell>Use in the Assignment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMethodologiesUsedRow(COST)}
            {getMethodologiesUsedRow(INCOME)}
            {getMethodologiesUsedRow(SALES)}
            {getMethodologiesUsedRow(LAND)}
          </TableBody>
        </Table>
        <br />
        <GeneratedComment
          title="Generated Commentary"
          label="Methodologies Not Used"
          dataPath="methodologiesNotUsedDiscussion"
          getGeneratedText={getMethodologiesNotUsed}
          isDynamicContent
        />
      </Paper>
    </Stack>
  )
}

const mapStateToProps = state => {
  const { address, acas } = get(state, 'report.reportData')
  const { yearBuilt } = get(state, 'report.reportData.propertyInformation.propertySummary')
  const { interestAppraisedAsIsMarketValue, interestAppraisedAsComplete, interestAppraisedAsStabilized } = get(
    state,
    'report.reportData.report.reportInformation'
  )

  const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
  const { costApproachMethodology, incomeCapitalizationApproachMethodology, salesComparisonApproachMethodology } = get(
    state,
    'report.reportData.final.finalValuesAndReconciliation'
  )

  const reportApproaches = get(state, 'report.reportSettings.approaches')

  return {
    address,
    yearBuilt,
    valueAsComplete,
    valueAsStabilized,
    interestAppraisedAsIsMarketValue,
    interestAppraisedAsComplete,
    interestAppraisedAsStabilized,
    acas,
    costApproachMethodology,
    incomeCapitalizationApproachMethodology,
    salesComparisonApproachMethodology,
    reportApproaches,
  }
}

FinalValuesAndReconciliation.propTypes = {
  valueAsComplete: PropTypes.bool.isRequired,
  valueAsStabilized: PropTypes.bool.isRequired,
}

export default wrapForm(
  DATA_PATH,
  { styles, FINAL_VALUES_AND_RECONCILIATION_HEADING },
  mapStateToProps,
  {}
)(FinalValuesAndReconciliation)
