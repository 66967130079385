import React from 'react'
import PropTypes from 'prop-types'

import ObjectID from 'bson-objectid'
import { Link } from 'react-router-dom'
import { OnChange } from 'react-final-form-listeners'
import { find, get } from 'lodash'

import { Alert, Grid, Paper, Stack, Typography } from '@mui/material'

import { LOSS_ITEM_VALUE_TYPE, RENT_LOSS_TYPE, VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'
import { SECTIONS, showSection } from 'shared/helpers/propertyType'
import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'

import { Template } from 'client-shared/components/Template'

import { removeByIndex } from 'client-shared/utils/immutability'

import { Select } from '@ui/Field'

import { CalloutButton, CheckboxWithLabel, WarningDialog } from '../.'

import { ADJUSTMENT_AND_LOSSES_DATA_SOURCE, ADJUSTMENT_TYPES, ROUNDING_ITEMS } from './constants'
import CommercialRentLossModal from './CommercialRentLossModal'
import ResidentialRentLossModal from './ResidentialRentLossModal'
import Toolbar from './AdjustmentsAndLossesTableHeaderActions'
import AdjustmentsAndLossesTableView from './AdjustmentsAndLossesTableView'

class AdjustmentsAndLossesTable extends React.PureComponent {
  state = {
    adjustmentTypeToAdd: ADJUSTMENT_TYPES.NPV,
    collectionToEdit: null,
    commercialRentLossModalOpen: false,
    editMode: false,
    index: null,
    matchIncomeApproachModalOpen: false,
    residentialRentLossModalOpen: false,
  }

  constructor(props) {
    super(props)
    this.concludedCapRate = React.createRef()
  }

  //
  //  Adjustments
  //
  addAdjustment = adjustmentType => {
    const { change } = this.props

    const newAdjustment = {
      _id: ObjectID().toString(),
      amount: 0,
      months: '',
      type: LOSS_ITEM_VALUE_TYPE.USER_ENTERED,
    }

    if (adjustmentType === ADJUSTMENT_TYPES.NPV) {
      const { npvAdjustments } = this.props.values
      change('npvAdjustments', [...npvAdjustments, newAdjustment])
    }

    if (adjustmentType === ADJUSTMENT_TYPES.AS_COMPLETE) {
      const { asCompleteNpvAdjustments } = this.props.values
      change('asCompleteNpvAdjustments', [...asCompleteNpvAdjustments, newAdjustment])
    }

    if (adjustmentType === ADJUSTMENT_TYPES.AS_STABILIZED) {
      const { asStabilizedNpvAdjustments } = this.props.values
      change('asStabilizedNpvAdjustments', [...asStabilizedNpvAdjustments, newAdjustment])
    }
  }

  removeAdjustmentOfType = (adjustmentType, index) => {
    const { change } = this.props
    if (adjustmentType === ADJUSTMENT_TYPES.NPV) {
      const { npvAdjustments } = this.props.values
      change('npvAdjustments', removeByIndex(npvAdjustments, index))
    }

    if (adjustmentType === ADJUSTMENT_TYPES.AS_COMPLETE) {
      const { asCompleteNpvAdjustments } = this.props.values
      change('asCompleteNpvAdjustments', removeByIndex(asCompleteNpvAdjustments, index))
    }

    if (adjustmentType === ADJUSTMENT_TYPES.AS_STABILIZED) {
      const { asStabilizedNpvAdjustments } = this.props.values
      change('asStabilizedNpvAdjustments', removeByIndex(asStabilizedNpvAdjustments, index))
    }
  }

  //
  // Rent Loss
  //
  openResidentialRentLossModal = () => {
    this.setState({ residentialRentLossModalOpen: true })
  }

  openCommercialRentLossModal = () => {
    this.setState({ commercialRentLossModalOpen: true })
  }

  closeRentLossModal = () => {
    const { residentialRentLossModalOpen, commercialRentLossModalOpen } = this.state
    if (residentialRentLossModalOpen) {
      this.setState({ residentialRentLossModalOpen: false, editMode: false, collectionToEdit: null, index: null })
    }

    if (commercialRentLossModalOpen) {
      this.setState({ commercialRentLossModalOpen: false, editMode: false, collectionToEdit: null, index: null })
    }
  }

  addRentLossItem = (collectionName, units, unitIds, vcLossPercentage, collectionType = RENT_LOSS_TYPE.RESIDENTIAL) => {
    const { values, change } = this.props

    const rentLossItems = get(values, collectionName)
    const mappedUnits = this.getMappedUnits(units, unitIds)
    const monthlyLoss = ACASCalculations.calculateMonthlyRentLoss(collectionType, mappedUnits, vcLossPercentage)

    const updatedRentLossItems = [
      ...rentLossItems,
      {
        amount: 0,
        monthlyLoss,
        months: '',
        units: unitIds,
        type: LOSS_ITEM_VALUE_TYPE.COMPUTED_VALUE,
      },
    ]

    change(collectionName, updatedRentLossItems)

    this.closeRentLossModal()
  }

  editRentLossItem = (
    collectionName,
    index,
    units,
    unitIds,
    vcLossPercentage,
    collectionType = RENT_LOSS_TYPE.RESIDENTIAL
  ) => {
    const { values, change, batch } = this.props

    const rentLossItems = get(values, collectionName)
    const lossItemToUpdate = rentLossItems[index]
    const mappedUnits = this.getMappedUnits(units, unitIds)

    if (mappedUnits.length > 0) {
      const monthlyLoss = ACASCalculations.calculateMonthlyRentLoss(collectionType, mappedUnits, vcLossPercentage)
      const months = lossItemToUpdate.months
      const amount = monthlyLoss * months

      batch(() => {
        change(`${collectionName}[${index}].amount`, amount)
        change(`${collectionName}[${index}].monthlyLoss`, monthlyLoss)
        change(`${collectionName}[${index}].units`, unitIds)
      })
    } else {
      this.onRemoveRentLoss(index, collectionName)
    }

    this.closeRentLossModal()
  }

  getMappedUnits = (units, unitIds) => {
    return unitIds.map(unitId => {
      return find(units, unit => unit._id?.toString() === unitId)
    })
  }

  onEditRentLoss = (index, collectionName, rentLossType) => {
    let newState = {
      collectionToEdit: collectionName,
      editMode: true,
      index: index,
    }

    switch (rentLossType) {
      case RENT_LOSS_TYPE.RESIDENTIAL:
        newState = { ...newState, residentialRentLossModalOpen: true }
        break
      case RENT_LOSS_TYPE.COMMERCIAL:
        newState = { ...newState, commercialRentLossModalOpen: true }
        break
      default:
        break
    }

    this.setState(newState)
  }

  onRemoveRentLoss = (indexToRemove, collectionName) => {
    const { values, change } = this.props
    const rentLossItems = get(values, collectionName, []).filter((value, index) => index !== indexToRemove)
    change(collectionName, rentLossItems)
  }

  fillDownLossPeriod = collectionName => () => {
    const { values, change } = this.props
    const asStabilizedLossItems = get(values, collectionName, [])

    const updatedStabilizedLossItems = asStabilizedLossItems.map(item => {
      if (item.type === LOSS_ITEM_VALUE_TYPE.COMPUTED_VALUE) {
        return { ...item, months: get(values, `${collectionName}[0].months`) }
      }
      return item
    })

    change(collectionName, updatedStabilizedLossItems)
  }

  onEndMoveItem = ({ source, destination }) => {
    if (!destination) {
      return
    }
    this.props.fields.move(source.index, destination.index)
  }

  onConcludedCapRateRowClick = () => {
    if (this.concludedCapRate) {
      this.concludedCapRate.current.focus()
    }
  }

  getRentLossRowTitle = (rentLossItem, collectionOfUnits, rentLossHeaderPrefix) => {
    const unitNamesInOrder = collectionOfUnits.flatMap((unit, index) => {
      if (rentLossItem.units?.includes(unit?._id)) {
        const unitLabel = unit?.number || index + 1
        return unitLabel
      }
      return []
    })

    const maxUnitsToName = 4

    if (unitNamesInOrder.length > maxUnitsToName) {
      return rentLossHeaderPrefix
    }

    let title = `${rentLossHeaderPrefix} (Unit${unitNamesInOrder.length > 1 ? 's' : ''}`
    title = `${title} ${unitNamesInOrder.join(', ')})`

    return title
  }

  residentialUnitsAdded = () => {
    const { residentialUnits } = this.props
    return residentialUnits && residentialUnits.length && '_id' in residentialUnits[0]
  }

  commercialUnitsAdded = () => {
    const { commercialUnits } = this.props
    return commercialUnits && commercialUnits.length && '_id' in commercialUnits[0]
  }

  revertSalesApproachAdjustmentsAndDeductions = () => {
    const { change } = this.props
    const { incomeApproachData } = this.props.values

    const capRateConclusion = incomeApproachData.capRateConclusion

    //replace sales approach adjustment and loss data with income approach values
    change('npvAdjustments', capRateConclusion.npvAdjustments)
    change('asCompleteNpvAdjustments', capRateConclusion.asCompleteNpvAdjustments)
    change('asCompleteLossItems', capRateConclusion.asCompleteLossItems)
    change('asCompleteResRentLossItems', capRateConclusion.asCompleteResRentLossItems)
    change('asCompleteCommercialRentLossItems', capRateConclusion.asCompleteCommercialRentLossItems)
    change('asCompleteMonthsOfRentLoss', capRateConclusion.asCompleteMonthsOfRentLoss)
    change('asStabilizedNpvAdjustments', capRateConclusion.asStabilizedNpvAdjustments)
    change('asStabilizedLossItems', capRateConclusion.asStabilizedLossItems)
    change('asStabilizedResRentLossItems', capRateConclusion.asStabilizedResRentLossItems)
    change('asStabilizedCommercialRentLossItems', capRateConclusion.asStabilizedCommercialRentLossItems)
    change('asStabilizedMonthsOfRentLoss', capRateConclusion.asStabilizedMonthsOfRentLoss)
  }

  //
  // Match Income Approach warning modal
  //
  handleMatchIncomeApproachModalSubmit = confirmed => {
    this.revertSalesApproachAdjustmentsAndDeductions()
    this.closeMatchIncomeApproachModal()
  }

  handleMatchIncomeApproachModalCancel = () => {
    const { change } = this.props
    change('matchIncomeApproachDeductions', false)
    this.closeMatchIncomeApproachModal()
  }

  openMatchIncomeApproachModal = () => {
    this.setState({ matchIncomeApproachModalOpen: true })
  }

  closeMatchIncomeApproachModal = () => {
    this.setState({ matchIncomeApproachModalOpen: false })
  }

  toggleMatchIncomeApproachDeductions = isChecked => {
    if (isChecked) {
      this.openMatchIncomeApproachModal()
    }
  }

  canEditControl = () => {
    const { dataSourceType } = this.props
    const { matchIncomeApproachDeductions } = this.props.values

    const canEdit =
      dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH ||
      (dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.SALES_APPROACH && !matchIncomeApproachDeductions)

    return canEdit
  }

  getDismissableCalloutBody = () => {
    const { propertyType } = this.props
    let path = './residential-projected-rent-roll'
    if (!showSection(SECTIONS.HAS_RESIDENTIAL, propertyType) && showSection(SECTIONS.HAS_COMMERCIAL, propertyType)) {
      path = './commercial-projected-rent-roll'
    }

    return (
      <Alert severity="info">
        You must re-save your&nbsp;
        <Link to={{ pathname: path, state: { skipConfirmation: false } }}>Stabilized Rent Roll page</Link>
        &nbsp;in order to display the calculated rent loss deductions
      </Alert>
    )
  }

  renderTableView = () => {
    const {
      commercialUnits,
      dataSourceType,
      netOperatingIncome,
      residentialUnits,
      valueAsComplete,
      valueAsStabilized,
      values,
    } = this.props

    const {
      canEditControl,
      concludedCapRate,
      fillDownLossPeriod,
      getRentLossRowTitle,
      onConcludedCapRateRowClick,
      onEditRentLoss,
      onRemoveRentLoss,
      removeAdjustmentOfType,
    } = this

    return (
      <AdjustmentsAndLossesTableView
        {...{
          canEditControl,
          commercialUnits,
          concludedCapRate,
          dataSourceType,
          fillDownLossPeriod,
          getRentLossRowTitle,
          netOperatingIncome,
          onConcludedCapRateRowClick,
          onEditRentLoss,
          onRemoveRentLoss,
          removeAdjustmentOfType,
          residentialUnits,
          valueAsComplete,
          valueAsStabilized,
          values,
        }}
      />
    )
  }

  render() {
    const {
      collectionToEdit,
      commercialRentLossModalOpen,
      editMode,
      index,
      matchIncomeApproachModalOpen,
      residentialRentLossModalOpen,
    } = this.state

    const {
      commercialUnits,
      commercialVCLossPercentageAggregate,
      dataSourceType,
      propertyType,
      residentialUnits,
      residentialVCLossPercentage,
      title,
      valueAsComplete,
      valueAsStabilized,
      valueConclusionType,
      values,
      hasIncomeApproach,
    } = this.props

    const {
      asCompleteCommercialRentLossItems,
      asCompleteResRentLossItems,
      asStabilizedCommercialRentLossItems,
      asStabilizedResRentLossItems,
    } = values

    const getTableTitleSize = () => {
      return dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH ? 'subtitle1' : 'h6'
    }

    const {
      addRentLossItem,
      canEditControl,
      closeRentLossModal,
      editRentLossItem,
      handleMatchIncomeApproachModalCancel,
      handleMatchIncomeApproachModalSubmit,
    } = this

    return (
      <Paper data-qa="adjustmentsAndLossesTable-section">
        <WarningDialog
          cancelButtonLabel="No"
          onCancel={handleMatchIncomeApproachModalCancel}
          onSubmit={handleMatchIncomeApproachModalSubmit}
          open={matchIncomeApproachModalOpen}
          submitButtonLabel="Yes"
          text="Are you sure you want to lose your changes and use the Income Approach's Cap Rate adjustments and losses?"
          title="Revert custom adjustments and losses"
        />

        {residentialRentLossModalOpen && (
          <ResidentialRentLossModal
            asCompleteResRentLossItems={asCompleteResRentLossItems}
            asStabilizedResRentLossItems={asStabilizedResRentLossItems}
            closeRentLossModal={closeRentLossModal}
            collectionName={collectionToEdit}
            editMode={editMode}
            index={index}
            onAddRentLoss={addRentLossItem}
            onEditRentLoss={editRentLossItem}
            residentialUnits={residentialUnits}
            residentialVCLossPercentage={residentialVCLossPercentage}
            valueConclusionType={valueConclusionType}
          />
        )}
        {commercialRentLossModalOpen && (
          <CommercialRentLossModal
            asCompleteCommercialRentLossItems={asCompleteCommercialRentLossItems}
            asStabilizedCommercialRentLossItems={asStabilizedCommercialRentLossItems}
            closeCommercialRentLossModal={closeRentLossModal}
            collectionName={collectionToEdit}
            commercialUnits={commercialUnits}
            commercialVCLossPercentageAggregate={commercialVCLossPercentageAggregate}
            editMode={editMode}
            index={index}
            onAddRentLoss={addRentLossItem}
            onEditRentLoss={editRentLossItem}
            valueConclusionType={valueConclusionType}
          />
        )}
        <Stack spacing={2}>
          <Template is="auto-flow / 1fr auto" placeItems="center start">
            <Typography variant={getTableTitleSize()}>{title}</Typography>
            {dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.SALES_APPROACH && (
              <>
                <OnChange name="matchIncomeApproachDeductions">{this.toggleMatchIncomeApproachDeductions}</OnChange>
                <CheckboxWithLabel
                  data-qa="checkbox-matchIncomeApproachDeductions"
                  label="Match Income Approach Deductions"
                  name="matchIncomeApproachDeductions"
                />
              </>
            )}
          </Template>
          {(valueAsStabilized || valueAsComplete) && this.getDismissableCalloutBody()}
          {canEditControl() &&
            showSection(SECTIONS.HAS_RESIDENTIAL, propertyType) &&
            !this.residentialUnitsAdded() &&
            valueConclusionType !== VALUE_CONCLUSION_TYPES.AS_IS && (
              <CalloutButton
                link="./residential-rent-roll"
                linkText="CHANGE"
                text="There are no residential units to deduct rent loss from."
                variant="warn"
              />
            )}
          {canEditControl() &&
            showSection(SECTIONS.HAS_COMMERCIAL, propertyType) &&
            !this.commercialUnitsAdded() &&
            valueConclusionType !== VALUE_CONCLUSION_TYPES.AS_IS && (
              <CalloutButton
                link="./commercial-rent-roll"
                linkText="CHANGE"
                text="There are no commercial units to deduct rent loss from."
                variant="warn"
              />
            )}
          {canEditControl() && (
            <Toolbar
              commercialUnitsAdded={this.commercialUnitsAdded}
              onAddAdjustment={this.addAdjustment}
              openCommercialRentLossModal={this.openCommercialRentLossModal}
              openRentLossModal={this.openResidentialRentLossModal}
              residentialUnitsAdded={this.residentialUnitsAdded}
              roundingFactorIsEditable={dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH}
              valueConclusionType={valueConclusionType}
              values={values}
            />
          )}
          {canEditControl() && (
            <Grid container direction="column">
              {valueAsStabilized && (
                <Grid item>
                  <CheckboxWithLabel
                    name="includeAsStabilizedDiscountRate"
                    label="Include As Stabilized Discount Rate?"
                  />
                </Grid>
              )}
              {valueAsComplete && (
                <Grid item>
                  <CheckboxWithLabel name="includeAsCompleteDiscountRate" label="Include As Complete Discount Rate?" />
                </Grid>
              )}
            </Grid>
          )}
          {!hasIncomeApproach && (
            <Grid container justifyContent="end">
              <Select.Single
                size="small"
                sx={{ width: '200px' }}
                options={ROUNDING_ITEMS}
                label="Round to the nearest"
                name="roundingFactor"
                fullWidth={false}
              />
            </Grid>
          )}
          {this.renderTableView()}
        </Stack>
      </Paper>
    )
  }
}

AdjustmentsAndLossesTable.propTypes = {
  change: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dataSourceType: PropTypes.number.isRequired,
  netOperatingIncome: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  valueAsComplete: PropTypes.bool.isRequired,
  valueAsStabilized: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  hasIncomeApproach: PropTypes.bool.isRequired,
}

export default AdjustmentsAndLossesTable
