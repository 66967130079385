import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

class GleanAppModal extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { open, applicationId } = this.props
    if (!prevProps.open && open) {
      // Only runs when the modal opens
      setTimeout(() => {
        const gleanAppElement = document.getElementById('glean-app')
        if (gleanAppElement) {
          window.GleanWebSDK.renderChat(gleanAppElement, {
            applicationId,
          })
        }
      }, 100)
    }
  }

  render() {
    const { open, onClose } = this.props

    return (
      <Dialog aria-describedby="glean-app-modal" aria-labelledby="glean-app-modal" fullWidth maxWidth="lg" open={open}>
        <DialogTitle>Glean Search</DialogTitle>
        <DialogContent>
          {/* The div where Glean will be rendered */}
          <div id="glean-app" style={{ width: '100%', height: '800px' }}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default GleanAppModal
